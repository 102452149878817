import { NODE_DESCRIPTIONS } from '../data';
import { SSIO, SSNode, Vector, IOType } from '../types';

export const getIOCenterPosition = (node: SSNode, io: SSIO): Vector => {
  return getRawIOCenterPosition(node.type, io.type, io.index);
};

export const getRawIOCenterPosition = (
  nodeType: string,
  ioType: IOType,
  ioIndex: number,
): Vector => {
  const nodeDescription = NODE_DESCRIPTIONS[nodeType];
  const count =
    ioType === IOType.Input
      ? nodeDescription.numberOfInputsAndAudioParams
      : nodeDescription.numberOfOutputs;
  const size = Math.max(
    3,
    nodeDescription.numberOfInputsAndAudioParams,
    nodeDescription.numberOfOutputs,
  );

  const paddingAmount = 2 / 3;

  return [
    ioType === IOType.Input ? paddingAmount : 3 - paddingAmount,
    count === 1
      ? size / 2
      : paddingAmount + (ioIndex * (size - 2 * paddingAmount)) / (count - 1),
  ];
};
