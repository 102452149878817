import { useRef, useLayoutEffect, RefObject } from 'react';
import { Rect } from '../types';

export const useResizeObserver = <T extends Element>(
  setRect: (rect: Rect) => void,
): RefObject<T> => {
  const elementRef = useRef<T>(null);
  useLayoutEffect(() => {
    if (elementRef.current === null) throw new Error();
    const { current } = elementRef;

    const observer = new ResizeObserver(() => {
      const { left, top, width, height } = current.getBoundingClientRect();
      setRect([
        [left, top],
        [width, height],
      ]);
    });
    observer.observe(current);

    return () => observer.disconnect();
  }, [setRect, elementRef.current]);

  return elementRef;
};
