import React, { FC } from 'react';
import { Input } from '../../../../components';

export type BooleanInputProps = {
  id?: string;
  tabIndex?: number;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
};

export const BooleanInput: FC<BooleanInputProps> = ({
  id,
  tabIndex,
  disabled,
  value,
  onChange,
}) => {
  return (
    <Input
      id={id}
      tabIndex={tabIndex}
      disabled={disabled}
      type="checkbox"
      checked={value}
      onChange={e => onChange(e.target.checked)}
    />
  );
};
