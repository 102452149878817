import { createContext } from 'react';
import { Session } from '../types';

export type SessionOptions = {
  session: Session | undefined;
  signIn: (session: Session) => void;
  signOut: () => void;
};

export const SessionContext = createContext<SessionOptions>({
  session: undefined,
  signIn: () => {},
  signOut: () => {},
});
