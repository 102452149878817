export enum ProbabilityDistributionType {
  Uniform,
  Exponential,
  Gaussian,
}

export type UniformDistribution = {
  type: ProbabilityDistributionType.Uniform;
  min: number;
  max: number;
};

export type ExponentialDistribution = {
  type: ProbabilityDistributionType.Exponential;
  min: number;
  max: number;
  exponent: number;
};

export type GaussianDistribution = {
  type: ProbabilityDistributionType.Gaussian;
  mean: number;
  variance: number;
};

export type ProbabilityDistribution =
  | UniformDistribution
  | ExponentialDistribution
  | GaussianDistribution;
