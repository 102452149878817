import { randomID } from './randomID';

const mulberry32 = (seed: number) => () => {
  let t = (seed += 0x6d2b79f5);
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
};

// This is used so that different reducers and peers can get the same new
// ids when procesesing paste or clone action.  It expects to be seeded w/ a
// value between 0 and 1
export const seededRandomIDs = (seed: number, count: number): string[] => {
  const rand = mulberry32(seed * Number.MAX_SAFE_INTEGER);
  const randomIDs = new Array(count);
  for (let i = 0; i < count; i++) randomIDs[i] = randomID(12, rand);
  return randomIDs;
};
