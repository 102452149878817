import React, { FC } from 'react';
import { ValueInputProps } from './ValueInput';
import { FloatInput } from './FloatInput';
import { ArrayWeightedRandomSequenceValueInput } from './ArrayWeightedRandomSequenceValueInput';

export const FloatArrayWeightedRandomSequenceValueInput: FC<
  ValueInputProps<number[]>
> = props => {
  return (
    <ArrayWeightedRandomSequenceValueInput {...props} Component={FloatInput} />
  );
};
