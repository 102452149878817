import React, { FC } from 'react';
import styled from 'styled-components';
import { TAB_ORDER } from '../../../../data';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const Cell = styled.div<{
  highlighted: boolean;
}>`
  position: relative;
  aspect-ratio: 1;
  text-align: center;
  color: var(--text-secondary-color);
  background: ${({ highlighted }) =>
    highlighted ? 'var(--input-highlighted-color)' : 'var(--input-color)'};
  &:hover {
    background: ${({ highlighted }) =>
      highlighted
        ? 'var(--input-highlighted-color)'
        : 'var(--input-disabled-color)'};
  }
`;

const margin = 100 / 6;
const Dot = styled.div<{
  isOn: boolean;
  hold: boolean;
  connectLeft: boolean;
  connectRight: boolean;
}>`
  border-color: var(--input-text-color);
  background-color: ${({ hold }) =>
    hold ? 'var(--input-text-color)' : 'transparent'};
  border-width: ${margin}%;
  border-style: solid;
  position: absolute;
  left: ${({ connectLeft }) => (connectLeft ? 0 : margin)}%;
  top: ${margin}%;
  height: ${100 - 2 * margin}%;
  width: ${({ connectLeft, connectRight }) =>
    100 -
    margin *
      (connectLeft && connectRight ? 0 : connectLeft || connectRight ? 1 : 2)}%;
  border-bottom-left-radius: ${({ connectLeft }) => (connectLeft ? 0 : 100)}%;
  border-top-left-radius: ${({ connectLeft }) => (connectLeft ? 0 : 100)}%;
  border-bottom-right-radius: ${({ connectRight }) =>
    connectRight ? 0 : 100}%;
  border-top-right-radius: ${({ connectRight }) => (connectRight ? 0 : 100)}%;
`;

export type RhythmStepsInputProps = {
  id?: string;
  width?: number;
  value: number[];
  onChange: (value: number[]) => void;
  highlightedIndex?: number;
};

export const RhythmStepsInput: FC<RhythmStepsInputProps> = ({
  id,
  value,
  onChange,
  highlightedIndex,
}) => {
  const updateStep = (i: number, currentValue: number) => {
    const nextValue = currentValue === 0 ? 1 : currentValue === 1 ? 2 : 0;
    onChange([...value.slice(0, i), nextValue, ...value.slice(i + 1)]);
  };

  return (
    <Container id={id}>
      {value.map((step, i) => {
        const previousStep = value[(i - 1 + value.length) % value.length];
        const nextStep = value[(i + 1) % value.length];
        const isOn = step === 1 || step === 2;
        const hold = step === 2;
        return (
          <Cell
            key={i}
            highlighted={highlightedIndex === i}
            tabIndex={TAB_ORDER.INPUT}
            onClick={() => {
              updateStep(i, step);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                updateStep(i, step);
              }
            }}
          >
            {isOn && (
              <Dot
                isOn={isOn}
                hold={hold}
                connectLeft={(step === 1 || step === 2) && previousStep === 2}
                connectRight={step === 2 && (nextStep === 1 || nextStep === 2)}
              />
            )}
          </Cell>
        );
      })}
    </Container>
  );
};
