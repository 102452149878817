import { NodeDescription, ParamType } from '../types';
import { TriggerEvent } from './TriggerEvent';

export const createNode = (
  type: string,
  {
    numberOfInputs,
    numberOfOutputs,
    outputChannelCount,
    channelCount,
    channelCountMode,
    channelInterpretation,
    params,
    signals,
  }: NodeDescription,
): {
  new (context: BaseAudioContext, params: any): AudioWorkletNode;
} => {
  class AnonymousNode extends AudioWorkletNode {
    signals: { [key: string]: any };

    constructor(context: BaseAudioContext, values: { [k: string]: any }) {
      // divide values into processor options and parameter data
      const processorOptions: { [k: string]: any } = {};
      const parameterData: { [k: string]: number } = {};
      Object.entries(values).forEach(([k, v]) => {
        if (params[k].type === ParamType.AudioParam) {
          parameterData[k] = v;
        } else {
          processorOptions[k] = v;
        }
      });

      // call super
      super(context, `${type}Processor`, {
        numberOfInputs,
        numberOfOutputs,
        outputChannelCount,
        channelCount,
        channelCountMode,
        channelInterpretation,
        processorOptions,
        parameterData,
      });

      // set parameters at top level, create getters and setters for values
      const privateValues: { [k: string]: any } = {};
      Object.entries(params).forEach(([k, { type, default: d }]) => {
        if (type === ParamType.AudioParam) {
          (this as any)[k] = this.parameters.get(k);
        } else {
          privateValues[k] = values[k] ?? d;
          Object.defineProperty(this, k, {
            get() {
              return privateValues[k];
            },
            set(v) {
              privateValues[k] = v;
              if (type === ParamType.AudioBuffer) {
                this.port.postMessage([k, v.getChannelData(0)]);
              } else {
                this.port.postMessage([k, v]);
              }
            },
          });
        }
      });

      // set signal defaults, create getters, handle incoming events
      this.signals = Object.entries(signals).reduce(
        (memo, [name, { default: value }]) => ((memo[name] = value), memo),
        {} as { [name: string]: any },
      );
      this.port.onmessage = ({ data: [k, v] }) => {
        if (process.env.NODE_ENV !== 'production' && signals[k] === undefined) {
          throw new Error(`unrecognized signal '${k}'`);
        }
        const { readable, trigger } = signals[k];
        if (readable) {
          this.signals[k] = v;
        }
        if (trigger) {
          this.dispatchEvent(new TriggerEvent(v));
        }
      };
    }
  }
  Object.defineProperty(AnonymousNode, 'name', { value: `${type}Node` });
  return AnonymousNode;
};
