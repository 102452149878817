import { V1GraphForStorage, SSGraph } from '../types';

export const graphToStorage = ({
  id,
  name,
  forkedFrom,
  nodes,
  edges,
}: SSGraph): V1GraphForStorage => {
  return { v: '1.0.0', id, name, forkedFrom, nodes, edges };
};
