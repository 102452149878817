import React, {
  ReactNode,
  MouseEvent,
  WheelEvent,
  KeyboardEvent,
  useContext,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import { GridContext } from '../contexts';
import { fontSize } from '../../../style';

export type GridProps = {
  onMouseDown?: (e: MouseEvent<SVGElement>) => void;
  onWheel?: (e: WheelEvent<SVGElement>) => void;
  onClick?: (e: MouseEvent<SVGElement>) => void;
  onDoubleClick?: (e: MouseEvent<SVGElement>) => void;
  onContextMenu?: (e: MouseEvent<SVGElement>) => void;
  onKeyDown?: (e: KeyboardEvent<SVGElement>) => void;
  children?: ReactNode;
};

const SVG = styled.svg`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  background-color: var(--grid-color);
  display: flex;
`;

export const Grid = forwardRef<SVGSVGElement, GridProps>(
  (
    {
      onMouseDown,
      onWheel,
      onClick,
      onDoubleClick,
      onContextMenu,
      onKeyDown,
      children,
    },
    ref,
  ) => {
    const {
      viewport: [[x, y], [w, h]],
      cellSize: [size],
    } = useContext(GridContext);

    const styleRef = useRef<HTMLStyleElement | null>(null);
    useEffect(() => {
      const sheet = styleRef.current?.sheet;
      const opacity = size > fontSize * 0.75 ? 1 : (size / fontSize - 0.5) * 4;
      if (sheet) {
        while (sheet.cssRules.length > 0) sheet.deleteRule(0);
        sheet.insertRule(
          `.non-scaling-text {
            font-size: ${fontSize / size}px;
            transform: translate(0, ${fontSize / 2 / size}px);
            display: block;
          }`,
        );
        sheet.insertRule(`.fading-text { opacity: ${opacity}; }`);
      }
    }, [styleRef.current, size]);

    return (
      <>
        <SVG
          ref={ref}
          onMouseDown={onMouseDown}
          onWheel={onWheel}
          onKeyDown={onKeyDown}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          onContextMenu={onContextMenu}
          viewBox={`${x} ${y} ${w} ${h}`}
        >
          {children}
        </SVG>
        <style ref={styleRef} />
      </>
    );
  },
);
