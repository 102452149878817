export const fontSize = 13;
export const lineHeight = 16;
export const maxTextContentWidth = 550;
export const maxBlockContentWidth = 700;
export const fontWeight = 500;

export const strokeWidth = 2;
export const cornerRadius = 1 / 3;
export const outlineOffset = 1 / 3;
export const ioRadius = 1 / 3;
export const outlineExpansion = outlineOffset * 2;

export const halfStrokeWidth = strokeWidth / 2;
export const columnPadding = lineHeight;
export const columnHandleWidth = lineHeight / 2;
export const columnHandleHeight = 10 * lineHeight;

export const inputWidth = 196;
export const meterWidth = 128;
