import React, { FC } from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: block;
  cursor: pointer;
  rect {
    fill: var(--play-button-color);
  }
  &:hover rect {
    fill: var(--play-button-hover-color);
  }
`;

export type MenuIconProps = { size: number };

export const MenuIcon: FC<MenuIconProps> = ({ size }) => {
  return (
    <SVG width={size} height={size} viewBox="0 0 1 1">
      <rect x={0} y={0.05} width={1} height={0.2} />
      <rect x={0} y={0.4} width={1} height={0.2} />
      <rect x={0} y={0.75} width={1} height={0.2} />
    </SVG>
  );
};
