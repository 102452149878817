import React, { Fragment, FC, useContext } from 'react';
import styled from 'styled-components';
import {
  getIOCenterPosition,
  getNodeSize,
  getNodeIO,
  add,
} from '../../../utils';
import { ActivityContext } from '../contexts';
import { ActivityType } from '../../../types';
import { strokeWidth, cornerRadius } from '../../../style';

const Rect = styled.rect`
  stroke-width: ${2 * strokeWidth};
  stroke: var(--outline-color);
  fill: var(--node-selected-color);
  vector-effect: non-scaling-stroke;
`;
const Ellipse = styled.ellipse`
  stroke-width: ${strokeWidth};
  fill: var(--io-color);
  stroke: var(--io-stroke-color);
  vector-effect: non-scaling-stroke;
`;

export type NodeTranslationActivityUIProps = {};

export const NodeTranslationActivityUI: FC<
  NodeTranslationActivityUIProps
> = () => {
  const activities = useContext(ActivityContext);

  return (
    <>
      {Object.entries(activities).map(([username, activity]) => {
        if (
          activity === undefined ||
          !(
            activity.type === ActivityType.NodeTranslation ||
            activity?.type === ActivityType.NodeClone
          )
        ) {
          return null;
        }

        const delta = activity.delta;
        const nodes =
          activity.type === ActivityType.NodeTranslation
            ? activity.nodes
            : activity.clipboard.nodes;

        return (
          <Fragment key={username}>
            {nodes.map(node => {
              const { position } = node;
              const currentPosition = add(position, delta);
              const [width, height] = getNodeSize(node);
              const [x, y] = currentPosition;
              return (
                <g key={node.id}>
                  <Rect
                    x={x}
                    y={y}
                    rx={cornerRadius}
                    width={width}
                    height={height}
                  />
                  {getNodeIO(node).map((io, i) => {
                    const [cx, cy] = add(
                      getIOCenterPosition(node, io),
                      currentPosition,
                    );
                    return (
                      <Ellipse key={i} cx={cx} cy={cy} rx={1 / 3} ry={1 / 3} />
                    );
                  })}
                </g>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};
