import React, { FC } from 'react';
import { randomNumberSequence } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { RandomNumberSequenceValue } from 'helicon';
import { ProbabilityDistributionInput } from './ProbabilityDistributionInput';
import { Col, InfoText, Label } from '../../../../components';

export const FloatArrayRandomNumberSequenceValueInput: FC<
  ValueInputProps<number[]>
> = ({ id, value, seededValue, paramDescription, onChange }) => {
  const { distribution, length } = value as RandomNumberSequenceValue;
  return (
    <Col gap={1} align="stretch">
      <Label style={{ margin: 0 }}>Values</Label>
      <ProbabilityDistributionInput
        id={id}
        value={distribution}
        paramDescription={paramDescription}
        onChange={value => onChange(randomNumberSequence(value, length))}
      />
      <div />
      <Label style={{ margin: 0 }}>Length</Label>
      <ProbabilityDistributionInput
        id={id}
        value={length}
        seededValue={seededValue.length}
        paramDescription={paramDescription}
        onChange={value => onChange(randomNumberSequence(distribution, value))}
      />
      <div />
      <InfoText>{seededValue.map(n => n.toFixed(3)).join(' ')}</InfoText>
    </Col>
  );
};
