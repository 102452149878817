import { SSNode, Vector } from '../types';
import { getNodeSize, add, subtract, divideScalar, min, max } from '../utils';

export const getNodesCenter = (nodes: SSNode[]): Vector => {
  if (nodes.length === 0) return [0, 0];

  const [tl, br] = nodes.reduce(
    ([tl, br], node) => [
      min(tl, node.position),
      max(br, add(node.position, getNodeSize(node))),
    ],
    [[Infinity, Infinity] as Vector, [-Infinity, -Infinity] as Vector],
  );

  return add(tl, divideScalar(subtract(br, tl), 2));
};
