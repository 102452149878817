import { State } from './State';
import { Action, ActionType } from './Action';

export const rootReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.DeleteProject:
      return {
        ...state,
        graphs: state.graphs.filter(g => g.id !== action.id),
      };
    default:
      return state;
  }
};
