import { LayoutState } from './State';
import { Action, ActionType } from './Action';

export const layoutReducer = (
  layout: LayoutState,
  action: Action,
): LayoutState => {
  switch (action.type) {
    case ActionType.SetRightColumnSize:
      return { ...layout, rightColumnSize: action.size };
    case ActionType.SetRightColumnIsOpen:
      return { ...layout, rightColumnIsOpen: action.isOpen };
    default:
      return layout;
  }
};
