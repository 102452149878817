import { EXTENSIONS } from '../extensions';

export const AVAILABLE_NODES: string[] = [
  'AudioBufferSourceNode',
  'AudioDestinationNode',
  'BiquadFilterNode',
  'ChannelMergerNode',
  'ChannelSplitterNode',
  'ConstantSourceNode',
  'ConvolverNode',
  'DelayNode',
  'DynamicsCompressorNode',
  'GainNode',
  'OscillatorNode',
  'PannerNode',
  'StereoPannerNode',
  'WaveShaperNode',
  ...EXTENSIONS.map(e => `${e.type}Node`),
];
