import styled from 'styled-components';
import { lineHeight } from '../style';

export const Col = styled.div<{
  gap?: number;
  justify?: string;
  align?: string;
  flex?: string;
}>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => (flex !== undefined ? flex : '0 1 auto')};
  align-items: ${({ align }) => align ?? 'flex-start'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  gap: ${({ gap }) => (gap ?? 0) * lineHeight}px;
`;
