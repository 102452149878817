import React, { FC, useContext, memo } from 'react';
import styled from 'styled-components';
import {
  ActionContext,
  SelectionContext,
  ActivityContext,
  GraphContext,
} from '../contexts';
import {
  SSEdge,
  Target,
  ActivityType,
  NodeTranslationActivity,
  TargetType,
} from '../../../types';
import { getIOCenterPosition, add } from '../../../utils';
import { TAB_ORDER } from '../../../data';
import { strokeWidth } from '../../../style';
import { SessionContext } from '../../../contexts';
import { EdgeDescription } from './EdgeDescription';

const Line = styled.line`
  stroke: var(
    ${({ selected }: { selected?: boolean }) =>
      selected ? '--edge-selected-color' : '--edge-color'}
  );
  stroke-width: 0.2;
  stroke-linecap: round;
  cursor: default;
`;
const NonScalingLine = styled(Line)`
  stroke-width: ${strokeWidth};
  vector-effect: non-scaling-stroke;
  pointer-events: none;
`;

export type EdgeUIProps = {
  edge: SSEdge;
};

export const EdgeUI: FC<EdgeUIProps> = memo(({ edge, edge: { from, to } }) => {
  const { graph } = useContext(GraphContext);
  const { session } = useContext(SessionContext);
  const { setSelection, setFocusTarget, setHoverTarget } =
    useContext(ActionContext);
  const { selections, select } = useContext(SelectionContext);
  const activities = useContext(ActivityContext);

  // use updated positions during node translation activity
  const fromNode = graph.nodes[from.node];
  const toNode = graph.nodes[to.node];
  const translationActivity = Object.values(activities).find(
    activity =>
      activity?.type === ActivityType.NodeTranslation &&
      activity.nodes.some(n => n.id === fromNode.id || n.id === toNode.id),
  ) as NodeTranslationActivity | undefined;

  const fromPosition = translationActivity?.nodes.some(
    n => n.id === fromNode.id,
  )
    ? add(fromNode.position, translationActivity.delta)
    : fromNode.position;

  const toPosition = translationActivity?.nodes.some(n => n.id === toNode.id)
    ? add(toNode.position, translationActivity.delta)
    : toNode.position;

  const [x1, y1] = add(fromPosition, getIOCenterPosition(fromNode, from));
  const [x2, y2] = add(toPosition, getIOCenterPosition(toNode, to));

  const target: Target = { type: TargetType.Edge, id: edge.id };

  const selected = Object.values(selections).some(selection =>
    selection?.some(t => t.type === TargetType.Edge && t.id == edge.id),
  );

  return (
    <g
      tabIndex={TAB_ORDER.EDGE}
      onFocus={() => setFocusTarget(target)}
      onBlur={() => setFocusTarget(undefined)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.stopPropagation();
          setSelection([target]);
        }
      }}
    >
      <title>
        <EdgeDescription graph={graph} id={edge.id} />
      </title>
      <NonScalingLine x1={x1} y1={y1} x2={x2} y2={y2} selected={selected} />
      <Line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        onMouseOver={() => setHoverTarget(target)}
        onMouseOut={() => setHoverTarget(undefined)}
        onClick={select(target)}
        selected={selected}
        pointerEvents={
          !session ||
          activities[session.username]?.type === ActivityType.NodeConnection
            ? 'none'
            : undefined
        }
      />
    </g>
  );
});
