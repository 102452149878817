// 10 digits have char codes 48-57
// 26 uppercase letters have char codes 65-90
// 26 lowercase letters have char codes 97-122
export const randomID = (
  length: number = 12,
  rand: () => number = Math.random,
): string => {
  const codes = new Array(length);
  for (let i = 0; i < length; i++) {
    let code = Math.floor(rand() * 62) + 48;
    if (code > 57) code += 7;
    if (code > 90) code += 6;
    codes[i] = code;
  }
  return String.fromCharCode(...codes);
};
