import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { TAB_ORDER } from '../../../data';
import { AudioGraphContext } from '../contexts';

const SVG = styled.svg`
  display: block;
  cursor: pointer;
  rect {
    fill: var(--play-button-color);
    &:hover {
      fill: var(--play-button-hover-color);
    }
  }
`;

export type StopButtonProps = {
  size: number;
};

export const StopButton: FC<StopButtonProps> = ({ size }) => {
  const { audioGraph } = useContext(AudioGraphContext);

  return (
    <SVG
      tabIndex={TAB_ORDER.HEADER_BUTTON}
      onClick={() => audioGraph.stop()}
      onKeyDown={e => {
        if (e.key === 'Enter') audioGraph.stop();
      }}
      width={size}
      height={size}
      viewBox="0 0 1 1"
    >
      <rect x={0.05} y={0.05} width={0.9} height={0.9} />
    </SVG>
  );
};
