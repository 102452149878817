import React, { FC } from 'react';
import styled from 'styled-components';
import { ValueInputProps } from './ValueInput';
import { TAB_ORDER } from '../../../../data';
import { lineHeight } from '../../../../style';
import { Button, Row, Col, InfoText, Label } from '../../../../components';
import { RhythmStepsInput } from './RhythmStepsInput';
import { WeightInput } from './WeightInput';
import { WeightedRandomMemberValue } from 'helicon';
import { weightedRandomMember } from 'helicon/build';
import { usePosition } from '../../hooks';

const Container = styled.div`
  background-color: var(--input-disabled-color);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const Info = styled.span`
  padding: 0 ${0.5 * lineHeight}px;
  color: var(--text-secondary-color);
  flex: 1;
`;

const ModifyButton = styled(Button)`
  width: calc(100% / 8);
  aspect-ratio: 1;
  text-align: center;
`;

const RhythmWeightInput = styled(WeightInput)`
  height: auto;
  align-self: stretch;
  width: 25%;
`;

const WeightLabel = styled(Label)`
  flex: 1;
  margin: 0;
  padding: 0 ${0.5 * lineHeight}px;
`;

export const RhythmWeightedRandomMemberValueInput: FC<
  ValueInputProps<number[]>
> = ({ id, disabled, value, seededValue, onChange, node }) => {
  const { items } = value as WeightedRandomMemberValue;

  const onChangeItem = (nv: [number[], number], i: number): void => {
    const copy = [...items];
    copy[i] = nv;
    onChange(weightedRandomMember(copy));
  };

  const position = usePosition(node.id);

  // find index of seeded value
  const seededIndex = items.findIndex(
    ([v]) =>
      v.length === seededValue.length &&
      v.every((vv: number, i: number) => vv === seededValue[i]),
  );

  return (
    <Col gap={1} align="stretch">
      <Col align="stretch" gap={1}>
        {items.map(([v, weight], i) => (
          <Container id={id}>
            <Row align="center">
              <ModifyButton
                disabled={items.length == 0}
                tabIndex={TAB_ORDER.INPUT_SECONDARY}
                onClick={() => {
                  onChangeItem([v.slice(0, -1), weight], i);
                }}
              >
                -
              </ModifyButton>
              <ModifyButton
                tabIndex={TAB_ORDER.INPUT_SECONDARY}
                onClick={() => {
                  onChangeItem([[...v, 0], weight], i);
                }}
              >
                +
              </ModifyButton>
              <Info>
                {v.length} step{v.length !== 1 && 's'}
              </Info>
            </Row>
            <RhythmStepsInput
              value={v}
              onChange={nv => onChangeItem([nv, weight], i)}
              highlightedIndex={i === seededIndex ? position : undefined}
            />
            <Row align="center">
              <RhythmWeightInput
                id={`${id}_weight`}
                disabled={disabled}
                tabIndex={TAB_ORDER.INPUT}
                value={weight}
                onChange={nv => onChangeItem([v, nv], i)}
              />
              <WeightLabel htmlFor={`${id}_weight`}>weight</WeightLabel>
              <ModifyButton
                disabled={disabled || items.length <= 1}
                tabIndex={TAB_ORDER.INPUT}
                onClick={() => {
                  const copy = [...items];
                  copy.splice(i, 1);
                  onChange(weightedRandomMember(copy));
                }}
                onKeyDown={e => e.stopPropagation()}
              >
                ×
              </ModifyButton>
            </Row>
          </Container>
        ))}
        <Button
          disabled={disabled}
          tabIndex={TAB_ORDER.INPUT}
          onClick={() => onChange(weightedRandomMember([...items, [[], 1]]))}
          onKeyDown={e => e.stopPropagation()}
          fullWidth
        >
          +
        </Button>
      </Col>
      <InfoText>{seededValue.join(' ')}</InfoText>
    </Col>
  );
};
