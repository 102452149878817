import { NodeExtension, ParamType } from 'helicon';

export const PitchShifterExtension: NodeExtension = {
  type: 'PitchShifter',
  processor: '/PitchShifterProcessor.js',
  description: {
    numberOfInputs: 1,
    numberOfOutputs: 1,
    outputChannelCount: [2],
    channelCount: 2,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      detune: {
        type: ParamType.AudioParam,
        default: 0,
      },
    },
    signals: {},
  },
};
