import { MouseEvent, useContext } from 'react';
import { MenuItems } from '../types';
import { ContextMenuContext } from '../contexts';

export const useContextMenu = (
  items: MenuItems,
): ((e: MouseEvent<Element>) => void) => {
  const { open } = useContext(ContextMenuContext);

  const onContextMenu = (e: MouseEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    open([e.pageX, e.pageY], items);
  };

  return onContextMenu;
};
