import { ExternalState, HistoryEntry, VectorClock, Typed } from '../types';
import { historyPosition } from './historyPosition';
import { replayHistory } from './replayHistory';

// splices an action into history at the correct position in clock order
// collapses the new action with a previous action if it is collapsable
export function insertAction<Action extends Typed, DocumentState, UserState>(
  initialState: ExternalState<DocumentState, UserState>,
  history: HistoryEntry<Action, DocumentState, UserState>[],
  action: Action,
  username: string,
  clock: VectorClock,
  reduce: (
    state: ExternalState<DocumentState, UserState>,
    action: Action,
    username: string,
  ) => ExternalState<DocumentState, UserState>,
  canCollapse?: (action: Action) => boolean,
): HistoryEntry<Action, DocumentState, UserState>[] {
  const position = historyPosition(history, clock);
  const prior = history.slice(0, position);
  const current: HistoryEntry<Action, DocumentState, UserState> = {
    action,
    username,
    clock,
    state: reduce(
      history[position - 1]?.state ?? initialState,
      action,
      username,
    ),
    tombstone: false,
  };

  // handle action collapse
  if (canCollapse && canCollapse(action)) {
    // find previous action by same actor
    let previousPosition = position - 1;
    while (previousPosition >= 0) {
      const { action: priorAction, username: priorUsername } = prior[
        previousPosition
      ];
      if (
        priorUsername != username ||
        (canCollapse(priorAction) && priorAction.type !== action.type)
      ) {
        previousPosition -= 1;
      } else {
        break;
      }
    }
    if (previousPosition >= 0) {
      // remove previous action
      const previousAction = history[previousPosition].action;
      if (previousAction.type === action.type) {
        prior.splice(previousPosition, 1);
      }
    }
  }

  // replay actions following the current action in clock order
  return replayHistory(
    initialState,
    [...prior, current],
    history.slice(position),
    reduce,
  );
}
