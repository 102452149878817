import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Row } from '../../../../components';
import { TAB_ORDER } from '../../../../data';
import { lineHeight } from '../../../../style';
import { RhythmStepsInput } from './RhythmStepsInput';

const Container = styled.div`
  background-color: var(--input-disabled-color);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Info = styled.span`
  padding: 0 ${lineHeight}px;
  color: var(--text-secondary-color);
  flex: 1;
`;

const ModifyButton = styled(Button)`
  width: calc(100% / 8);
  aspect-ratio: 1;
  text-align: center;
`;

export type RhythmInputProps = {
  id?: string;
  width?: number;
  value: number[];
  onChange: (value: number[]) => void;
  highlightedIndex?: number;
};

export const RhythmInput: FC<RhythmInputProps> = ({
  id,
  value,
  onChange,
  highlightedIndex,
}) => {
  return (
    <Container id={id}>
      <Row align="center">
        <ModifyButton
          disabled={value.length == 0}
          tabIndex={TAB_ORDER.INPUT_SECONDARY}
          onClick={() => {
            onChange(value.slice(0, -1));
          }}
        >
          -
        </ModifyButton>
        <ModifyButton
          tabIndex={TAB_ORDER.INPUT_SECONDARY}
          onClick={() => {
            onChange([...value, 0]);
          }}
        >
          +
        </ModifyButton>
        <Info>
          {value.length} step{value.length !== 1 && 's'}
        </Info>
      </Row>
      <RhythmStepsInput
        value={value}
        onChange={onChange}
        highlightedIndex={highlightedIndex}
      />
    </Container>
  );
};
