export type VectorClock = { [username: string]: number };

export type ExternalState<DocumentState, UserState> = {
  users: {
    [username: string]: UserState;
  };
  document: DocumentState;
};

export type ExternalReducer<Action extends Typed, DocumentState, UserState> = (
  state: ExternalState<DocumentState, UserState>,
  action: Action,
  username: string,
) => ExternalState<DocumentState, UserState>;

export interface Typed {
  type: string;
}
export interface Clocked {
  clock: VectorClock;
}

export type InternalState<Action extends Typed, DocumentState, UserState> = {
  clock: VectorClock;
  state: ExternalState<DocumentState, UserState>;
  initialState: ExternalState<DocumentState, UserState>;
  history: HistoryEntry<Action, DocumentState, UserState>[];
  redoStack: Action[];
};

export type HistoryEntry<Action extends Typed, DocumentState, UserState> = {
  clock: VectorClock;
  username: string;
  action: Action;
  state: ExternalState<DocumentState, UserState>;
  tombstone: boolean;
};

// internal actions

export enum InternalActionType {
  ACTION = 'ACTION',
  SYNC = 'SYNC',
  UNDO = 'UNDO',
  REDO = 'REDO',
}

export type ActionAction<Action> = {
  type: InternalActionType.ACTION;
  clock: VectorClock;
  username: string;
  action: Action;
};

export type SyncAction<Action, DocumentState, UserState> = {
  type: InternalActionType.SYNC;
  clock: VectorClock;
  username: string;
  initialState: ExternalState<DocumentState, UserState>;
  history: {
    clock: VectorClock;
    username: string;
    action: Action;
    tombstone: boolean;
  }[];
};

export type UndoAction = {
  type: InternalActionType.UNDO;
  clock: VectorClock;
  username: string;
};

export type RedoAction = {
  type: InternalActionType.REDO;
  clock: VectorClock;
  username: string;
};

export type InternalAction<Action, DocumentState, UserState> =
  | ActionAction<Action>
  | SyncAction<Action, DocumentState, UserState>
  | UndoAction
  | RedoAction;

// peer message types

export enum PeerMessageType {
  REQUEST_SYNC = 'REQUEST_SYNC',
  SYNC = 'SYNC',
  ACTION = 'ACTION',
  UNDO = 'UNDO',
}

export type PeerMessage<Action, DocumentState, UserState> =
  | { type: PeerMessageType.REQUEST_SYNC; clock: VectorClock }
  | {
      type: PeerMessageType.SYNC;
      initialState: ExternalState<DocumentState, UserState>;
      history: {
        clock: VectorClock;
        username: string;
        action: Action;
        tombstone: boolean;
      }[];
      clock: VectorClock;
    }
  | {
      type: PeerMessageType.ACTION;
      action: Action;
      clock: VectorClock;
    }
  | {
      type: PeerMessageType.UNDO;
      clock: VectorClock;
    };
