export enum ParamType {
  AudioParam,
  AudioBuffer,
  Value,
}

export type AudioParamParamDescription = {
  type: ParamType.AudioParam;
  default: number;
  min?: number;
  max?: number;
};

export type AudioBufferParamDescription = {
  type: ParamType.AudioBuffer;
  default: AudioBuffer | null;
  writeOnce?: boolean;
};

export type ValueParamDescription<T = any> = {
  type: ParamType.Value;
  default: T;
};

export type ParamDescription =
  | AudioParamParamDescription
  | AudioBufferParamDescription
  | ValueParamDescription;

export type SignalDescription<T = any> = {
  trigger?: boolean;
  readable?: boolean;
  default?: T;
};

export type NodeDescription = {
  numberOfInputs: number;
  numberOfOutputs: number;
  outputChannelCount?: number[];
  channelCount?: number;
  channelCountMode?: 'max' | 'clamped-max' | 'explicit';
  channelInterpretation?: 'speakers' | 'discrete';
  params: { [name: string]: ParamDescription };
  signals: { [name: string]: SignalDescription };
};
