import { HistoryEntry, Typed, ExternalReducer, ExternalState } from '../types';

export function replayHistory<Action extends Typed, DocumentState, UserState>(
  initialState: ExternalState<DocumentState, UserState>,
  history: HistoryEntry<Action, DocumentState, UserState>[],
  futureHistory: HistoryEntry<Action, DocumentState, UserState>[],
  reduce: ExternalReducer<Action, DocumentState, UserState>,
): HistoryEntry<Action, DocumentState, UserState>[] {
  const revisedHistory = [...history];
  futureHistory.forEach(({ action, username, clock, tombstone }) => {
    const state =
      revisedHistory.length > 0
        ? revisedHistory[revisedHistory.length - 1].state
        : initialState;
    revisedHistory.push({
      action,
      username,
      clock,
      state: tombstone ? state : reduce(state, action, username),
      tombstone,
    });
  });
  return revisedHistory;
}
