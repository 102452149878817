import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { FormDialog } from '../../../components';
import { lineHeight } from '../../../style';
import { downloadAudioFile } from '../../../audioUtils/downloadAudioFile';
import { GraphContext } from '../contexts';

export type DownloadAudioFileDialogProps = {
  close: () => void;
};

const Row = styled.div`
  margin-top: ${lineHeight}px;
  text-align: center;
`;

const floatPattern = /^[0-9]*\.?[0-9]*$/;

export const DownloadAudioFileDialog: FC<DownloadAudioFileDialogProps> = ({
  close,
}) => {
  const { seededGraph } = useContext(GraphContext);

  return (
    <FormDialog
      title="Download audio file"
      buttonText="Download"
      close={close}
      onSubmit={async data => {
        const numberOfChannels = parseInt(data.numChannels);
        const sampleRate = parseInt(data.sampleRate);
        const bitDepth = parseInt(data.bitDepth);
        const duration = parseFloat(data.duration);
        const fadeIn = data.fadeIn ? parseFloat(data.fadeIn) : 0;
        const fadeOut = data.fadeOut ? parseFloat(data.fadeOut) : 0;
        await downloadAudioFile(
          seededGraph,
          numberOfChannels,
          sampleRate,
          bitDepth,
          duration,
          fadeIn,
          fadeOut,
        );
        close();
        return null;
      }}
      fields={{
        numChannels: {
          label: 'Number of channels',
          type: 'radio',
          options: {
            '2': 'Stereo',
            '1': 'Mono',
          },
        },
        sampleRate: {
          label: 'Sample rate (kHz)',
          type: 'radio',
          options: {
            '44100': '44.1',
            '48000': '48',
            '96000': '96',
          },
        },
        bitDepth: {
          label: 'Bit depth',
          type: 'radio',
          options: {
            '16': '16',
            '32': '32',
          },
        },
        duration: {
          label: 'Duration (seconds)',
          pattern: floatPattern,
          validate: value =>
            parseFloat(value) <= 0 ? 'Duration must be longer than zero' : null,
        },
        fadeIn: {
          label: 'Fade in (seconds)',
          pattern: floatPattern,
          validate: (_, values) =>
            parseFloat(values.fadeIn) > parseFloat(values.duration)
              ? 'Fade in time must be less than duration'
              : null,
        },
        fadeOut: {
          label: 'Fade out (seconds)',
          pattern: floatPattern,
          validate: (_, values) =>
            parseFloat(values.fadeOut) >
            Math.max(0, parseFloat(values.duration) - parseFloat(values.fadeIn))
              ? 'Fade out time must be less than duration after fade in'
              : null,
        },
      }}
      layout={[
        ['numChannels', 'sampleRate', 'bitDepth'],
        ['duration', 'fadeIn', 'fadeOut'],
      ]}
      initialValues={{
        numChannels: '2',
        sampleRate: '44100',
        bitDepth: '16',
        duration: '0',
        fadeIn: '0',
        fadeOut: '0',
      }}
      renderInfo={values => (
        <Row>
          Wave file, approx.{' '}
          {parseFloat(
            (
              (parseInt(values.numChannels) *
                parseInt(values.bitDepth) *
                parseInt(values.sampleRate) *
                parseFloat(values.duration)) /
              8000000
            ).toFixed(2),
          )}{' '}
          MB
        </Row>
      )}
    />
  );
};
