import React, { FC } from 'react';
import styled from 'styled-components';
import { lineHeight } from '../style';

const Circle = styled.circle`
  fill: var(--text-color);
`;

const Text = styled.text`
  dominant-baseline: central;
  text-anchor: middle;
  fill: var(--background-color);
`;

export type IdenticonProps = {
  username: string;
  loading?: boolean;
  color?: string;
  onClick?: () => void;
};

export const Identicon: FC<IdenticonProps> = ({ username, color, onClick }) => (
  <svg width={lineHeight} height={lineHeight}>
    <Circle cx="50%" cy="50%" r="50%" onClick={onClick} />
    <Text x="50%" y="50%" fill={color}>
      {username[0].toUpperCase()}
    </Text>
  </svg>
);
