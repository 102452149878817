import React, { FC, useContext } from 'react';
import { GraphContext } from '../contexts';
import { Target, TargetType } from '../../../types';
import { NodeDescription } from './NodeDescription';
import { EdgeDescription } from './EdgeDescription';
import { IODescription } from './IODescription';

export type TargetDescriptionProps = {
  target: Target;
};

export const TargetDescription: FC<TargetDescriptionProps> = ({ target }) => {
  const { graph } = useContext(GraphContext);
  switch (target.type) {
    case TargetType.Node:
      return <NodeDescription node={graph.nodes[target.id]} />;
    case TargetType.Edge:
      return <EdgeDescription graph={graph} id={target.id} />;
    case TargetType.IO:
      return (
        <IODescription node={graph.nodes[target.io.node]} io={target.io} />
      );
    default:
      throw new Error('unrecognized target type');
  }
};
