import React, { FC, useContext } from 'react';
import { readAccount, listFiles } from '../../api';
import { useAPIData } from '../../hooks';
import { SessionContext } from '../../contexts';
import { DefaultScaffold, Link } from '../../components';
import { AccountSettingsController } from './components';
import { TAB_ORDER } from '../../data';

export type AccountSettingsProps = {};

export const AccountSettings: FC<AccountSettingsProps> = () => {
  const { session } = useContext(SessionContext);
  const [, accountError, accountData] = useAPIData(
    session == undefined
      ? undefined
      : () => readAccount({ username: session.username }),
    [session],
  );
  const [, filesError, filesData, setFiles] = useAPIData(
    session === undefined ? undefined : () => listFiles({ session }),
    [session],
  );

  return session && accountData && filesData ? (
    <AccountSettingsController
      account={accountData.account}
      files={filesData.files}
      setFiles={files => setFiles({ files })}
      session={session}
    />
  ) : (
    <DefaultScaffold
      breadcrumbs={
        <>
          <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            sinesaw
          </Link>
          {' / '}
          <Link
            plain
            href="/account-settings"
            tabIndex={TAB_ORDER.HEADER_BUTTON}
          >
            account settings
          </Link>
        </>
      }
      error={
        accountError ||
        filesError ||
        (session === undefined ? 'unauthorized' : null)
      }
    />
  );
};
