import React, { FC, useRef, useContext, ReactNode } from 'react';
import { TAB_ORDER } from '../data';
import { MenuItems } from '../types';
import { ContextMenuContext } from '../contexts';
import { strokeWidth, lineHeight } from '../style';

export type MenuButtonProps = {
  items: MenuItems;
  children: ReactNode;
  alignRight?: boolean;
};

export const MenuButton: FC<MenuButtonProps> = ({
  items,
  children,
  alignRight,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { open, close, isOpen } = useContext(ContextMenuContext);

  const showMenu = () => {
    if (ref.current === null) return;
    const { bottom, right, left } = ref.current.getBoundingClientRect();
    open(
      [
        alignRight ? right + 2 * strokeWidth : left - 2 * strokeWidth,
        bottom + 0.75 * lineHeight,
      ],
      items,
      ref.current,
    );
  };

  const toggleMenu = () => {
    if (isOpen) close();
    else showMenu();
  };

  return (
    <div
      ref={ref}
      tabIndex={TAB_ORDER.HEADER_BUTTON}
      onClick={e => {
        e.stopPropagation();
        toggleMenu();
      }}
      onContextMenu={e => {
        e.preventDefault();
        showMenu();
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu();
        }
      }}
    >
      {children}
    </div>
  );
};
