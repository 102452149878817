import { NodeExtension, ParamType } from 'helicon';

export const ArrangerExtension: NodeExtension = {
  type: 'Arranger',
  processor: '/ArrangerProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 2,
    outputChannelCount: [1, 1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      clock: {
        type: ParamType.AudioParam,
        min: -1,
        max: 1,
        default: 0,
      },
      sections: {
        type: ParamType.Value,
        default: [],
      },
    },
    signals: {
      position: {
        readable: true,
        trigger: true,
        default: 0,
      },
    },
  },
};
