import styled from 'styled-components';
import { lineHeight, strokeWidth } from '../style';

export type ToolbarGroupProps = {
  flex?: boolean;
  right?: boolean;
};

export const ToolbarGroup = styled.div`
  display: flex;
  flex: ${({ flex }: ToolbarGroupProps) => (flex ? `1 1 auto` : `0 0 auto`)};
  align-items: center;
  height: ${2.5 * lineHeight}px;
  padding: 0 ${lineHeight}px;
  overflow: hidden;
  justify-content: ${({ right }: ToolbarGroupProps) =>
    right ? 'flex-end' : 'flex-start'};
  > *:nth-child(n + 2) {
    margin-left: ${5 * strokeWidth}px;
  }
`;
