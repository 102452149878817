import React, { forwardRef, useState, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { lineHeight } from '../style';

const ContainerSpan = styled.span`
  position: relative;
  display: inline-block;
  height: ${lineHeight}px;
  line-height: ${lineHeight}px;
  &:focus {
    outline-color: var(--outline-secondary-color);
  }
`;
const PlaceholderSpan = styled.span`
  display: inline-block;
  height: ${lineHeight}px;
  line-height: ${lineHeight}px;
  padding-right: 1px;
  white-space: pre;
`;
const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: ${lineHeight / 2}px;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  display: inline-block;
  height: ${lineHeight}px;
  line-height: ${lineHeight}px;
  &:focus {
    outline-color: var(--outline-secondary-color);
  }
`;

export type EditableTextProps = {
  text: string;
  onChange: (value: string) => void;
  tabIndex?: number;
};

export const EditableText = forwardRef<HTMLSpanElement, EditableTextProps>(
  ({ text, onChange, tabIndex }, ref) => {
    const [editing, setEditing] = useState(false);
    const [textValue, setTextValue] = useState('');

    const update = (e: SyntheticEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (value !== '' && value !== text) {
        onChange(e.currentTarget.value);
      }
      setEditing(false);
    };

    const startEditing = () => {
      setEditing(true);
      setTextValue(text);
    };

    return (
      <ContainerSpan
        onClick={editing ? undefined : startEditing}
        onKeyDown={
          editing
            ? undefined
            : e => {
                if (e.key === 'Enter') {
                  startEditing();
                }
              }
        }
        tabIndex={tabIndex}
        ref={ref}
      >
        <PlaceholderSpan style={{ visibility: editing ? 'hidden' : 'visible' }}>
          {editing ? textValue : text}
        </PlaceholderSpan>
        {editing && (
          <Input
            autoFocus
            onFocus={e => e.currentTarget.select()}
            defaultValue={text}
            onChange={e => setTextValue(e.currentTarget.value)}
            onBlur={update}
            spellCheck={false}
            autoComplete="off"
            onKeyDown={e => {
              switch (e.key) {
                case 'Enter':
                  update(e);
                  break;
                case 'Escape':
                  setEditing(false);
                  break;
              }
            }}
          />
        )}
      </ContainerSpan>
    );
  },
);
