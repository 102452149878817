import React, { FC, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { useAnimationFrameEffect } from '../../../hooks';
import { strokeWidth } from '../../../style';
import { SSEdge, Vector } from '../../../types';
import { AudioGraphContext } from '../contexts';

export type AnalyserProps = {
  edge: SSEdge;
  size: Vector;
  stepDivisor?: number;
  frameDivisor?: number;
};

const Path = styled.path`
  stroke-width: ${strokeWidth};
  stroke: var(--visualization-stroke-color);
  vector-effect: non-scaling-stroke;
  fill: none;
`;
const SVG = styled.svg`
  flex: 1;
  background-color: var(--visualization-color);
  &:focus {
    outline-color: var(--outline-secondary-color);
  }
  width: auto;
`;
const Line = styled.line`
  stroke-width: ${strokeWidth};
  stroke: var(--visualization-secondary-stroke-color);
  vector-effect: non-scaling-stroke;
`;

const calculatePath = (
  data: Float32Array,
  start: number,
  [width, height]: Vector,
): string => {
  const len = data.length;
  const right = len - start;
  const xScale = (width - strokeWidth) / len;
  const yScale = (height - strokeWidth) * 0.4;
  const xShift = strokeWidth / 2;
  const yShift = -height / 2;

  let d = `M ${xShift} ${height - data[start] * yScale + yShift}`;
  for (let i = 0; i < right; i++) {
    d += ` L ${i * xScale + xShift} ${
      height - data[start + i] * yScale + yShift
    }`;
  }
  for (let i = 0; i < start; i++) {
    d += ` L ${(i + right) * xScale + xShift} ${
      height - data[i] * yScale + yShift
    }`;
  }

  return d;
};

export const Analyser: FC<AnalyserProps> = ({
  edge,
  size,
  size: [width, height],
  stepDivisor = 1,
  frameDivisor = 1,
}) => {
  const { audioGraph, audioContext } = useContext(AudioGraphContext);
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);

  const maxPathRef = useRef<SVGPathElement>(null);
  const minPathRef = useRef<SVGPathElement>(null);
  const maxValuesRef = useRef(new Float32Array(width / stepDivisor));
  const minValuesRef = useRef(new Float32Array(width / stepDivisor));
  const indexRef = useRef(0);

  const fromNode = audioGraph.audioNodes[edge.from.node];

  useEffect(() => {
    if (fromNode) {
      const analyserNode = new AnalyserNode(audioContext, {
        fftSize: 1024,
      });
      fromNode.connect(analyserNode, edge.from.index);
      setAnalyser(analyserNode);

      return () => {
        analyserNode.disconnect();
      };
    } else {
      setAnalyser(null);
      return undefined;
    }
  }, [edge, audioContext, fromNode]);

  useAnimationFrameEffect(
    () => {
      if (!analyser || !audioGraph.playing) return;

      const data = new Float32Array(analyser.fftSize);
      analyser.getFloatTimeDomainData(data);

      const maxValues = maxValuesRef.current;
      const minValues = minValuesRef.current;
      const index = indexRef.current;
      const len = maxValues.length;

      // Compute average power over the interval.
      let maxV = data[0];
      let minV = data[0];
      for (let i = 1; i < data.length; i++) {
        maxV = Math.max(data[i], maxV);
        minV = Math.min(data[i], minV);
      }

      maxValues[index] = maxV;
      minValues[index] = minV;

      maxPathRef.current?.setAttribute(
        'd',
        calculatePath(maxValues, index, size),
      );
      minPathRef.current?.setAttribute(
        'd',
        calculatePath(minValues, index, size),
      );

      indexRef.current = (index + 1) % len;
    },
    [analyser, audioGraph, audioContext],
    frameDivisor,
  );

  return (
    <SVG width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <Line x1={0} y1={height / 2} x2={width} y2={height / 2} />

      <Path
        ref={maxPathRef}
        d={calculatePath(maxValuesRef.current, indexRef.current, size)}
      />
      <Path
        ref={minPathRef}
        d={calculatePath(minValuesRef.current, indexRef.current, size)}
      />
      <Line x1={strokeWidth / 2} y1={0} x2={strokeWidth / 2} y2={height} />
      <Line
        x1={width - strokeWidth / 2}
        y1={0}
        x2={width - strokeWidth / 2}
        y2={height}
      />
    </SVG>
  );
};
