import React, { forwardRef, useContext } from 'react';
import { GraphContext, ActionContext } from '../contexts';
import { EditableText } from '../../../components';
import { TAB_ORDER } from '../../../data';

export type GraphNameProps = {};

export const GraphName = forwardRef<HTMLSpanElement, GraphNameProps>(
  (_, ref) => {
    const { graph } = useContext(GraphContext);
    const { updateGraphMetadata } = useContext(ActionContext);

    return (
      <EditableText
        text={graph.name}
        onChange={name => {
          updateGraphMetadata({ name });
        }}
        tabIndex={TAB_ORDER.HEADER_BUTTON}
        ref={ref}
      />
    );
  },
);
