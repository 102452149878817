import { ValueType } from 'helicon';

export const VALUE_TYPE_LABELS: { [type in ValueType]: string } = {
  [ValueType.Fixed]: 'fixed',
  [ValueType.RandomNumber]: 'random',
  [ValueType.RandomInteger]: 'random',
  [ValueType.RandomMember]: 'choice',
  [ValueType.WeightedRandomMember]: 'choice',
  [ValueType.WeightedRandomSubset]: 'subset',
  [ValueType.WeightedRandomSequence]: 'sequence',
  [ValueType.RandomNumberSequence]: 'random',
};
