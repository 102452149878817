import { useState, useEffect } from 'react';
import { Response } from '../utils';

export const useAPIData = <Data>(
  fn?: () => Response<Data>,
  key: any[] = [],
): [boolean, string | null, Data | null, (data: Data) => void] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Data | null>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      setLoading(true);
      setData(null);
      setError(null);
      if (fn) {
        const { status, body, error } = await fn();
        if (status === 200) {
          setData(body);
        } else {
          setError(error);
        }
        setLoading(false);
      }
    })();
  }, key);
  return [loading, error, data, setData];
};
