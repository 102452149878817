import React, { FC } from 'react';

export type StarIconProps = {
  numPoints?: number;
  start?: number; // position of starting point in radians
  skip?: number;
};
export const StarIcon: FC<StarIconProps> = ({
  numPoints = 5,
  start = 0,
  skip = 2,
}) => {
  const points = new Array(numPoints);
  const step = (skip * Math.PI * 2) / numPoints;
  for (let i = 0; i < numPoints; i++) {
    const angle = start + i * step;
    const x = 0.5 + 0.5 * Math.sin(angle);
    const y = 0.5 + 0.5 * Math.cos(angle);
    points[i] = `${x} ${y}`;
  }
  return <path d={`M ${points.join(' L ')}`} />;
};
