export enum ActionType {
  DeleteProject = 'DeleteProject',
}

export type Action = DeleteProjectAction;

export type DeleteProjectAction = {
  type: ActionType.DeleteProject;
  id: string;
};

export const deleteProject = (id: string): DeleteProjectAction => ({
  type: ActionType.DeleteProject,
  id,
});
