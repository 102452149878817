import { State } from './State';

export const initialState = (): State => {
  const sessionJSON = localStorage.getItem('session');

  let session = sessionJSON ? JSON.parse(sessionJSON) : undefined;

  if (session && session.expiry < Date.now() / 1000) {
    session = undefined;
  }

  return { session };
};
