import { Value, ValueType } from 'helicon';
import { NODE_DESCRIPTIONS } from '../data';

export const defaultParams = (type: string): { [name: string]: Value } => {
  const description = NODE_DESCRIPTIONS[type];
  return Object.keys(description.params).reduce((memo: any, k) => {
    memo[k] = { type: ValueType.Fixed, value: description.params[k].default };
    return memo;
  }, {});
};
