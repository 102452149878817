import React, { FC } from 'react';
import { strokeWidth } from '../../../../style';
import { range } from '../../../../utils';

const NUM_POINTS = 16;
const f = (x: number): number => 1 - Math.pow(x, 3);

export type ExponentialIconProps = {
  width: number;
  height: number;
};

export const ExponentialIcon: FC<ExponentialIconProps> = ({
  width,
  height,
}) => {
  const w = width - strokeWidth;
  const h = height - strokeWidth;
  const s = strokeWidth / 2;
  return (
    <path
      d={`M 0 ${h + s} ${range(0, 1, 1 / NUM_POINTS)
        .map(x => `L ${x * w + s} ${f(x) * h + s}`)
        .join(' ')}`}
    />
  );
};
