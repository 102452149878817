import styled from 'styled-components';
import { lineHeight, pulse } from '../style';

// export const LoadingIndicator = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: ${2 * lineHeight}px;
//   height: ${lineHeight / 2}px;
//   margin: -${lineHeight / 4}px 0 0 -${lineHeight}px;
//   background: var(--outline-color);
//   animation: ${rotate} 2s linear infinite;
//   z-index: 1;
// `;

export const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -${lineHeight / 2}px 0 0 -${lineHeight / 2}px;
  width: ${lineHeight}px;
  height: ${lineHeight}px;
  border-radius: ${lineHeight / 2}px;
  background: var(--outline-color);
  animation: ${pulse} 1s linear infinite alternate;
  z-index: 1;
`;
