import { AudioNodeParams } from 'helicon';
import { createContext } from 'react';
import {
  SSGraph,
  Rect,
  Activity,
  Target,
  Vector,
  SSNode,
  SSIO,
} from '../../../types';

import {
  Action,

  // commands
  copy,
  paste,

  // interaction
  setCursorPosition,
  setActivity,
  performActivity,
  setFocusTarget,
  setHoverTarget,
  setSelection,

  // graph
  setGraph,
  updateGraphMetadata,
  addNode,
  removeNode,
  updateNode,
  updateNodeData,
  connect,
  disconnect,
  disconnectIO,
  deleteSelection,
  translateSelection,

  // layout and grid
  setDOMRect,
  setViewport,
  panViewport,
  zoomViewport,
  setLayoutSize,
  setRightColumnSize,
  setRightColumnIsOpen,
} from '../reducers';

export type Actions = {
  // commands
  copy(): void;
  paste(position?: Vector): void;

  // interaction
  setCursorPosition(p: Vector | undefined): void;
  setActivity(a: Activity | undefined): void;
  performActivity(a: Activity): void;
  setFocusTarget(t: Target | undefined): void;
  setHoverTarget(t: Target | undefined): void;
  setSelection(s: Target[] | undefined): void;

  // graph
  setGraph(g: SSGraph): void;
  updateGraphMetadata(d: { name?: string; forkedFrom?: string[] }): void;
  addNode(n: SSNode): void;
  removeNode(id: string): void;
  updateNode(id: string, params: AudioNodeParams): void;
  updateNodeData(id: string, d: { name?: string; position?: Vector }): void;
  connect(o: SSIO, i: SSIO): void;
  disconnect(id: string): void;
  disconnectIO(io: SSIO): void;
  deleteSelection(): void;
  translateSelection(v: Vector): void;

  // layout and grid
  setDOMRect(r: Rect): void;
  setViewport(r: Rect): void;
  panViewport(d: Vector): void;
  zoomViewport(c: Vector, a: number): void;
  setLayoutSize(s: Vector): void;
  setRightColumnSize(s: number): void;
  setRightColumnIsOpen(o: boolean): void;
};

export const bindActions = (dispatch: (a: Action) => void): Actions => ({
  // commands
  copy() {
    dispatch(copy());
  },
  paste(position) {
    dispatch(paste(position));
  },

  // interaction
  setCursorPosition(p: Vector | undefined) {
    dispatch(setCursorPosition(p));
  },
  setActivity(a: Activity | undefined) {
    dispatch(setActivity(a));
  },
  performActivity(a: Activity) {
    dispatch(performActivity(a));
  },
  setFocusTarget(t: Target | undefined) {
    dispatch(setFocusTarget(t));
  },
  setHoverTarget(t: Target | undefined) {
    dispatch(setHoverTarget(t));
  },
  setSelection(s: Target[] | undefined) {
    dispatch(setSelection(s));
  },

  // graph
  setGraph(g: SSGraph) {
    dispatch(setGraph(g));
  },
  updateGraphMetadata(d: { name?: string; forkedFrom?: string[] }) {
    dispatch(updateGraphMetadata(d));
  },
  addNode(n: SSNode) {
    dispatch(addNode(n));
  },
  removeNode(id: string) {
    dispatch(removeNode(id));
  },
  updateNode(id: string, p: AudioNodeParams) {
    dispatch(updateNode(id, p));
  },
  updateNodeData(id: string, d: { name?: string; position?: Vector }) {
    dispatch(updateNodeData(id, d));
  },
  connect(o: SSIO, i: SSIO) {
    dispatch(connect(o, i));
  },
  disconnect(id: string) {
    dispatch(disconnect(id));
  },
  disconnectIO(io: SSIO) {
    dispatch(disconnectIO(io));
  },
  deleteSelection() {
    dispatch(deleteSelection());
  },
  translateSelection(v: Vector) {
    dispatch(translateSelection(v));
  },

  // layout and grid
  setDOMRect(r: Rect) {
    dispatch(setDOMRect(r));
  },
  setViewport(r: Rect) {
    dispatch(setViewport(r));
  },
  panViewport(d: Vector) {
    dispatch(panViewport(d));
  },
  zoomViewport(c: Vector, a: number) {
    dispatch(zoomViewport(c, a));
  },
  setLayoutSize(s: Vector) {
    dispatch(setLayoutSize(s));
  },
  setRightColumnSize(s: number) {
    dispatch(setRightColumnSize(s));
  },
  setRightColumnIsOpen(o: boolean) {
    dispatch(setRightColumnIsOpen(o));
  },
});

export const ActionContext = createContext<Actions>(bindActions(() => {}));
