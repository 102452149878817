import React, { FC, useContext, ReactNode } from 'react';
import styled from 'styled-components';
import { MenuButton } from './MenuButton';
import { MenuIcon } from './MenuIcon';
import { NetworkActivityIndicator } from './NetworkActivityIndicator';
import { SessionInfo } from './SessionInfo';
import { Toolbar } from './Toolbar';
import { ToolbarGroup } from './ToolbarGroup';
import { lineHeight } from '../style';
import { createGraph } from '../api';
import { RoutingContext, SessionContext } from '../contexts';
import { useBlockingAPIAction } from '../hooks';
import { MenuItems } from '../types';

export type LayoutProps = {
  menuItems?: MenuItems;
  children?: ReactNode;
  breadcrumbs?: ReactNode;
  networkActivityPending?: boolean;
  networkActive?: boolean;
  networkError?: boolean;
  headerContent?: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
`;

const Breadcrumbs = styled.div`
  display: flex;
  white-space: pre;
  margin-left: ${lineHeight}px;
`;

export const Layout: FC<LayoutProps> = ({
  children,
  breadcrumbs,
  menuItems,
  networkActivityPending,
  networkActive,
  networkError,
  headerContent,
}) => {
  const { route } = useContext(RoutingContext);
  const { session } = useContext(SessionContext);
  const blockingCreateGraph = useBlockingAPIAction(createGraph);

  return (
    <Container>
      <Toolbar as="header">
        <ToolbarGroup flex>
          <MenuButton
            items={
              menuItems || [
                [
                  [
                    'New project',
                    async () => {
                      if (session) {
                        const response = await blockingCreateGraph({ session });
                        if (response.status === 200) {
                          route(`/${response.body}`);
                        }
                      }
                    },
                    session === undefined,
                  ],
                ],
              ]
            }
          >
            <MenuIcon size={lineHeight} />
          </MenuButton>
          <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
          <NetworkActivityIndicator
            pending={networkActivityPending ?? false}
            active={networkActive ?? false}
            error={!!networkError ?? false}
          />
        </ToolbarGroup>
        <ToolbarGroup right>
          {headerContent}
          <SessionInfo />
        </ToolbarGroup>
      </Toolbar>
      {children}
    </Container>
  );
};
