import React, { FC } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { TAB_ORDER } from '../../../data';
import { GraphContext } from '../contexts';
import { StarIcon } from './icons/StarIcon';

const SVG = styled.svg`
  display: block;
  cursor: pointer;
  * {
    fill: var(--play-button-color);
  }
  &:hover * {
    fill: var(--play-button-hover-color);
  }
`;

export type ReseedButtonProps = {
  size: number;
};

export const ReseedButton: FC<ReseedButtonProps> = ({ size }) => {
  const { reSeed } = useContext(GraphContext);
  return (
    <SVG
      tabIndex={TAB_ORDER.HEADER_BUTTON}
      width={size}
      height={size}
      viewBox="0 0 1 1"
      onClick={reSeed}
    >
      <StarIcon />
    </SVG>
  );
};
