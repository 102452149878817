import { SSNode, SSIO, IOType } from '../types';
import { NODE_DESCRIPTIONS } from '../data';
import { range } from './range';

export const getNodeIO = (node: SSNode): SSIO[] => {
  return getDefaultNodeIO(node.type, node.id);
};

export const getDefaultNodeIO = (nodeType: string, id: string): SSIO[] => {
  const { numberOfInputsAndAudioParams, numberOfOutputs } =
    NODE_DESCRIPTIONS[nodeType];
  return [
    ...range(0, numberOfInputsAndAudioParams - 1).map(index => ({
      type: IOType.Input,
      node: id,
      index,
    })),
    ...range(0, numberOfOutputs - 1).map(index => ({
      type: IOType.Output,
      node: id,
      index,
    })),
  ];
};
