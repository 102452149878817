import React, { FC } from 'react';
import { SSNode, SSIO, IOType } from '../../../types';
import { NODE_DESCRIPTIONS } from '../../../data';

export type IODescriptionProps = {
  node: SSNode;
  io: SSIO;
};

export const IODescription: FC<IODescriptionProps> = ({
  node,
  io,
}: IODescriptionProps) => {
  const description = NODE_DESCRIPTIONS[node.type];
  return (
    <>
      {`${description.displayName} ${
        io.type == IOType.Output
          ? description.outputNames[io.index]
          : io.index < description.numberOfInputs
          ? description.inputNames[io.index]
          : description.audioParamNames[io.index - description.numberOfInputs]
      }`}
    </>
  );
};
