import { nativeNodeDescriptions } from '../audioGraph';
import {
  Graph,
  Node,
  Edge,
  AudioNodeParams,
  NodeDescription,
  NodeExtension,
  Value,
  FixedValue,
  RandomNumberValue,
  RandomIntegerValue,
  RandomMemberValue,
  WeightedRandomMemberValue,
  WeightedRandomSubsetValue,
  WeightedRandomSequenceValue,
  RandomNumberSequenceValue,
  ProbabilityDistribution,
  UniformDistribution,
  ExponentialDistribution,
  GaussianDistribution,
  ValueType,
  ProbabilityDistributionType,
} from '../types';
import { getAudioParamNames, VERSION } from '../utils';

type NodeBuilder = (id: string, type: string, params?: AudioNodeParams) => void;

type EdgeBuilder = (
  fromNodeID: string,
  fromIndex: number,
  toNodeID: string,
  toIndexOrParam: number | string,
) => void;

export const fixed = (value: any): FixedValue => ({
  type: ValueType.Fixed,
  value,
});
export const randomNumber = (
  distribution: ProbabilityDistribution,
): RandomNumberValue => ({
  type: ValueType.RandomNumber,
  distribution,
});
export const randomInteger = (
  distribution: ProbabilityDistribution,
): RandomIntegerValue => ({
  type: ValueType.RandomInteger,
  distribution,
});
export const randomMember = <T>(items: T[]): RandomMemberValue<T> => ({
  type: ValueType.RandomMember,
  items,
});
export const weightedRandomMember = <T>(
  items: [T, number][],
): WeightedRandomMemberValue<T> => ({
  type: ValueType.WeightedRandomMember,
  items,
});
export const weightedRandomSubset = <T>(
  items: [T, number][],
): WeightedRandomSubsetValue<T> => ({
  type: ValueType.WeightedRandomSubset,
  items,
});
export const weightedRandomSequence = <T>(
  items: [T, number][],
  length: ProbabilityDistribution,
): WeightedRandomSequenceValue<T> => ({
  type: ValueType.WeightedRandomSequence,
  items,
  length,
});
export const randomNumberSequence = (
  distribution: ProbabilityDistribution,
  length: ProbabilityDistribution,
): RandomNumberSequenceValue => ({
  type: ValueType.RandomNumberSequence,
  distribution,
  length,
});
export const uniform = (min: number, max: number): UniformDistribution => ({
  type: ProbabilityDistributionType.Uniform,
  min,
  max,
});
export const exponential = (
  min: number,
  max: number,
  exponent: number,
): ExponentialDistribution => ({
  type: ProbabilityDistributionType.Exponential,
  min,
  max,
  exponent,
});
export const gaussian = (
  mean: number,
  variance: number,
): GaussianDistribution => ({
  type: ProbabilityDistributionType.Gaussian,
  mean,
  variance,
});

const valueBuilders = {
  fixed,
  randomNumber,
  randomInteger,
  randomMember,
  weightedRandomMember,
  weightedRandomSubset,
  weightedRandomSequence,
  randomNumberSequence,
  uniform,
  exponential,
  gaussian,
};

export const build = (
  builder: (
    node: NodeBuilder,
    edge: EdgeBuilder,
    v: typeof valueBuilders,
  ) => void,
  extensions: NodeExtension[] = [],
): Graph => {
  const descriptions = extensions.reduce(
    (memo, { description, type }) => ((memo[type] = description), memo),
    { ...nativeNodeDescriptions } as { [type: string]: NodeDescription },
  );
  const nodes: { [id: string]: Node } = {};
  const edges: { [id: string]: Edge } = {};

  const defaultParams = (type: string): AudioNodeParams => {
    const description = descriptions[type];
    const defaults = Object.keys(description.params).reduce(
      (memo: { [param: string]: Value }, k) => {
        memo[k] = {
          type: ValueType.Fixed,
          value: description.params[k].default,
        };
        return memo;
      },
      {},
    );
    return defaults;
  };

  const paramIndex = (nodeID: string, paramName: string): number => {
    const type = nodes[nodeID].type;
    const description = descriptions[type];
    const paramIndex = getAudioParamNames(description).indexOf(paramName);
    if (paramIndex === -1 && process.env.NODE_ENV !== 'production') {
      throw new Error(`param ${paramName} not available on node ${nodeID}`);
    }
    return description.numberOfInputs + paramIndex;
  };

  const node: NodeBuilder = (id, type, params = {}): void => {
    nodes[id] = {
      id,
      type,
      params: { ...defaultParams(type), ...params },
    };
  };

  let edgeID = 0;
  const edge: EdgeBuilder = (
    fromNodeID,
    fromIndex,
    toNodeID,
    toIndexOrParam,
  ) => {
    const id = `E${edgeID++}`;
    edges[id] = {
      id,
      from: { node: fromNodeID, index: fromIndex },
      to: {
        node: toNodeID,
        index:
          typeof toIndexOrParam === 'number'
            ? toIndexOrParam
            : paramIndex(toNodeID, toIndexOrParam),
      },
    };
  };

  builder(node, edge, valueBuilders);

  return {
    v: VERSION,
    nodes,
    edges,
  };
};
