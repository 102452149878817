import React, { FC, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { GraphUI } from './GraphUI';
import { SelectionInfo } from './SelectionInfo';
import { MiniMap } from './MiniMap';
import { Meters } from './Meters';
import { PlayButton } from './PlayButton';
import { StopButton } from './StopButton';
import { CurrentTime } from './CurrentTime';
import { GraphName } from './GraphName';
import { TargetDescription } from './TargetDescription';
import { ResizableColumn } from './ResizableColumn';
import { DownloadAudioFileDialog } from './DownloadAudioFileDialog';
import { ShareDialog } from './ShareDialog';
import {
  columnPadding,
  columnHandleWidth,
  lineHeight,
  inputWidth,
  strokeWidth,
} from '../../../style';
import { Toolbar, ToolbarGroup, Link, Layout } from '../../../components';
import { Vector } from '../../../types';
import {
  FocusTargetContext,
  HoverTargetContext,
  ActionContext,
  GridContext,
  LayoutContext,
  GraphContext,
  UndoContext,
} from '../contexts';
import { createGraph, forkGraph } from '../../../api';
import { RoutingContext, SessionContext } from '../../../contexts';
import { useBlockingAPIAction, useResizeObserver } from '../../../hooks';
import { TAB_ORDER } from '../../../data';
import { ReseedButton } from './ReseedButton';
import { FlashOverlay } from './FlashOverlay';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

export const Columns = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
`;

export const GrowingToolbarGroup = styled(ToolbarGroup)`
  flex-grow: 1;
  flex-shrink: 0;
`;

export const RightColumnMainContent = styled.div`
  flex: 1;
  margin: 0 ${-columnPadding}px;
  padding: ${lineHeight / 2}px ${columnPadding}px;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const RightColumnFooter = styled.div`
  padding: ${lineHeight / 2}px 0;
`;

export const FooterInfoText = styled.div`
  color: var(--text-secondary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-variant-ligatures: none;
`;

export const Workspace = styled.div`
  flex: 1;
  position: relative;
  > * {
    position: absolute;
  }
`;

export type EditorLayoutProps = {
  graphSavePending: boolean;
  graphSaveActive: boolean;
  graphSaveError: string | null;
  cursorPosition: Vector | undefined;
};

export const EditorLayout: FC<EditorLayoutProps> = ({
  graphSavePending,
  graphSaveActive,
  graphSaveError,
  cursorPosition,
}) => {
  const {
    setCursorPosition,
    setRightColumnIsOpen,
    setRightColumnSize,
    setDOMRect,
  } = useContext(ActionContext);
  const { undo, redo } = useContext(UndoContext);
  const { route } = useContext(RoutingContext);
  const { session } = useContext(SessionContext);
  const { rightColumnIsOpen, rightColumnSize } = useContext(LayoutContext);
  const focusTargets = useContext(FocusTargetContext);
  const hoverTargets = useContext(HoverTargetContext);
  const { getGridPosition } = useContext(GridContext);
  const { graph, seed } = useContext(GraphContext);
  const workspace = useResizeObserver<HTMLDivElement>(setDOMRect);
  const [downloadAudioFileDialogActive, setDownloadAudioFileDialogActive] =
    useState<boolean>(false);
  const [shareDialogActive, setShareDialogActive] = useState<boolean>(false);
  const blockingCreateGraph = useBlockingAPIAction(createGraph);
  const blockingForkGraph = useBlockingAPIAction(forkGraph);
  const graphNameRef = useRef<HTMLSpanElement | null>(null);

  const isOwner =
    session !== undefined &&
    session.username === graph.id.slice(0, session.username.length);
  const ownHoverTarget = session && hoverTargets[session.username];
  const ownFocusTarget = session && focusTargets[session.username];

  return (
    <>
      {downloadAudioFileDialogActive && (
        <DownloadAudioFileDialog
          close={() => setDownloadAudioFileDialogActive(false)}
        />
      )}
      {shareDialogActive && (
        <ShareDialog close={() => setShareDialogActive(false)} />
      )}
      <Layout
        breadcrumbs={
          <>
            <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
              sinesaw
            </Link>
            {' / '}
            <Link
              plain
              href={`/${graph.author}`}
              tabIndex={TAB_ORDER.HEADER_BUTTON}
            >
              {graph.author}
            </Link>
            {' / '}
            <GraphName ref={graphNameRef} />
          </>
        }
        menuItems={[
          [
            [
              'New project',
              async () => {
                if (session) {
                  const response = await blockingCreateGraph({ session });
                  if (response.status === 200) {
                    route(`/${response.body}`);
                  }
                }
              },
              session === undefined,
            ],
            [
              'Back to projects',
              session ? `/${session.username}` : undefined,
              session === undefined,
            ],
          ],
          [
            ['Undo', undo, undo === undefined],
            ['Redo', redo, redo === undefined],
          ],
          [
            [
              'Rename',
              () => {
                graphNameRef.current?.click();
              },
              !isOwner,
            ],
            [
              'Fork',
              async () => {
                if (session) {
                  const response = await blockingForkGraph({
                    session,
                    data: graph,
                  });
                  if (response.status === 200) {
                    route(`/${response.body}`);
                  }
                }
              },
              session === undefined,
            ],
            [
              'Share',
              () => {
                setShareDialogActive(true);
              },
              !isOwner,
            ],
            [
              'Download audio file',
              () => {
                setDownloadAudioFileDialogActive(true);
              },
            ],
          ],
        ]}
        networkActivityPending={graphSavePending}
        networkActive={graphSaveActive}
        networkError={!!graphSaveError}
      >
        <Columns>
          <LeftColumn>
            <Workspace
              ref={workspace}
              onMouseLeave={() => setCursorPosition(undefined)}
              onMouseEnter={({ pageX, pageY }) => {
                setCursorPosition(getGridPosition([pageX, pageY]));
              }}
              onMouseMove={({ pageX, pageY }) => {
                setCursorPosition(getGridPosition([pageX, pageY]));
              }}
            >
              {workspace.current && <GraphUI />}
            </Workspace>
            <Toolbar as="footer">
              <ToolbarGroup>
                <PlayButton size={lineHeight} />
                <StopButton size={lineHeight} />
                <FooterInfoText>
                  <CurrentTime />
                </FooterInfoText>
              </ToolbarGroup>
              <GrowingToolbarGroup>
                <ReseedButton size={lineHeight} />
                <FooterInfoText>0x{seed.toString(16)}</FooterInfoText>
              </GrowingToolbarGroup>
              <ToolbarGroup right>
                <FooterInfoText>
                  {ownHoverTarget ? (
                    <TargetDescription target={ownHoverTarget} />
                  ) : ownFocusTarget ? (
                    <TargetDescription target={ownFocusTarget} />
                  ) : cursorPosition ? (
                    `${Math.floor(cursorPosition[0])},${Math.floor(
                      cursorPosition[1],
                    )}`
                  ) : null}
                </FooterInfoText>
                <MiniMap />
              </ToolbarGroup>
            </Toolbar>
          </LeftColumn>
          <ResizableColumn
            size={rightColumnSize}
            setSize={setRightColumnSize}
            isOpen={rightColumnIsOpen}
            setIsOpen={setRightColumnIsOpen}
            minSize={
              inputWidth +
              2 * (columnPadding + lineHeight + strokeWidth) +
              columnHandleWidth
            }
            maxSize={640}
          >
            <RightColumnMainContent>
              <SelectionInfo />
            </RightColumnMainContent>
            <RightColumnFooter>
              <Meters />
            </RightColumnFooter>
          </ResizableColumn>
        </Columns>
      </Layout>
      <FlashOverlay />
    </>
  );
};
