import { createContext } from 'react';
import { SSGraph } from '../../../types';
import { SeededGraph, seedGraph } from 'helicon';
import { defaultGraph } from '../../../utils';

export type GraphOptions = {
  graph: SSGraph;
  seededGraph: SeededGraph;
  seed: number;
  reSeed: () => void;
};

const graph = defaultGraph();
const seed = 0;
const seededGraph = seedGraph(graph, seed);

export const GraphContext = createContext<GraphOptions>({
  graph,
  seed,
  seededGraph,
  reSeed: () => {},
});
