import { Vector, SSNode } from '../types';
import { UI_CONFIGURATION } from '../data';
import { defaultParams } from './defaultParams';
import { randomID } from './randomID';

export const node = (type: string, position: Vector): SSNode => ({
  id: randomID(),
  type,
  name: UI_CONFIGURATION[type].shortName,
  position,
  params: defaultParams(type),
});
