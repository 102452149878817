import { createContext } from 'react';
import { AudioGraph } from 'helicon';

export type AudioGraphOptions = {
  updating: Promise<void>;
  audioGraph: AudioGraph;
  audioContext: BaseAudioContext;
};

export const defaultAudioGraphOptions = {
  updating: Promise.resolve(),
  // don't set a default here as it will lead to an AudioContext being created
  // which is expensive
  audioGraph: undefined as any,
  audioContext: undefined as any,
};

export const AudioGraphContext = createContext<AudioGraphOptions>(
  defaultAudioGraphOptions,
);
