import { NodeExtension, ParamType } from 'helicon';

export const ADSREnvelopeExtension: NodeExtension = {
  type: 'ADSREnvelope',
  processor: '/ADSREnvelopeProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      clock: {
        type: ParamType.AudioParam,
        default: 0,
      },
      attack: {
        type: ParamType.AudioParam,
        min: 0.001,
        max: 60,
        default: 0.001,
      },
      decay: {
        type: ParamType.AudioParam,
        min: 0.001,
        max: 60,
        default: 0.5,
      },
      sustain: {
        type: ParamType.AudioParam,
        min: 0,
        max: 1,
        default: 0.5,
      },
      release: {
        type: ParamType.AudioParam,
        min: 0.001,
        max: 60,
        default: 0.5,
      },
    },
    signals: {
      trigger: {
        trigger: true,
      },
    },
  },
};
