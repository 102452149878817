import React, { FC, memo } from 'react';

export type WaveformProps = {
  height: number;
  buffer: AudioBuffer;
  color: string;
  fromIndex?: number;
  toIndex?: number;
  numPoints?: number;
};

export const Waveform: FC<WaveformProps> = memo(
  ({
    height,
    buffer,
    color,
    fromIndex = 0,
    toIndex = buffer.length - 1,
    numPoints = 128,
  }) => {
    const data = buffer.getChannelData(0);
    const len = toIndex - fromIndex;
    const step = Math.floor(len / numPoints);

    let d = `M 0 1`;
    let max: number;
    for (let i = fromIndex; i < toIndex; i += step) {
      max = Math.abs(data[i]);
      for (let j = i + 1, jmax = Math.min(toIndex, i + step); j < jmax; j++) {
        max = Math.max(max, Math.abs(data[j]));
      }
      d += ` L ${(i - fromIndex) / len} ${1 - max}`;
    }
    d += `L 1 1`;

    return (
      <svg
        style={{ flex: '1' }}
        height={height}
        viewBox="0 0 1 1"
        preserveAspectRatio="none"
      >
        <path d={d} fill={color} />
      </svg>
    );
  },
);
