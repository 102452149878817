import { useState } from 'react';
import { useAudioGraphEffect } from './useAudioGraphEffect';

export const usePosition = (nodeID: string): number | undefined => {
  const [position, setPosition] = useState<number | undefined>(undefined);
  useAudioGraphEffect(
    audioGraph => {
      const audioNode = audioGraph.audioNodes[nodeID];
      setPosition(audioNode.signals?.position);
      const updatePosition = () => setPosition(audioNode.signals?.position);
      audioNode.addEventListener('trigger', updatePosition);
      return () => audioNode.removeEventListener('trigger', updatePosition);
    },
    audioGraph => [audioGraph.audioNodes[nodeID]],
  );
  return position;
};
