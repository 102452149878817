import React, { useState, useEffect } from 'react';
import { Vector } from '../types';
import { magnitude, subtract } from '../utils';

export type DragHandler<T> = (
  position: Vector,
  origin: Vector,
  delta: Vector,
  t: T,
  modifierKeys: { metaKey: boolean; shiftKey: boolean },
) => void;

type DragState<T> = {
  origin: Vector;
  position: Vector;
  active: boolean;
  t: T;
};

const MINIMUM_DISTANCE = 10;

export const useDrag = <T>(
  onDrag?: DragHandler<T>,
  onDrop?: DragHandler<T>,
): ((e: React.MouseEvent, t: T) => void) => {
  const [state, setState] = useState<DragState<T> | undefined>(undefined);

  useEffect(() => {
    if (state === undefined) return;

    const { origin, position, active, t } = state;

    const mouseMove = (e: MouseEvent) => {
      const position: Vector = [e.pageX, e.pageY];
      setState({
        ...state,
        position,
        active:
          active || magnitude(subtract(origin, position)) > MINIMUM_DISTANCE,
      });
      active &&
        onDrag &&
        onDrag(position, origin, subtract(position, origin), t, e);
    };

    const mouseUp = (e: MouseEvent) => {
      const position: Vector = [e.pageX, e.pageY];
      active &&
        onDrop &&
        onDrop(position, origin, subtract(position, origin), t, e);
      setState(undefined);
    };

    const keyChange = (e: KeyboardEvent) => {
      active &&
        onDrag &&
        onDrag(position, origin, subtract(position, origin), t, e);
    };

    const preventClick = (e: MouseEvent) => {
      e.stopImmediatePropagation();
    };

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mouseup', mouseUp);
    window.addEventListener('keydown', keyChange);
    window.addEventListener('keyup', keyChange);
    if (active) {
      window.addEventListener('click', preventClick, true);
    }

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', mouseUp);
      window.removeEventListener('keydown', keyChange);
      window.removeEventListener('keyup', keyChange);
      window.removeEventListener('click', preventClick, true);
    };
  });

  return (e: React.MouseEvent, t: T) => {
    if (e.button !== 0) return;
    const position: Vector = [e.pageX, e.pageY];
    setState({
      position,
      origin: position,
      active: false,
      t,
    });
  };
};
