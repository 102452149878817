import { hsluvToHex } from 'hsluv-ts';

// hues
const color =
  (hue: number, saturation: number = 85) =>
  (lightness: number) =>
    hsluvToHex([hue, saturation, lightness]);

const outline = color(0, 100);
const background = color(120);
const node = color(270);
const io = color(270);
const edge = color(330, 70);
const text = color(240);
const uiControl = color(270);
const visualizationStroke = color(270);
const visualization = color(180);

const hues: { [key: string]: (lightness: number) => string } = {
  '--outline-color': outline,
  '--outline-secondary-color': outline,
  '--background-color': background,
  '--background-secondary-color': background,
  '--text-color': text,
  '--text-secondary-color': text,
  '--error-text-color': outline,
  '--input-color': uiControl,
  '--input-disabled-color': uiControl,
  '--input-highlighted-color': uiControl,
  '--input-text-color': text,
  '--input-disabled-text-color': text,
  '--button-color': uiControl,
  '--button-text-color': uiControl,
  '--button-hover-color': uiControl,
  '--button-hover-text-color': uiControl,
  '--button-disabled-color': uiControl,
  '--button-disabled-text-color': uiControl,
  '--visualization-stroke-color': visualizationStroke,
  '--visualization-secondary-stroke-color': visualizationStroke,
  '--visualization-color': visualization,
  '--visualization-secondary-color': visualization,
  '--ui-control-color': uiControl,
  '--ui-control-secondary-color': uiControl,
  '--node-stroke-color': node,
  '--node-color': node,
  '--node-label-color': node,
  '--node-highlighted-color': node,
  '--node-selected-color': node,
  '--node-selected-highlighted-color': node,
  '--node-placeholder-color': node,
  '--node-placeholder-stroke-color': node,
  '--io-stroke-color': io,
  '--io-color': io,
  '--io-selected-color': io,
  '--io-targeted-color': io,
  '--edge-color': edge,
  '--edge-selected-color': edge,
  '--grid-color': background,
  '--grid-line-color': background,
  '--grid-line-secondary-color': background,
  '--play-button-color': uiControl,
  '--play-button-hover-color': uiControl,
  '--menu-color': background,
  '--menu-active-color': visualization,
  '--menu-divider-color': background,
  '--menu-disabled-text-color': background,
  '--menu-border-color': background,
  '--project-preview-color': background,
  '--selection-color': outline,
};

const lightnesses: { [key: string]: number } = {
  '--outline-color': 50,
  '--outline-secondary-color': 50,
  '--background-color': 5,
  '--background-secondary-color': 20,
  '--text-color': 85,
  '--text-secondary-color': 75,
  '--error-text-color': 50,
  '--input-color': 35,
  '--input-disabled-color': 20,
  '--input-highlighted-color': 50,
  '--input-text-color': 90,
  '--input-disabled-text-color': 50,
  '--button-color': 90,
  '--button-text-color': 5,
  '--button-hover-color': 70,
  '--button-hover-text-color': 5,
  '--button-disabled-color': 70,
  '--button-disabled-text-color': 50,
  '--visualization-stroke-color': 90,
  '--visualization-secondary-stroke-color': 50,
  '--visualization-color': 20,
  '--visualization-secondary-color': 30,
  '--ui-control-color': 50,
  '--ui-control-secondary-color': 35,
  '--node-stroke-color': 55,
  '--node-color': 30,
  '--node-label-color': 75,
  '--node-highlighted-color': 95,
  '--node-selected-color': 45,
  '--node-selected-highlighted-color': 95,
  '--node-placeholder-color': 35,
  '--node-placeholder-stroke-color': 50,
  '--io-stroke-color': 55,
  '--io-color': 15,
  '--io-selected-color': 45,
  '--io-targeted-color': 65,
  '--edge-color': 45,
  '--edge-selected-color': 65,
  '--grid-color': 7,
  '--grid-line-color': 25,
  '--grid-line-secondary-color': 15,
  '--play-button-color': 50,
  '--play-button-hover-color': 65,
  '--menu-color': 5,
  '--menu-active-color': 35,
  '--menu-divider-color': 35,
  '--menu-disabled-text-color': 50,
  '--menu-border-color': 50,
  '--project-preview-color': 10,
  '--selection-color': 40,
};

export const darkTheme = Object.entries(hues).reduce(
  (memo, [key, hue]) => ((memo[key] = hue(lightnesses[key])), memo),
  {} as { [key: string]: string },
);

export const lightTheme = Object.entries(hues).reduce(
  (memo, [key, hue]) => ((memo[key] = hue(100 - lightnesses[key])), memo),
  {} as { [key: string]: string },
);
