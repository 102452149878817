import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: 600;
  font-family: 'Display', sans-serif;
  font-size: 5em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  color: var(--ui-control-color);
  user-select: text;
`;
