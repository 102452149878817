import { hsluvToHex } from 'hsluv-ts';

export const getUserColor = (
  username: string,
  lightness: number = 50,
): string => {
  let memo = Math.PI;
  for (let i = 0; i < username.length; i++) {
    memo *= username.charCodeAt(i);
  }
  return hsluvToHex([memo, 100, lightness]);
};
