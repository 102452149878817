import React, { FC, useContext, useState, memo } from 'react';
import { AudioGraphContext } from '../contexts';
import { useAnimationFrameEffect } from '../../../hooks';

export type CurrentTimeProps = {
  frameDivisor?: number;
};

export const CurrentTime: FC<CurrentTimeProps> = memo(
  ({ frameDivisor = 1 }) => {
    const { audioContext } = useContext(AudioGraphContext);
    const [time, setTime] = useState<number>(audioContext.currentTime);
    useAnimationFrameEffect(
      () => {
        setTime(audioContext.currentTime);
      },
      [audioContext],
      frameDivisor,
    );

    const hours = `${Math.floor(time / 3600)}`.padStart(2, '0');
    const minutes = `${Math.floor((time % 3600) / 60)}`.padStart(2, '0');
    const seconds = `${Math.floor(time % 60)}`.padStart(2, '0');
    const subseconds = `${Math.floor((time % 1) * 100)}`.padEnd(2, '0');

    return (
      <>
        {hours}:{minutes}:{seconds}.{subseconds}
      </>
    );
  },
);
