import React, { FC, useEffect, useState, useContext } from 'react';
import { confirmEmail, resendEmailConfirmation } from '../../api';
import { SessionContext } from '../../contexts';
import {
  LoadingIndicator,
  BasicLayout,
  ValidatedForm,
  H2,
} from '../../components';
import { validateUsername } from '../../utils';

export type ConfirmEmailProps = {
  path: string[];
};

enum Status {
  Loading,
  Success,
  Error,
  Resent,
}

export const ConfirmEmail: FC<ConfirmEmailProps> = ({ path: [, ...code] }) => {
  const { signIn } = useContext(SessionContext);
  const [status, setStatus] = useState<Status>(Status.Loading);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      const [username, emailConfirmationCode] = code.join('/').split('.');
      const response = await confirmEmail({ username, emailConfirmationCode });
      if (response.status === 200) {
        const { session } = response.body;
        signIn(session);
        setStatus(Status.Success);
      } else {
        setStatus(Status.Error);
      }
    })();
  }, []);

  if (loading || status === Status.Loading) {
    return <LoadingIndicator />;
  }

  return (
    <BasicLayout>
      {status === Status.Success && (
        <>
          <H2 as="h1">Your email was confirmed successfully</H2>
          <p>You are now signed in. Thanks for creating a sinesaw account</p>
        </>
      )}
      {status === Status.Error && (
        <>
          <H2 as="h1">We weren’t able to confirm your email</H2>
          <p>The confirmation link you used may be expired or invalid</p>
          <ValidatedForm
            onSubmit={async ({ username }) => {
              const response = await resendEmailConfirmation({
                username,
              });
              if (response.status === 204) {
                setStatus(Status.Resent);
              }
              return response.error;
            }}
            loading={loading}
            setLoading={setLoading}
            buttonText="Resend link"
            fields={{
              username: {
                label: 'Username',
                validate: validateUsername,
              },
            }}
          />
        </>
      )}
      {status === Status.Resent && (
        <>
          <H2 as="h1">We emailed a new confirmation link.</H2>
          <p>If it doesn’t arrive, be sure to check your spam folder</p>
        </>
      )}
    </BasicLayout>
  );
};
