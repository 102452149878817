import { VectorClock } from '../types';

// returns 1 if the first clock is later than the second clock,
// -1 if the first clock is earlier than the second clock, and 0 if the clocks
// are equal
export function later(clock1: VectorClock, clock2: VectorClock): number {
  const keys1 = Object.keys(clock1);
  const keys2 = Object.keys(clock2);

  let clock1GreaterForSomeKey;
  let sum1 = 0;
  for (const k of keys1) {
    if (clock2[k] === undefined || clock1[k] > clock2[k]) {
      clock1GreaterForSomeKey = true;
    }
    sum1 += clock1[k] ?? 0;
  }

  let clock2GreaterForSomeKey;
  let sum2 = 0;
  for (const k of keys2) {
    if (clock1[k] === undefined || clock2[k] > clock1[k]) {
      clock2GreaterForSomeKey = true;
    }
    sum2 += clock2[k] ?? 0;
  }

  if (clock1GreaterForSomeKey && !clock2GreaterForSomeKey) return 1;
  if (clock2GreaterForSomeKey && !clock1GreaterForSomeKey) return -1;
  if (sum1 !== sum2) return sum1 > sum2 ? 1 : -1;

  // if sums are equal and each clock is ahead, we consider usernames in
  // alphabetical order.
  const uniqueKeys = [...new Set([...keys1, ...keys2])].sort();
  for (const k of uniqueKeys) {
    if (clock2[k] === undefined || clock1[k] > clock2[k]) {
      return 1;
    }
    if (clock1[k] === undefined || clock2[k] > clock1[k]) {
      return -1;
    }
  }

  return 0;
}
