import React, { FC } from 'react';
import { Account, File, Session } from '../../../types';
import { AccountSettingsLayout } from './AccountSettingsLayout';

export type AccountSettingsControllerProps = {
  account: Account;
  files: File[];
  setFiles: (files: File[]) => void;
  session: Session;
};

export const AccountSettingsController: FC<AccountSettingsControllerProps> = ({
  account,
  files,
  setFiles,
  session,
}) => {
  return (
    <AccountSettingsLayout
      account={account}
      files={files}
      setFiles={setFiles}
      session={session}
    />
  );
};
