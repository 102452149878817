import React, { FC } from 'react';
import styled from 'styled-components';
// import { Link } from '../../../components';
import { File } from '../../../types';
import { strokeWidth, lineHeight } from '../../../style';
import { FileRow } from './FileRow';

const Table = styled.table`
  border-spacing: ${2 * strokeWidth}px;
  margin: 0 ${-2 * strokeWidth}px;

  td {
    background-color: var(--background-secondary-color);
    padding: ${lineHeight / 2}px;
  }
`;

export type FilesTableProps = {
  files: File[];
  setFiles: (files: File[]) => void;
};

export const FilesTable: FC<FilesTableProps> = ({ files, setFiles }) => {
  return (
    <>
      <Table>
        {files.map(file => (
          <FileRow
            key={file.contentHash}
            file={file}
            onDelete={() => setFiles(files.filter(f => f !== file))}
          />
        ))}
      </Table>
      <p>
        {(files.reduce((memo, { size }) => memo + size, 0) / 1_000_000).toFixed(
          2,
        )}{' '}
        MB of 100 GB storage used.{' '}
      </p>
      {/* <Link href="/paid-storage">Learn about paid storage</Link>. */}
    </>
  );
};
