import {
  nativeNodeDescriptions,
  NodeDescription,
  ParamType,
  getAudioParamNames,
} from 'helicon';
import { SSNodeDescription, SSParamDescription } from '../types';
import { EXTENSIONS } from '../extensions';
import { UI_CONFIGURATION } from './UI_CONFIGURATION';

// create map of helicon format node descriptions including both native nodes
// and sinesaw extensions
export const HELICON_NODE_DESCRIPTIONS = {
  ...nativeNodeDescriptions,
  ...EXTENSIONS.reduce(
    (memo, { type, description }) => (
      (memo[`${type}Node`] = description), memo
    ),
    {} as { [name: string]: NodeDescription },
  ),
};

// add data from ui configuration to convert to sinesaw extended node
// descriptions
export const NODE_DESCRIPTIONS = Object.entries(
  HELICON_NODE_DESCRIPTIONS,
).reduce((memo, [nodeName, description]) => {
  const { shortName, displayName, inputNames, outputNames } =
    UI_CONFIGURATION[nodeName];
  memo[nodeName] = {
    ...description,
    shortName,
    displayName,
    inputNames,
    outputNames,
    audioParamNames: getAudioParamNames(description),
    params: Object.entries(description.params).reduce(
      (memo, [paramName, param]) => {
        memo[paramName] = {
          ...param,
          ...UI_CONFIGURATION[nodeName].params[paramName],
        };
        return memo;
      },
      {} as { [name: string]: SSParamDescription },
    ),
    numberOfInputsAndAudioParams:
      description.numberOfInputs +
      Object.values(description.params).reduce(
        (count, { type }) =>
          type === ParamType.AudioParam ? count + 1 : count,
        0,
      ),
  };
  return memo;
}, {} as { [name: string]: SSNodeDescription });
