import { SSGraph } from '../types';
import { randomID } from './randomID';
import { randomName } from './randomName';
import { node } from './node';
import { VERSION } from 'helicon';

export const defaultGraph = (
  author: string = '!',
  id: string = `${author}/${randomID()}`,
): SSGraph => {
  const destination = node('AudioDestinationNode', [0, 0]);

  return {
    v: VERSION,
    id,
    author,
    name: randomName(),
    nodes: {
      [destination.id]: destination,
    },
    edges: {},
    incomingEdges: {
      [destination.id]: [],
    },
    outgoingEdges: {
      [destination.id]: [],
    },
  };
};
