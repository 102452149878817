import React, { FC } from 'react';
import { weightedRandomMember } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { Col, InfoText } from '../../../../components';
import { WeightInput } from './WeightInput';
import { WeightedRandomMemberValue } from 'helicon';
import { OptionLabel } from './OptionLabel';

export const BooleanWeightedRandomMemberValueInput: FC<
  ValueInputProps<boolean>
> = ({ id, tabIndex, value, seededValue, onChange, disabled }) => {
  const [[, weight]] = (value as WeightedRandomMemberValue).items;
  return (
    <Col gap={1} align="stretch">
      <OptionLabel htmlFor={`${id}_weight`}>
        <span>probability</span>
        <WeightInput
          id={`${id}_weight`}
          tabIndex={tabIndex}
          disabled={disabled}
          value={weight}
          onChange={nv => {
            onChange(
              weightedRandomMember([
                [true, nv],
                [false, 1 - nv],
              ]),
            );
          }}
          min={0}
          max={1}
        />
      </OptionLabel>
      <InfoText>{seededValue ? 'on' : 'off'}</InfoText>
    </Col>
  );
};
