import { Action, ActionType } from './Action';

const undoableActionTypes: Set<ActionType> = new Set([
  ActionType.Paste,
  ActionType.PerformActivity,
  ActionType.SetGraph,
  ActionType.UpdateGraphMetadata,
  ActionType.AddNode,
  ActionType.RemoveNode,
  ActionType.UpdateNodeData,
  ActionType.UpdateNode,
  ActionType.Connect,
  ActionType.Disconnect,
  ActionType.DisconnectIO,
  ActionType.DeleteSelection,
  ActionType.TranslateSelection,
]);

export const canUndo = ({ type }: Action): boolean =>
  undoableActionTypes.has(type);
