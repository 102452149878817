import React, { FC, useContext, useRef, memo } from 'react';
import styled from 'styled-components';
import { IOUI } from './IOUI';
import {
  SSNode,
  Target,
  NodeTarget,
  ActivityType,
  TargetType,
  Vector,
  Clipboard,
  Activity,
} from '../../../types';
import { NodeLabel } from './NodeLabel';
import {
  SelectionContext,
  ActivityContext,
  ActionContext,
  GraphRefContext,
} from '../contexts';
import { strokeWidth, cornerRadius } from '../../../style';
import { useGridDrag, useAudioGraphEffect } from '../hooks';
import { useContextMenu, useTheme } from '../../../hooks';
import {
  getNodeSize,
  getNodeIO,
  getNodesAndConnectingEdgesClipboard,
} from '../../../utils';
import { TAB_ORDER } from '../../../data';
import { SessionContext } from '../../../contexts';
import { NodeDescription } from './NodeDescription';

type RectProps = {
  dragActive: boolean;
  selected: boolean;
};

const Rect = styled.rect`
  stroke: var(--node-stroke-color);
  stroke-width: ${strokeWidth};
  vector-effect: non-scaling-stroke;
  fill: var(
    ${({ selected, dragActive }: RectProps) =>
      dragActive
        ? '--node-placeholder-color'
        : selected
        ? '--node-selected-color'
        : '--node-color'}
  );
  opacity: ${({ dragActive }: RectProps) => (dragActive ? 0.35 : 1)};
  cursor: default;
`;

export type NodeUIProps = {
  node: SSNode;
};

export const NodeUI: FC<NodeUIProps> = memo(
  ({
    node,
    node: {
      name,
      position: [x, y],
    },
  }) => {
    const {
      setSelection,
      setFocusTarget,
      setHoverTarget,
      setActivity,
      performActivity,
      copy,
      deleteSelection,
    } = useContext(ActionContext);
    const { session } = useContext(SessionContext);
    const { selections, select } = useContext(SelectionContext);
    const activities = useContext(ActivityContext);
    const graphRef = useContext(GraphRefContext);
    const theme = useTheme();

    const [width, height] = getNodeSize(node);
    const selection = session && selections[session.username];
    const selected = selection?.some(
      t => t.type == TargetType.Node && t.id === node.id,
    );
    const selectedByAny = Object.values(selections).some(selection =>
      selection?.some(t => t.type == TargetType.Node && t.id === node.id),
    );
    const dragActive = Object.values(activities).some(
      activity =>
        activity?.type === ActivityType.NodeTranslation &&
        activity.nodes.some(n => n.id === node.id),
    );

    const getTranslationActivity = (
      _position: Vector,
      _origin: Vector,
      delta: Vector,
      clipboard: Clipboard,
      e: { metaKey: boolean },
    ): Activity =>
      e.metaKey
        ? {
            type: ActivityType.NodeClone,
            delta,
            clipboard,
            seed: Math.random(),
          }
        : {
            type: ActivityType.NodeTranslation,
            delta,
            nodes: clipboard.nodes,
          };
    const beginDrag = useGridDrag<Clipboard>(
      (...args) => setActivity(getTranslationActivity(...args)),
      (...args) => {
        performActivity(getTranslationActivity(...args));
        setActivity(undefined);
      },
      true,
    );

    const animateRef = useRef<SVGAnimateElement>(null);
    useAudioGraphEffect(
      audioGraph => {
        const audioNode = audioGraph.audioNodes[node.id];
        const trigger = () => {
          if (animateRef.current !== null) {
            (animateRef.current as any).beginElement();
          }
        };
        audioNode?.addEventListener('trigger', trigger as EventListener);
        return () =>
          audioNode?.removeEventListener('trigger', trigger as EventListener);
      },
      audioGraph => [audioGraph.audioNodes[node.id]],
    );

    const onContextMenu = useContextMenu([
      [
        [
          'Delete',
          () => {
            deleteSelection();
          },
        ],
      ],
      [
        [
          'Copy',
          () => {
            copy();
          },
        ],
      ],
    ]);

    const target: Target = { type: TargetType.Node, id: node.id };

    return (
      <g
        id={node.id}
        tabIndex={TAB_ORDER.NODE}
        onFocus={() => {
          setFocusTarget(target);
        }}
        onBlur={() => {
          setFocusTarget(undefined);
        }}
        onMouseDown={(e: React.MouseEvent<SVGGElement>) => {
          e.stopPropagation();
          const selectedNodes =
            selection && selected
              ? (
                  selection.filter(
                    t => t.type === TargetType.Node,
                  ) as NodeTarget[]
                ).map(t => graphRef.current.nodes[t.id])
              : [node];
          beginDrag(
            e,
            getNodesAndConnectingEdgesClipboard(
              graphRef.current,
              selectedNodes,
            ),
          );
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            if (selected && e.metaKey) {
              setSelection(
                selection?.filter(
                  t => t.type === TargetType.Node && t.id === node.id,
                ),
              );
            } else if (selected) {
              setSelection(undefined);
            } else if (e.metaKey) {
              setSelection([...(selection ?? []), target]);
            } else {
              setSelection([target]);
            }
          }
        }}
        onContextMenu={e => {
          if (!selected) setSelection([target]);
          onContextMenu(e);
        }}
      >
        <title>
          <NodeDescription node={node} />
        </title>
        <Rect
          x={x}
          y={y}
          rx={cornerRadius}
          width={width}
          height={height}
          onMouseOver={() => setHoverTarget(target)}
          onMouseOut={() => setHoverTarget(undefined)}
          onClick={select(target)}
          selected={selectedByAny}
          dragActive={dragActive}
        >
          <animate
            ref={animateRef}
            attributeName="fill"
            begin="indefinate"
            dur="0.1s"
            from={
              selected
                ? theme['--node-selected-highlighted-color']
                : theme['--node-highlighted-color']
            }
            to={
              selected ? theme['--node-selected-color'] : theme['--node-color']
            }
          />
        </Rect>
        {!dragActive &&
          getNodeIO(node).map((io, i) => <IOUI node={node} io={io} key={i} />)}
        <NodeLabel x={x + width / 2} y={y + height}>
          {name}
        </NodeLabel>
      </g>
    );
  },
);
