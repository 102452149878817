import { createContext } from 'react';

export type LayoutOptions = {
  rightColumnSize: number;
  rightColumnIsOpen: boolean;
};

export const defaultLayoutOptions: LayoutOptions = {
  rightColumnSize: 0,
  rightColumnIsOpen: false,
};

export const LayoutContext = createContext<LayoutOptions>(defaultLayoutOptions);
