import React, { FC } from 'react';
import { SectionsContentLayout, P } from '../../components';

export const PaidStorage: FC = () => {
  return (
    <SectionsContentLayout
      title="paid storage"
      path="/paid-storage"
      sections={[
        {
          id: 'paid-storage',
          title: 'Paid storage',
          content: (
            <>
              <P>
                Sinesaw is free to use with 10 megabytes of sample storage. As a
                solo developer paying for hosting, this low limit is indended to
                keep costs reasonable.
              </P>
              <P>
                In the future, I intend to add payed sample storage at a
                reasonable rate to cover these hosting costs.
              </P>
            </>
          ),
        },
      ]}
    />
  );
};
