import { Value } from 'helicon';
import { fixed } from 'helicon/build';
import {
  IOType,
  SSEdge,
  SSNode,
  V0GraphForStorage,
  V0IOType,
  V1GraphForStorage,
} from '../types';

export const migrateV0Graph = (
  graph: V0GraphForStorage,
): V1GraphForStorage => ({
  v: '1.0.0',
  id: graph.id,
  name: graph.metadata.name,
  ...(graph.metadata.forkedFrom
    ? { forkedFrom: graph.metadata.forkedFrom }
    : {}),
  nodes: Object.entries(graph.nodes).reduce((memo, [id, node]) => {
    memo[id] = {
      id,
      type: node.audioNode.type,
      name: node.data.name,
      position: node.data.position,
      params: Object.entries(node.audioNode.params).reduce(
        (memo, [name, value]) => {
          // handle change to slew limiter node - rename slew param to tiem and change scale
          if (node.audioNode.type === 'SlewLimiterNode' && name === 'slew') {
            memo['time'] = fixed(
              Math.round(((value * 44100) / 128) * 1000) / 1000,
            );
          } else {
            memo[name] = fixed(value);
          }
          return memo;
        },
        {
          // handle addition of loop param to AD envelope node
          ...(node.audioNode.type === 'ADEnvelopeNode'
            ? { loop: fixed(false) }
            : {}),
          // handle addition of gain param to destination node
          ...(node.audioNode.type === 'AudioDestinationNode'
            ? { gain: fixed(1) }
            : {}),
        } as { [name: string]: Value },
      ),
    };
    return memo;
  }, {} as { [id: string]: SSNode }),
  edges: Object.entries(graph.edges).reduce(
    (memo, [id, edge]) => (
      (memo[id] = {
        id,
        from: {
          ...edge.from,
          type:
            edge.from.type === V0IOType.Input ? IOType.Input : IOType.Output,
        },
        to: {
          ...edge.to,
          type: edge.to.type === V0IOType.Input ? IOType.Input : IOType.Output,
        },
      }),
      memo
    ),
    {} as { [id: string]: SSEdge },
  ),
});
