import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from './components';
import { darkTheme, lightTheme, strokeWidth, cornerRadius } from './style';

// set favicon
const link = document.querySelector('link[rel=icon]');
const match = window.matchMedia('(prefers-color-scheme: dark)');
const updateFavicon = (e: MediaQueryList | MediaQueryListEvent) => {
  const theme = e.matches ? darkTheme : lightTheme;
  const stroke = (strokeWidth * 3) / 16;
  link?.setAttribute(
    'href',
    `data:image/svg+xml,${encodeURIComponent(
      `<svg xmlns="http://www.w3.org/2000/svg" width="3" height="3" viewBox="${
        -stroke / 2
      } ${-stroke / 2} ${3 + stroke} ${3 + stroke}"><rect fill="${
        theme['--node-color']
      }" stroke="${
        theme['--node-stroke-color']
      }" stroke-width="${stroke}" width="3" height="3" rx="${cornerRadius}" /></svg>`,
    )}`,
  );
};
match.addEventListener('change', updateFavicon);
updateFavicon(match);

// render app
ReactDOM.render(<Root />, document.getElementById('app'));
