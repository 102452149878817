import { NodeExtension, ParamType } from 'helicon';

export const SampleSlicerExtension: NodeExtension = {
  type: 'SampleSlicer',
  processor: '/SampleSlicerProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      buffer: {
        type: ParamType.AudioBuffer,
        default: null,
      },
      slicePoints: {
        type: ParamType.Value,
        default: [0],
      },
      clock: {
        type: ParamType.AudioParam,
        default: 0,
      },
      reset: {
        type: ParamType.AudioParam,
        default: 0,
      },
      start: {
        type: ParamType.AudioParam,
        default: 0,
      },
      length: {
        type: ParamType.AudioParam,
        default: 0,
      },
      detune: {
        type: ParamType.AudioParam,
        default: 0,
      },
    },
    signals: {
      position: {
        readable: true,
        trigger: true,
        default: 0,
      },
    },
  },
};
