import { SSNode, Vector } from '../types';
import { NODE_DESCRIPTIONS } from '../data';

export const getNodeSize = (node: SSNode): Vector => {
  return getDefaultNodeSize(node.type);
};

export const getDefaultNodeSize = (type: string): Vector => {
  const description = NODE_DESCRIPTIONS[type];
  return [
    3,
    Math.max(
      description.numberOfInputsAndAudioParams,
      description.numberOfOutputs,
      3,
    ),
  ];
};
