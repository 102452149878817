import React, { FC, useState, useReducer, useMemo } from 'react';
import { GlobalStyle } from './GlobalStyle';
import { Router } from './Router';
import {
  AccountSettings,
  Editor,
  Profile,
  Home,
  ConfirmEmail,
  ResetPassword,
  Developers,
  OpenSource,
  PaidStorage,
  Tutorials,
  Contact,
  About,
} from '../apps';
import { ContextMenu } from './ContextMenu';
import { BlockingOverlay } from './BlockingOverlay';
import { NotFoundPage } from './NotFoundPage';
import { Shade } from './Shade';
import { Vector, MenuItems, Session } from '../types';
import {
  ContextMenuContext,
  ContextMenuOptions,
  SessionContext,
  SessionOptions,
  BlockingOverlayContext,
  BlockingOverlayOptions,
} from '../contexts';
import { rootReducer, initialState, signIn } from '../reducers';

export type RootProps = {};

export const Root: FC<RootProps> = () => {
  const [{ session }, dispatch] = useReducer(
    rootReducer,
    undefined,
    initialState,
  );
  const sessionOptions = useMemo<SessionOptions>(
    () => ({
      session,
      signIn: (s: Session) => {
        dispatch(signIn(s));
        localStorage.setItem('session', JSON.stringify(s));
      },
      signOut: () => {
        localStorage.removeItem('session');
        window.location.reload();
      },
    }),
    [session],
  );

  const [menuOptions, setMenuOptions] = useState<
    | {
        items: MenuItems;
        position: Vector;
        spliceElement?: HTMLElement | SVGElement | null;
        key: number;
      }
    | undefined
  >();
  const contextMenuOptions = useMemo<ContextMenuOptions>(
    () => ({
      open: (position, items, spliceElement) =>
        setMenuOptions({
          position,
          items,
          key: (menuOptions?.key ?? 0) + 1,
          spliceElement,
        }),
      close: () => setMenuOptions(undefined),
      isOpen: menuOptions !== undefined,
    }),
    [menuOptions],
  );

  const [blockingOverlayActive, setBlockingOverlayActive] = useState<boolean>(
    false,
  );
  const [blockingOverlayError, setBlockingOverlayError] = useState<
    string | null
  >(null);
  const blockingOverlayOptions = useMemo<BlockingOverlayOptions>(
    () => ({
      active: blockingOverlayActive,
      error: blockingOverlayError,
      start: () => setBlockingOverlayActive(true),
      stop: () => setBlockingOverlayActive(false),
      fail: setBlockingOverlayError,
    }),
    [blockingOverlayActive, blockingOverlayError],
  );

  return (
    <>
      <GlobalStyle />
      <SessionContext.Provider value={sessionOptions}>
        <BlockingOverlayContext.Provider value={blockingOverlayOptions}>
          <ContextMenuContext.Provider value={contextMenuOptions}>
            <Router
              routes={[
                [/^\/$/, 'sinesaw - home', Home],
                [/^\/about$/, 'sinsaw - about', About],
                [/^\/contact$/, 'sinsaw - contact', Contact],
                [/^\/developers$/, 'sinsaw - developers', Developers],
                [/^\/open-source$/, 'sinsaw - open source', OpenSource],
                [/^\/paid-storage$/, 'sinsaw - paid storage', PaidStorage],
                [/^\/tutorials$/, 'sinsaw - tutorials', Tutorials],
                [
                  /^\/account-settings$/,
                  'sinesaw - account settings',
                  AccountSettings,
                ],
                [
                  /^\/email-confirmation\/[a-zA-Z0-9-\+\.]+$/,
                  'sinesaw - confirm email',
                  ConfirmEmail,
                ],
                [
                  /^\/reset-password\/[a-zA-Z0-9-\+\.]+$/,
                  'sinesaw - reset password',
                  ResetPassword,
                ],
                [
                  /^\/[\w]{3,64}$/,
                  ([username]) => `sinesaw - ${username}`,
                  Profile,
                ],
                [
                  /^\/[\w]{3,64}\/[a-zA-Z0-9]{12}$/,
                  ([username, id]) => `sinesaw - ${username} - ${id}`,
                  Editor,
                ],
              ]}
              NotFound={() => <NotFoundPage />}
            >
              {menuOptions && (
                <ContextMenu
                  key={menuOptions.key}
                  position={menuOptions.position}
                  items={menuOptions.items}
                  spliceElement={menuOptions.spliceElement}
                  close={() => setMenuOptions(undefined)}
                />
              )}
              {(blockingOverlayActive || blockingOverlayError) && (
                <>
                  <Shade />
                  <BlockingOverlay error={blockingOverlayError} />
                </>
              )}
            </Router>
          </ContextMenuContext.Provider>
        </BlockingOverlayContext.Provider>
      </SessionContext.Provider>
    </>
  );
};
