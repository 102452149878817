import React, { FC, useState, useRef, memo } from 'react';
import styled from 'styled-components';
import { Vector } from '../../../types';
import { strokeWidth, halfStrokeWidth } from '../../../style';
import { range } from '../../../utils';
import { TAB_ORDER } from '../../../data';
import { useAnimationFrameEffect } from '../../../hooks';

type PathProps = { frozen: boolean };
const Path = styled.path`
  stroke-width: ${strokeWidth};
  stroke: var(
    ${({ frozen }: PathProps) =>
      frozen
        ? '--visualization-secondary-stroke-color'
        : '--visualization-stroke-color'}
  );
  fill: none;
`;
const Line = styled.line`
  stroke-width: ${strokeWidth};
  stroke: var(--visualization-secondary-stroke-color);
`;
const SVG = styled.svg`
  flex-shrink: 0;
  background-color: var(--visualization-color);
  &:focus {
    outline-color: var(--outline-secondary-color);
  }
`;

export type FFTProps = {
  size: Vector;
  truncate?: number;
  stepDivisor?: number;
  frameDivisor?: number;
  analyser: AnalyserNode;
  context: BaseAudioContext;
};

const calculatePath = (
  data: Uint8Array,
  frequencyBinCount: number,
  truncate: number | undefined,
  [width, height]: Vector,
  stepDivisor: number,
) => {
  const len = Math.min(truncate ?? frequencyBinCount, frequencyBinCount);
  const xScale = width / Math.log2(len);
  const yScale = (height - strokeWidth) / 255;
  const yOffset = height - halfStrokeWidth;

  let d = `M 0 ${yOffset - data[0] * yScale}`;
  for (let i = 1, s = 1, last = null; i < len - 1; i += s) {
    const x = Math.log2(i) * xScale;
    if (last && x - last < stepDivisor / 2) s *= 2;
    last = x;
    d += ` L ${x} ${yOffset - data[i] * yScale}`;
  }
  d += `L ${Math.log2(len - 1) * xScale} ${yOffset - data[len - 1] * yScale}`;

  return d;
};

export const FFT: FC<FFTProps> = memo(
  ({
    size,
    size: [width, height],
    truncate,
    stepDivisor = 1,
    frameDivisor = 1,
    analyser,
    context,
  }) => {
    const [frozen, setFrozen] = useState<boolean>(false);
    const dataRef = useRef<Uint8Array>(
      new Uint8Array(analyser.frequencyBinCount),
    );
    const pathRef = useRef<SVGPathElement>(null);
    useAnimationFrameEffect(
      () => {
        if (frozen || context.state !== 'running' || pathRef.current === null) {
          return;
        }
        if (
          dataRef.current === null ||
          dataRef.current.length !== analyser.frequencyBinCount
        ) {
          dataRef.current = new Uint8Array(analyser.frequencyBinCount);
        }
        analyser.getByteFrequencyData(dataRef.current);
        pathRef.current.setAttribute(
          'd',
          calculatePath(
            dataRef.current,
            analyser.fftSize,
            truncate,
            size,
            stepDivisor,
          ),
        );
      },
      [context, analyser, frozen, truncate, size, stepDivisor],
      frameDivisor,
    );

    const { frequencyBinCount } = analyser;
    const len = Math.min(truncate ?? frequencyBinCount, frequencyBinCount);

    const numLines = Math.floor(
      Math.log2(((len / frequencyBinCount) * context.sampleRate) / 2),
    );

    return (
      <SVG
        width={width}
        height={height}
        tabIndex={TAB_ORDER.VISUALIZATION}
        onClick={() => setFrozen(!frozen)}
        onKeyDown={e => {
          if (e.key === 'Enter') setFrozen(!frozen);
        }}
      >
        {range(0, numLines).map(i => {
          // this draws vertical lines at a regular interval on the log scale we
          // are using for the x-axis.  TODO: make them spaced one octave apart
          const x = Math.min(
            Math.max(
              halfStrokeWidth,
              (Math.log2(i) / Math.log2(numLines)) * width,
            ),
            width - halfStrokeWidth,
          );
          return <Line key={i} x1={x} y1={0} x2={x} y2={height} />;
        })}
        <Line
          x1={0}
          y1={height - halfStrokeWidth}
          x2={width}
          y2={height - halfStrokeWidth}
        />
        <Path
          ref={pathRef}
          d={calculatePath(
            dataRef.current,
            analyser.fftSize,
            truncate,
            size,
            stepDivisor,
          )}
          frozen={frozen}
        />
      </SVG>
    );
  },
);
