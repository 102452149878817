import React, { FC, MouseEvent, KeyboardEvent, useContext, memo } from 'react';
import styled from 'styled-components';
import {
  SSIO,
  SSNode,
  Target,
  ActivityType,
  TargetType,
  NodeConnectionActivity,
  Vector,
} from '../../../types';
import { useGridDrag } from '../hooks';
import {
  ActionContext,
  SelectionContext,
  ActivityContext,
  HoverTargetContext,
} from '../contexts';
import { strokeWidth, ioRadius } from '../../../style';
import { getIOCenterPosition, add } from '../../../utils';
import { TAB_ORDER } from '../../../data';
import { IODescription } from './IODescription';

type EllipseProps = {
  selected?: boolean;
  active?: boolean;
  targeted?: boolean;
};
const Ellipse = styled.ellipse`
  fill: var(
    ${({ active, targeted, selected }: EllipseProps) =>
      active || targeted
        ? '--io-targeted-color'
        : selected
        ? '--io-selected-color'
        : '--io-color'}
  );
  stroke-width: ${strokeWidth};
  stroke: var(--io-stroke-color);
  vector-effect: non-scaling-stroke;
  cursor: default;
`;

export type IOUIProps = {
  node: SSNode;
  io: SSIO;
};

export const IOUI: FC<IOUIProps> = memo(({ node, io }) => {
  const {
    setSelection,
    setActivity,
    performActivity,
    setFocusTarget,
    setHoverTarget,
    disconnectIO,
  } = useContext(ActionContext);

  const { selections, select } = useContext(SelectionContext);
  const activities = useContext(ActivityContext);
  const hoverTargets = useContext(HoverTargetContext);

  const getNodeConnectionActivity = (
    position: Vector,
  ): NodeConnectionActivity => ({
    type: ActivityType.NodeConnection,
    origin: io,
    position,
  });
  const beginDrag = useGridDrag(
    position => {
      setActivity(getNodeConnectionActivity(position));
    },
    position => {
      performActivity(getNodeConnectionActivity(position));
      setActivity(undefined);
    },
  );

  const [cx, cy] = add(getIOCenterPosition(node, io), node.position);

  const target: Target = { type: TargetType.IO, io };

  return (
    <Ellipse
      tabIndex={TAB_ORDER.IO}
      onFocus={e => {
        e.stopPropagation();
        setFocusTarget(target);
      }}
      onBlur={e => {
        e.stopPropagation();
        setFocusTarget(undefined);
      }}
      onKeyDown={(e: KeyboardEvent<SVGEllipseElement>) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
          setSelection([target]);
        } else if (e.key === 'Backspace') {
          e.stopPropagation();
          disconnectIO(io);
        }
      }}
      cx={cx}
      cy={cy}
      rx={ioRadius}
      ry={ioRadius}
      onMouseOver={() => setHoverTarget(target)}
      onMouseOut={() => setHoverTarget(undefined)}
      onMouseDown={(e: MouseEvent<SVGEllipseElement>) => {
        e.stopPropagation();
        beginDrag(e, undefined);
      }}
      onClick={select(target)}
      selected={Object.values(selections).some(selection =>
        selection?.some(
          t =>
            t.type === TargetType.IO &&
            t.io.node === io.node &&
            t.io.type === io.type &&
            t.io.index === io.index,
        ),
      )}
      active={Object.values(activities).some(
        activity =>
          activity?.type == ActivityType.NodeConnection &&
          activity.origin.node === io.node &&
          activity.origin.type === io.type &&
          activity.origin.index === io.index,
      )}
      targeted={Object.entries(activities).some(([username, activity]) => {
        const hoverTarget = hoverTargets[username];
        return (
          activity?.type === ActivityType.NodeConnection &&
          hoverTarget &&
          hoverTarget.type === TargetType.IO &&
          hoverTarget.io.node === io.node &&
          hoverTarget.io.type === io.type &&
          hoverTarget.io.index === io.index &&
          activity.origin.type !== io.type
        );
      })}
    >
      <title>
        <IODescription node={node} io={io} />
      </title>
    </Ellipse>
  );
});
