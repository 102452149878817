import React, { FC } from 'react';
import { SSGraph } from '../../../types';
import { IODescription } from './IODescription';

export type EdgeDescriptionProps = {
  graph: SSGraph;
  id: string;
};

export const EdgeDescription: FC<EdgeDescriptionProps> = ({
  graph,
  id,
}: EdgeDescriptionProps) => {
  const edge = graph.edges[id];
  return (
    <>
      Connection from{' '}
      <IODescription node={graph.nodes[edge.from.node]} io={edge.from} /> to{' '}
      <IODescription node={graph.nodes[edge.to.node]} io={edge.to} />
    </>
  );
};
