import React, { FC } from 'react';
import { SectionsContentLayout, P, A } from '../../components';

export const Developers: FC = () => {
  return (
    <SectionsContentLayout
      title="developers"
      path="/developers"
      sections={[
        {
          id: 'developers',
          title: 'Developers',
          content: <></>,
        },
        {
          id: 'web audio',
          title: 'Web audio',
          content: (
            <>
              <P>Lorem ipsum dolor sit amet.</P>
            </>
          ),
        },
        {
          id: 'custom-audio-nodes',
          title: 'Custom audio nodes',
          content: (
            <>
              <P>Lorem ipsum dolor sit amet.</P>
            </>
          ),
        },
        {
          id: 'native-code',
          title: 'Native audio processing with WebAssembly',
          content: (
            <>
              <P>
                Many of the audio nodes provided by sinesaw are implemented in
                C++, and compiled to run in the browser using{' '}
                <A href="https://emscripten.org/">emscripten</A>.
              </P>
              <P>
                This <A href="">example</A> is helpful
              </P>
            </>
          ),
        },
        {
          id: 'specification',
          title: 'A specification for pluggable audio nodes',
          content: (
            <>
              <P>
                A goal of Sinesaw is to develop an open web audio plugin format,
                allowing Audio Nodes to be developed in a platform independent
                way, and used in sinesaw and along with other web audio DAWs.
              </P>
            </>
          ),
        },
      ]}
    />
  );
};
