import React, { FC, useState, useRef, useLayoutEffect } from 'react';
import { Vector, MenuItems } from '../types';
import { Menu } from './Menu';

export type ContextMenuProps = {
  position: Vector;
  items: MenuItems;
  close: () => void;
  spliceElement?: HTMLElement | SVGElement | null;
};

export const ContextMenu: FC<ContextMenuProps> = ({
  position: [x, y],
  items,
  close,
  spliceElement,
}) => {
  const [[w, h], setSize] = useState<Vector>([0, 0]);

  // measure menu to position appropriately
  const menuRef = useRef<HTMLElement>(null);
  useLayoutEffect(() => {
    const { current } = menuRef;
    if (current === null) throw new Error();
    const { width, height } = current.getBoundingClientRect();
    setSize([width, height]);
  }, [menuRef]);

  const left = x + w > window.innerWidth ? x - w : x;
  const top = Math.min(y, window.innerHeight - h);

  return (
    <Menu
      spliceElement={spliceElement}
      close={close}
      ref={menuRef}
      position={[left, top]}
      items={items}
    />
  );
};
