import React, { ChangeEvent, FC } from 'react';
import { Input } from '../../../../components';
import { TAB_ORDER } from '../../../../data';

export type EnumInputProps<T = any> = {
  id?: string;
  tabIndex?: number;
  disabled?: boolean;
  value: T;
  options: T[];
  onChange: (value: T) => void;
};

export const EnumInput: FC<EnumInputProps> = ({
  id,
  tabIndex = TAB_ORDER.INPUT,
  disabled,
  value,
  options,
  onChange,
}) => {
  return (
    <Input
      fullWidth
      id={id}
      as="select"
      tabIndex={tabIndex}
      disabled={disabled}
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        onChange(options[parseInt(e.currentTarget.value)])
      }
    >
      {options.map((v, i) => (
        <option key={v} value={i} selected={value === v}>
          {v}
        </option>
      ))}
    </Input>
  );
};
