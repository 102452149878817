import styled from 'styled-components';
import { lineHeight } from '../style';

export const Row = styled.div<{
  align?: string;
  flex?: string;
  justify?: string;
  gap?: number;
}>`
  display: flex;
  flex: ${({ flex }) => (flex !== undefined ? flex : '0 1 auto')};
  align-items: ${({ align }) => align ?? 'flex-start'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  gap: ${({ gap }) => (gap ?? 0) * lineHeight}px;
`;
