import React, { FC } from 'react';
import { fixed } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { FloatArrayInput } from './FloatArrayInput';
import { usePosition } from '../../hooks';

export const FloatArrayFixedValueInput: FC<ValueInputProps<number[]>> = ({
  id,
  disabled,
  seededValue,
  onChange,
  node,
  paramDescription,
}) => {
  const position = usePosition(node.id);
  return (
    <FloatArrayInput
      id={id}
      disabled={disabled}
      value={seededValue}
      onChange={value => onChange(fixed(value))}
      highlightedIndex={position}
      min={paramDescription.min}
      max={paramDescription.max}
    />
  );
};
