import { createContext } from 'react';
import { MenuItems, Vector } from '../types';

export type ContextMenuOptions = {
  open: (
    position: Vector,
    items: MenuItems,
    spliceElement?: HTMLElement | SVGElement | null,
  ) => void;
  close: () => void;
  isOpen: boolean;
};

export const ContextMenuContext = createContext<ContextMenuOptions>({
  open: () => {},
  close: () => {},
  isOpen: false,
});
