import React, { FC, useState, useMemo, useEffect, ReactNode } from 'react';
import { RoutingContext, RoutingOptions } from '../contexts';

export type RouteHandler = FC<{ path: string[] }>;
export type Route = [
  RegExp,
  string | ((path: string[]) => string),
  RouteHandler,
];

export type RouterProps = {
  routes: Route[];
  NotFound: RouteHandler;
  children?: ReactNode;
};

const getRoute = (routes: Route[], path: string) =>
  routes.find(([pattern]) => path.match(pattern));

const getTitle = (route: Route | undefined, path: string) =>
  route === undefined
    ? ''
    : typeof route[1] === 'string'
    ? route[1]
    : route[1](path.slice(1).split('/'));

export const Router: FC<RouterProps> = ({ routes, NotFound, children }) => {
  const [path, setPath] = useState<string>(window.location.pathname);
  const [route, setRoute] = useState<Route | undefined>(() =>
    getRoute(routes, window.location.pathname),
  );

  useEffect(() => {
    const { pathname, search, hash } = window.location;

    // update title on initial load
    const title = getTitle(route, pathname);
    window.history.replaceState(null, title, `${pathname}${search}${hash}`);
    document.title = title;

    // list to pop state events and update
    const update = () => {
      setRoute(getRoute(routes, window.location.pathname));
      setPath(window.location.pathname);
    };
    window.addEventListener('popstate', update);
    return () => window.removeEventListener('popstate', update);
  }, [routes]);

  const routingOptions = useMemo<RoutingOptions>(
    () => ({
      route(path: string): void {
        const route = getRoute(routes, path);
        const title = getTitle(route, path);
        window.history.pushState(null, title, path);
        document.title = title;
        setRoute(route);
        setPath(path);
      },
    }),
    [routes],
  );

  const Component = route ? route[2] : NotFound;

  return (
    <RoutingContext.Provider value={routingOptions}>
      <Component key={path} path={path.slice(1).split('/')} />
      {children}
    </RoutingContext.Provider>
  );
};
