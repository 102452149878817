import React, { FC } from 'react';
import { ValueInputProps } from './ValueInput';
import { IntegerInput } from './IntegerInput';
import { ArrayWeightedRandomSequenceValueInput } from './ArrayWeightedRandomSequenceValueInput';

export const IntegerArrayWeightedRandomSequenceValueInput: FC<
  ValueInputProps<number[]>
> = props => {
  return (
    <ArrayWeightedRandomSequenceValueInput
      {...props}
      Component={IntegerInput}
    />
  );
};
