import React, { Fragment, FC, useContext } from 'react';
import styled from 'styled-components';
import { ActivityContext, GraphContext } from '../contexts';
import { SessionContext } from '../../../contexts';
import { ActivityType } from '../../../types';
import { getIOCenterPosition, getUserColor, add } from '../../../utils';
import { strokeWidth } from '../../../style';

const Line = styled.line`
  stroke-width: 0.2;
  stroke-linecap: round;
  pointer-events: none;
`;
const NonScalingLine = styled(Line)`
  stroke-width: ${strokeWidth};
  vector-effect: non-scaling-stroke;
`;

export type NodeConnectionActivityUIProps = {};

export const NodeConnectionActivityUI: FC<
  NodeConnectionActivityUIProps
> = () => {
  const { session } = useContext(SessionContext);
  const activities = useContext(ActivityContext);
  const { graph } = useContext(GraphContext);

  return (
    <>
      {Object.entries(activities).map(([username, activity]) => {
        if (
          activity === undefined ||
          activity.type !== ActivityType.NodeConnection
        ) {
          return null;
        }

        const color =
          username === session?.username
            ? 'var(--outline-color)'
            : getUserColor(username);

        const { origin, position } = activity;
        const originNode = graph.nodes[origin.node];
        const ioOffset = getIOCenterPosition(originNode, origin);
        const [x1, y1] = add(originNode.position, ioOffset);
        const [x2, y2] = position;
        return (
          <Fragment key={username}>
            <NonScalingLine x1={x1} y1={y1} x2={x2} y2={y2} stroke={color} />
            <Line x1={x1} y1={y1} x2={x2} y2={y2} stroke={color} />
          </Fragment>
        );
      })}
    </>
  );
};
