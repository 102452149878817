import { NodeExtension, ParamType } from 'helicon';

export const SlewLimiterExtension: NodeExtension = {
  type: 'SlewLimiter',
  processor: '/SlewLimiterProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      time: {
        type: ParamType.AudioParam,
        min: 0,
        default: 0,
      },
      signal: {
        type: ParamType.AudioParam,
        default: 0,
      },
    },
    signals: {},
  },
};
