import React, { FC, useContext } from 'react';
import { Activity, ActivityType } from '../../../types';
import { GraphContext } from '../contexts';
import { IODescription } from './IODescription';

export type ActivityDescriptionProps = {
  activity: Activity;
};

export const ActivityDescription: FC<ActivityDescriptionProps> = ({
  activity,
}) => {
  const { graph } = useContext(GraphContext);

  if (activity.type === ActivityType.DragSelection) {
    const {
      origin: [originX, originY],
      position: [positionX, positionY],
    } = activity;
    return (
      <>
        Selecting from ({originX},{originY}) to ({positionX},{positionY})
      </>
    );
  }

  if (activity.type === ActivityType.NodeConnection) {
    const { origin } = activity;
    return (
      <>
        Connecting <IODescription node={graph.nodes[origin.node]} io={origin} />
      </>
    );
  }

  if (activity.type === ActivityType.NodeTranslation) {
    const { nodes } = activity;
    return <>Translating {nodes.length} nodes</>;
  }

  return null;
};
