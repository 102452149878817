import { NodeExtension, ParamType } from 'helicon';

export const RandomGateExtension: NodeExtension = {
  type: 'RandomGate',
  processor: '/RandomGateProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      clock: {
        type: ParamType.AudioParam,
        default: 0,
      },
      probability: {
        type: ParamType.AudioParam,
        default: 0.5,
      },
    },
    signals: {
      trigger: {
        trigger: true,
      },
    },
  },
};
