import styled from 'styled-components';
import { lineHeight } from '../style';

export const Toolbar = styled.div`
  height: ${2.5 * lineHeight}px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
`;
