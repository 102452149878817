import React, { FC } from 'react';
import { strokeWidth } from '../../../../style';
import { range } from '../../../../utils';

export type GaussianIconProps = {
  width?: number;
  height?: number;
};

const mean = 0.5;
const variance = 0.025;
const stdDev = Math.sqrt(variance);
const sqrtTau = Math.sqrt(2 * Math.PI);

const NUM_POINTS = 16;
const f = (x: number): number =>
  1 - Math.pow(1 / stdDev / sqrtTau, -Math.pow(x - mean, 2) / 2 / variance);

export const GaussianIcon: FC<GaussianIconProps> = ({
  width = 1,
  height = 1,
}) => {
  const w = width - strokeWidth;
  const h = height - strokeWidth;
  const s = strokeWidth / 2;
  return (
    <path
      d={`M 0 ${h + s} ${range(0, 1, 1 / NUM_POINTS)
        .map(x => `L ${x * w + s} ${f(x) * h + s}`)
        .join(' ')}`}
    />
  );
};
