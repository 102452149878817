import React, { FC, useContext, memo, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { ActionContext, GridContext, GraphContext } from '../contexts';
import { lineHeight } from '../../../style';
import {
  graphBounds,
  gridBounds,
  centerViewport,
  multiplyScalar,
  subtract,
} from '../../../utils';
import { TAB_ORDER } from '../../../data';

const SVG = styled.svg`
  background-color: var(--visualization-color);
  &:focus {
    outline-color: var(--outline-secondary-color);
  }
`;

const Viewport = styled.rect`
  fill: var(--visualization-stroke-color);
`;

const GraphBounds = styled.rect`
  fill: var(--visualization-secondary-stroke-color);
`;

export type MiniMapProps = {};

export const MiniMap: FC<MiniMapProps> = memo(() => {
  const { zoomViewport, panViewport, setViewport } = useContext(ActionContext);
  const { graph } = useContext(GraphContext);
  const {
    viewport,
    viewport: [viewportPosition, viewportSize],
    domRect: [, domSize],
    domRect: [, [domSizeX, domSizeY]],
  } = useContext(GridContext);

  const setCenteredViewport = () => setViewport(centerViewport(domSize, graph));

  const onKeyDown = (e: KeyboardEvent) => {
    // up and down arrows zoom viewport in and out when meta key is held
    if (e.metaKey) {
      switch (e.key) {
        case 'ArrowUp':
          zoomViewport([0.5, 0.5], -50);
          break;
        case 'ArrowDown':
          zoomViewport([0.5, 0.5], 50);
          break;
        default:
          return;
      }
      e.stopPropagation();
      return;
    }

    // arrows pan viewport, stepping farther when shift key is held
    switch (e.key) {
      case 'Enter':
        setCenteredViewport;
        break;
      case 'ArrowUp':
        panViewport([0, e.shiftKey ? -5 : -1]);
        break;
      case 'ArrowDown':
        panViewport([0, e.shiftKey ? 5 : 1]);
        break;
      case 'ArrowLeft':
        panViewport([e.shiftKey ? -5 : -1, 0]);
        break;
      case 'ArrowRight':
        panViewport([e.shiftKey ? 5 : 1, 0]);
        break;
      default:
        return;
    }
    e.stopPropagation();
  };

  const height = 1.5 * lineHeight;
  const width = (height * domSizeX) / domSizeY;

  if (domSizeX === 0 || domSizeY === 0) {
    return <SVG width={0} height={height} />;
  }

  if (Object.keys(graph.nodes).length === 0) {
    return (
      <SVG
        height={height}
        width={width}
        tabIndex={TAB_ORDER.MINIMAP}
        onClick={setCenteredViewport}
        onKeyDown={onKeyDown}
      />
    );
  }

  const [areaPosition, [, sizeY]] = gridBounds(graph, viewport, domSize);
  const [graphPosition, graphSize] = graphBounds(graph);
  const scale = height / sizeY;

  const [x, y] = multiplyScalar(
    subtract(viewportPosition, areaPosition),
    scale,
  );
  const [w, h] = multiplyScalar(viewportSize, scale);

  const [gx, gy] = multiplyScalar(subtract(graphPosition, areaPosition), scale);
  const [gw, gh] = multiplyScalar(graphSize, scale);

  return (
    <SVG
      height={height}
      width={width}
      tabIndex={TAB_ORDER.MINIMAP}
      onClick={setCenteredViewport}
      onKeyDown={onKeyDown}
    >
      <GraphBounds x={gx} y={gy} width={gw} height={gh} />
      <Viewport x={x} y={y} width={w} height={h} />
    </SVG>
  );
});
