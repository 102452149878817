import React, { FC, useState } from 'react';
import {
  Button,
  SectionsLayout,
  TextArea,
  Link,
  Stack,
  UserTag,
  FormLinks,
  NetworkActivityIndicator,
  BasicHeader,
} from '../../../components';
import { Account, File, Session } from '../../../types';
import { FilesTable } from './FilesTable';
import { TAB_ORDER } from '../../../data';
import { useAPIAction } from '../../../hooks';
import { updateAccount } from '../../../api';

export type AccountSettingsLayoutProps = {
  account: Account;
  files: File[];
  setFiles: (files: File[]) => void;
  session: Session;
};

export const AccountSettingsLayout: FC<AccountSettingsLayoutProps> = ({
  account,
  files,
  setFiles,
  session,
}) => {
  const [bio, setBio] = useState(account.bio ?? '');
  const [callUpdateAccount, updateAccountLoading, updateAccountError] =
    useAPIAction(updateAccount);

  return (
    <SectionsLayout
      breadcrumbs={
        <>
          <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            sinesaw
          </Link>
          {' / '}
          <Link
            plain
            href="/account-settings"
            tabIndex={TAB_ORDER.HEADER_BUTTON}
          >
            account settings
          </Link>
        </>
      }
      sections={[
        {
          id: 'profile',
          title: 'Profile',
          content: (
            <>
              <BasicHeader>Profile</BasicHeader>
              <form
                onSubmit={async e => {
                  e.preventDefault();
                  if (!updateAccountLoading) {
                    callUpdateAccount({ session, bio });
                  }
                }}
              >
                <Stack>
                  <UserTag username={account.username} />
                  <TextArea
                    value={bio}
                    onChange={e => setBio(e.target.value)}
                  />
                  <Button type="submit" disabled={updateAccountLoading}>
                    {updateAccountLoading || updateAccountError ? (
                      <NetworkActivityIndicator
                        pending={false}
                        active={updateAccountLoading}
                        error={!!updateAccountError}
                      />
                    ) : (
                      'Update'
                    )}
                  </Button>
                </Stack>
              </form>
              <FormLinks>
                <Link href={`/${account.username}`}>view profile</Link>
              </FormLinks>
            </>
          ),
        },
        {
          id: 'files',
          title: 'Files',
          content: (
            <>
              <BasicHeader>Files</BasicHeader>
              <FilesTable files={files} setFiles={setFiles} />
            </>
          ),
        },
      ]}
    />
  );
};
