import React, { FC, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { TAB_ORDER } from '../../../data';
import { AudioGraphContext } from '../contexts';
import { PauseIcon, PlayIcon } from './icons';

const SVG = styled.svg`
  display: block;
  cursor: pointer;
  rect,
  path {
    fill: var(--play-button-color);
  }
  &:hover rect,
  &:hover path {
    fill: var(--play-button-hover-color);
  }
`;

export type PlayButtonProps = {
  size: number;
};

export const PlayButton: FC<PlayButtonProps> = ({ size }) => {
  const { audioGraph } = useContext(AudioGraphContext);
  const context = audioGraph.context as AudioContext;
  const [playing, setPlaying] = useState<boolean>(context.state === 'running');
  useEffect(() => {
    const onStateChange = () => setPlaying(context.state === 'running');
    context.addEventListener('statechange', onStateChange);
    return () => {
      context.removeEventListener('playing', onStateChange);
    };
  }, [context]);

  const toggle = () => {
    if (audioGraph.playing) {
      audioGraph.pause();
    } else {
      audioGraph.play();
    }
  };

  return (
    <SVG
      tabIndex={TAB_ORDER.HEADER_BUTTON}
      onClick={toggle}
      onKeyDown={e => {
        if (e.key === 'Enter') toggle();
      }}
      width={size}
      height={size}
      viewBox="0 0 1 1"
    >
      {playing ? <PauseIcon /> : <PlayIcon />}
    </SVG>
  );
};
