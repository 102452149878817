import { useState, useMemo, useEffect } from 'react';
import { darkTheme, lightTheme } from '../style';

export const useTheme = (): { [name: string]: string } => {
  const match = useMemo(
    () => window.matchMedia('(prefers-color-scheme: dark)'),
    [],
  );
  const [theme, setTheme] = useState(match.matches ? darkTheme : lightTheme);
  useEffect(() => {
    const handler = (e: MediaQueryListEvent) =>
      setTheme(e.matches ? darkTheme : lightTheme);
    match.addEventListener('change', handler);
    return () => match.removeEventListener('change', handler);
  }, []);
  return theme;
};
