import { request, Response } from '../utils';
import { Session, File as LibraryFile } from '../types';
import md5 from 'crypto-js/md5';
import Base64 from 'crypto-js/enc-base64';
import Latin1 from 'crypto-js/enc-latin1';

const getFileMD5 = (file: File): Promise<string> =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve(md5(Latin1.parse(reader.result as string)).toString(Base64));
    reader.readAsBinaryString(file);
  });

export const createFile = async ({
  session,
  file,
}: {
  session: Session;
  file: File;
}): Response<LibraryFile | null> => {
  const response: {
    status: number;
    error: string | null;
    body:
      | {
          exists: false;
          post: { url: string; fields: { [key: string]: string } };
          file: LibraryFile;
        }
      | {
          exists: true;
          file: LibraryFile;
        };
  } = await request('create-file', {
    session,
    contentHash: await getFileMD5(file),
    fileName: file.name,
    fileSize: file.size,
    extension: file.name.split('.').slice(-1)[0],
  });

  if (response.status !== 200) {
    return {
      status: response.status,
      error: response.error,
      body: null,
    };
  }

  if (response.body.exists === false) {
    const formData = new FormData();
    Object.entries(response.body.post.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('Content-Type', file.type);
    formData.append('file', file);
    const s3Response = await fetch(response.body.post.url, {
      method: 'POST',
      body: formData,
    });
    if (s3Response.status !== 204) {
      return {
        status: s3Response.status,
        error: 'file upload failure',
        body: null,
      };
    }
  }

  return {
    ...response,
    body: response.body.file,
  };
};
