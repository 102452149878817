import styled from 'styled-components';
import { lineHeight } from '../style';

export type StackProps = {
  space?: number;
  leading?: boolean;
  trailing?: boolean;
};

export const Stack = styled.div`
  margin: ${({ leading, space = lineHeight }: StackProps) =>
      leading ? space : 0}px
    0
    ${({ trailing, space = lineHeight }: StackProps) =>
      trailing ? space : 0}px;

  & > * {
    margin: ${({ space = lineHeight }: StackProps) => space}px 0;
    // display: block;
  }
  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;
