import { useReducer, useMemo } from 'react';
import { Session } from '../../types';
// import { ConnectionState } from '../useP2P/connectionReducer';
import {
  ExternalState,
  InternalState,
  // PeerMessage,
  InternalActionType,
  InternalAction,
  // PeerMessageType,
  Typed,
  VectorClock,
} from './types';
import { internalReducer } from './internalReducer';
// import { useP2P } from '../useP2P';

export const useP2PReducer = <Action extends Typed, DocumentState, UserState>(
  documentReducer: (
    state: DocumentState,
    action: Action,
    userState: UserState,
  ) => DocumentState,
  userReducer: (
    state: UserState,
    action: Action,
    document: DocumentState,
    nextDocument: DocumentState,
  ) => UserState,
  initDocument: () => DocumentState,
  initUser: (username: string) => UserState,
  canCollapse: (action: Action) => boolean,
  canUndo: (action: Action) => boolean,
  session: Session | undefined,
  // graphID: string,
): {
  state: ExternalState<DocumentState, UserState>;
  // connectionState: ConnectionState;
  dispatch: (action: Action) => void;
  undo?: () => void;
  redo?: () => void;
} => {
  const username = session?.username ?? '!';

  const reducer = useMemo(
    () =>
      internalReducer(
        documentReducer,
        userReducer,
        initUser,
        canCollapse,
        canUndo,
        username,
      ),
    [documentReducer, userReducer, initUser, canCollapse, canUndo, username],
  );

  // create reducer managing document and user state
  const [{ state, /* initialState, */ clock, history, redoStack }, dispatch] =
    useReducer<
      (
        state: InternalState<Action, DocumentState, UserState>,
        action: InternalAction<Action, DocumentState, UserState>,
      ) => InternalState<Action, DocumentState, UserState>,
      null
    >(reducer, null, () => {
      const state = {
        document: initDocument(),
        users: { [username]: initUser(username) },
      };
      return {
        state,
        initialState: state,
        history: [],
        redoStack: [],
        clock: { [username]: 0 },
      };
    });

  // // create p2p connections
  // const [connectionState, send] = useP2P<
  //   PeerMessage<Action, DocumentState, UserState>
  // >(session, graphID, (message, sender) => {
  //   switch (message.type) {
  //     case PeerMessageType.REQUEST_SYNC:
  //       send(
  //         {
  //           type: PeerMessageType.SYNC,
  //           clock,
  //           initialState,
  //           history: history.map(({ clock, username, action, tombstone }) => ({
  //             clock,
  //             username,
  //             action,
  //             tombstone,
  //           })),
  //         },
  //         sender,
  //       );
  //       break;
  //     case PeerMessageType.SYNC:
  //       dispatch({
  //         type: InternalActionType.SYNC,
  //         username: sender,
  //         clock: message.clock,
  //         initialState: message.initialState,
  //         history: message.history,
  //       });
  //       break;
  //     case PeerMessageType.ACTION:
  //       dispatch({
  //         type: InternalActionType.ACTION,
  //         username: sender,
  //         clock: message.clock,
  //         action: message.action,
  //       });
  //       break;
  //     case PeerMessageType.UNDO:
  //       dispatch({
  //         type: InternalActionType.UNDO,
  //         username: sender,
  //         clock: message.clock,
  //       });
  //       break;
  //     default:
  //       console.log('unrecognized peer message', message);
  //       throw new Error('unrecognized peer message');
  //   }
  // });

  // keep clock in ref so that it is up to date when incremented in wrapped
  // dispatch
  const clockRef = useMemo<{ clock: VectorClock }>(
    () => ({
      clock,
    }),
    [],
  );
  clockRef.clock = clock;

  // replace dispatch with a wrapped version that sends events to peers
  const wrappedDispatch = (action: Action) => {
    clockRef.clock[username] += 1;
    dispatch({
      type: InternalActionType.ACTION,
      username,
      clock: { ...clockRef.clock },
      action,
    });
    // send({ type: PeerMessageType.ACTION, clock: clockRef.clock, action });
  };

  const { undo, redo } = useMemo<{
    undo?: () => void;
    redo?: () => void;
  }>(
    () => ({
      undo: history.some(
        entry => entry.username === username && canUndo(entry.action),
      )
        ? () => {
            clockRef.clock[username] += 1;
            dispatch({
              type: InternalActionType.UNDO,
              clock: { ...clockRef.clock },
              username,
            });
            // send({
            //   type: PeerMessageType.UNDO,
            //   clock: clockRef.clock,
            // });
          }
        : undefined,
      redo:
        redoStack.length > 0
          ? () => {
              clockRef.clock[username] += 1;
              dispatch({
                type: InternalActionType.REDO,
                clock: { ...clockRef.clock },
                username,
              });
              // send({
              //   type: PeerMessageType.ACTION,
              //   clock: clockRef.clock,
              //   action: redoStack[0],
              // });
            }
          : undefined,
    }),
    [history, redoStack],
  );

  return {
    state,
    // connectionState,
    dispatch: wrappedDispatch,
    undo,
    redo,
  };
};
