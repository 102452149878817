import React, { FC, useState, KeyboardEvent, useContext, useRef } from 'react';
import { Input } from '../../../../components';
import { Waveform } from '../Waveform';
import { SelectAudioFileDialog } from '../SelectAudioFileDialog';
import { lineHeight } from '../../../../style';
import { useAudioGraphEffect } from '../../hooks';
import { SessionContext } from '../../../../contexts';

export type AudioBufferInputProps = {
  id?: string;
  tabIndex?: number;
  value: string | undefined;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export const AudioBufferInput: FC<AudioBufferInputProps> = ({
  id,
  tabIndex,
  value,
  onChange,
  disabled,
}) => {
  const ref = useRef<HTMLLabelElement | null>(null);
  const { session } = useContext(SessionContext);
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | undefined>();
  const [selectAudioFileDialogActive, setSelectAudioFileDialogActive] =
    useState<boolean>(false);

  useAudioGraphEffect(
    audioGraph => {
      let cancel = false;
      if (value && audioGraph.files[value]) {
        audioGraph.files[value].audioBuffer.then(buffer => {
          if (!cancel && buffer !== audioBuffer) setAudioBuffer(buffer);
        });
      } else if (value) {
        audioGraph.fetchAudioBuffer(value).then(buffer => {
          if (!cancel && buffer !== audioBuffer) setAudioBuffer(buffer);
        });
      }
      return () => {
        cancel = true;
      };
    },
    audioGraph => [value, audioGraph],
  );

  const isDisabled = disabled || session === undefined;

  return (
    <>
      <Input
        id={id}
        as="label"
        fullWidth
        tabIndex={disabled ? -1 : tabIndex}
        style={{ position: 'relative' }}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setSelectAudioFileDialogActive(true);
          }
        }}
        onClick={() => {
          setSelectAudioFileDialogActive(true);
        }}
        disabled={isDisabled}
        ref={ref}
      >
        {audioBuffer && (
          <div
            style={{
              position: 'absolute',
              top: 0.25 * lineHeight,
              left: 0.5 * lineHeight,
              right: 0.5 * lineHeight,
              display: 'flex',
            }}
          >
            <Waveform
              buffer={audioBuffer}
              height={lineHeight}
              color={
                disabled
                  ? 'var(--input-disabled-text-color)'
                  : 'var(--input-text-color)'
              }
            />
          </div>
        )}
      </Input>
      {selectAudioFileDialogActive && (
        <SelectAudioFileDialog
          close={() => {
            setSelectAudioFileDialogActive(false);
          }}
          value={value}
          onChange={value => onChange(value)}
        />
      )}
    </>
  );
};
