import React, { FC } from 'react';
import styled from 'styled-components';
import { ProbabilityDistribution, ProbabilityDistributionType } from 'helicon';
import { FloatInput } from '..';
import { Col, Label, Row } from '../../../../../components';
import { exponential, uniform } from 'helicon/build';
import { GaussianDistributionInput } from './GaussianDistributionInput';
import { SSParamDescription } from '../../../../../types';
import { lineHeight } from '../../../../../style';
import { ExponentialIcon, GaussianIcon, UniformIcon } from '../../icons';
import { convertProbabilityDistribution } from '../../../../../utils';
import {
  PROBABILITY_DISTRIBUTION_TYPE_LABELS,
  TAB_ORDER,
} from '../../../../../data';

const TypeButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding: ${lineHeight / 2}px ${lineHeight / 2}px;
  background: var(--input-color);
  &:disabled {
    background: var(--input-disabled-color);
  }
  height: ${1.5 * lineHeight}px;
  svg * {
    fill: none;
    stroke: var(--input-text-color);
    stroke-width: 2;
    vector-effect: non-scaling-stroke;
  }
`;

export type ProbabilityDistributionInputProps = {
  id?: string;
  value: ProbabilityDistribution;
  paramDescription: SSParamDescription;
  seededValue?: number;
  onChange: (value: ProbabilityDistribution) => void;
};

export const ProbabilityDistributionInput: FC<
  ProbabilityDistributionInputProps
> = ({ id, value, seededValue, paramDescription, onChange }) => {
  return (
    <Col gap={1} align="stretch">
      <Row>
        {[
          ProbabilityDistributionType.Uniform,
          ProbabilityDistributionType.Gaussian,
          ProbabilityDistributionType.Exponential,
        ].map(pdt => (
          <TypeButton
            key={pdt}
            tabIndex={TAB_ORDER.INPUT}
            disabled={pdt === value.type}
            onClick={() => {
              onChange(convertProbabilityDistribution(value, pdt));
            }}
            title={`${PROBABILITY_DISTRIBUTION_TYPE_LABELS[pdt]} distribution`}
          >
            <svg
              width={1 * lineHeight}
              height={lineHeight / 2}
              preserveAspectRatio="none"
            >
              {pdt === ProbabilityDistributionType.Uniform ? (
                <UniformIcon width={1 * lineHeight} height={lineHeight / 2} />
              ) : pdt === ProbabilityDistributionType.Gaussian ? (
                <GaussianIcon width={1 * lineHeight} height={lineHeight / 2} />
              ) : pdt === ProbabilityDistributionType.Exponential ? (
                <ExponentialIcon
                  width={1 * lineHeight}
                  height={lineHeight / 2}
                />
              ) : null}
            </svg>
          </TypeButton>
        ))}
      </Row>
      <Col gap={0.5} align="stretch">
        {value.type == ProbabilityDistributionType.Uniform ? (
          <>
            <div>
              <Label htmlFor={id && `${id}_min`}>min</Label>
              <FloatInput
                id={id && `${id}_min`}
                value={value.min}
                onChange={v => onChange(uniform(v, value.max))}
              />
            </div>
            <div>
              <Label htmlFor={`${id}_max`}>max</Label>
              <FloatInput
                id={id && `${id}_max`}
                value={value.max}
                onChange={v => onChange(uniform(value.min, v))}
              />
            </div>
          </>
        ) : value.type === ProbabilityDistributionType.Gaussian ? (
          <GaussianDistributionInput
            id={id}
            value={value}
            onChange={onChange}
            seededValue={seededValue}
            paramDescription={paramDescription}
          />
        ) : value.type === ProbabilityDistributionType.Exponential ? (
          <>
            <div>
              <Label htmlFor={id && `${id}_min`}>min</Label>
              <FloatInput
                id={id && `${id}_min`}
                value={value.min}
                onChange={v =>
                  onChange(exponential(v, value.max, value.exponent))
                }
              />
            </div>
            <div>
              <Label htmlFor={id && `${id}_max`}>max</Label>
              <FloatInput
                id={id && `${id}_max`}
                value={value.max}
                onChange={v =>
                  onChange(exponential(value.min, v, value.exponent))
                }
              />
            </div>
            <div>
              <Label htmlFor={id && `${id}_exponent`}>exponent</Label>
              <FloatInput
                id={id && `${id}_exponent`}
                value={value.exponent}
                onChange={v => onChange(exponential(value.min, value.max, v))}
              />
            </div>
          </>
        ) : null}
      </Col>
    </Col>
  );
};
