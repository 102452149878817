import React, { FC, useContext } from 'react';
import { File } from '../../../types';
import { useAPIAction } from '../../../hooks';
import { SessionContext } from '../../../contexts';
import { deleteFile } from '../../../api';
import { A, NetworkActivityIndicator } from '../../../components';

export type FileRowProps = {
  file: File;
  onDelete: () => void;
};

export const FileRow: FC<FileRowProps> = ({
  file: { name, size, contentHash },
  onDelete,
}) => {
  const { session } = useContext(SessionContext);
  const [callDeleteFile, deleteFileLoading, deleteFileError] = useAPIAction(
    deleteFile,
  );

  return (
    <tr key={contentHash}>
      <td>{name}</td>
      <td>{(size / 1000000).toFixed(2)} MB</td>
      <td>
        {deleteFileLoading || deleteFileError || session === undefined ? (
          <NetworkActivityIndicator
            pending={false}
            active={deleteFileLoading}
            error={!!deleteFileError}
          />
        ) : (
          <A
            onClick={async () => {
              if (
                confirm(
                  "Are you sure you want to delete this file?  This can't be undone",
                )
              ) {
                await callDeleteFile({ session, contentHash });
                onDelete();
              }
            }}
          >
            delete
          </A>
        )}
      </td>
    </tr>
  );
};
