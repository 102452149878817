import React, { FC } from 'react';
import { Col, InfoText, Label, Row } from '../../../../components';
import { TAB_ORDER } from '../../../../data';
import { weightedRandomMember } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { WeightInput } from './WeightInput';
import { OptionLabel } from './OptionLabel';
import { WeightedRandomMemberValue } from 'helicon';
import { lineHeight } from '../../../../style';

export const EnumWeightedRandomMemberValueInput: FC<
  ValueInputProps
> = props => {
  const { id, disabled, seededValue, onChange } = props;
  const value = props.value as WeightedRandomMemberValue;
  return (
    <Col gap={1} align="stretch">
      <Col align="stretch">
        <Row>
          <Label style={{ flex: 1 }}>value</Label>
          <Label style={{ width: 4 * lineHeight }}>weight</Label>
        </Row>
        {value.items.map(([val, weight], i) => (
          <OptionLabel
            htmlFor={`${id}_${i}`}
            key={i}
            highlighted={seededValue === val}
          >
            <span>{val}</span>
            <WeightInput
              id={`${id}_${i}`}
              disabled={disabled}
              tabIndex={TAB_ORDER.INPUT}
              value={weight}
              onChange={newWeight => {
                const copy = [...value.items];
                copy[i] = [val, newWeight];
                onChange(weightedRandomMember(copy));
              }}
            />
          </OptionLabel>
        ))}
      </Col>
      <InfoText>{seededValue}</InfoText>
    </Col>
  );
};
