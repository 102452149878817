import React, { FC } from 'react';
import { ValueInputProps } from './ValueInput';
import { FloatInput } from './FloatInput';
import { ArrayWeightedRandomSubsetValueInput } from './ArrayWeightedRandomSubsetValueInput';

export const FloatArrayWeightedRandomSubsetValueInput: FC<
  ValueInputProps<number[]>
> = props => {
  return (
    <ArrayWeightedRandomSubsetValueInput {...props} Component={FloatInput} />
  );
};
