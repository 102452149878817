export const TAB_ORDER = {
  HEADER_BUTTON: 1,
  NODE: 2,
  EDGE: 3,
  IO: 4,
  VISUALIZATION: 5,
  COLUMN_HANDLE: 6,
  MINIMAP: 7,
  INPUT: 8,
  INPUT_SECONDARY: 9,
  MENU_ITEM: 10,
  DIALOG_CLOSE: 11,
};
