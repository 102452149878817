import React, { useContext } from 'react';
import { useDrag, DragHandler } from '../../../hooks/useDrag';
import { GridRefContext } from '../contexts';
import { Vector } from '../../../types';
import { floor, subtract } from '../../../utils';

export const useGridDrag = <T>(
  onDrag?: DragHandler<T>,
  onDrop?: DragHandler<T>,
  quantize?: boolean,
): ((e: React.MouseEvent, t: T) => void) => {
  const {
    current: { getGridPosition },
  } = useContext(GridRefContext);

  const getPosition = quantize
    ? (pagePosition: Vector) => floor(getGridPosition(pagePosition))
    : getGridPosition;

  return useDrag(
    onDrag === undefined
      ? undefined
      : (pagePosition, pageOrigin, _pageDelta, t, modifierKeys) => {
          const position = getPosition(pagePosition);
          const origin = getPosition(pageOrigin);
          const delta = subtract(position, origin);
          onDrag(position, origin, delta, t, modifierKeys);
        },
    onDrop === undefined
      ? undefined
      : (pagePosition, pageOrigin, _pageDelta, t, modifierKeys) => {
          const position = getPosition(pagePosition);
          const origin = getPosition(pageOrigin);
          const delta = subtract(position, origin);
          onDrop(position, origin, delta, t, modifierKeys);
        },
  );
};
