import React, { FC } from 'react';
import styled from 'styled-components';
import { lineHeight } from '../style';
import { Identicon } from './Identicon';
import { Row } from './Row';

const Username = styled.span`
  margin-left: ${lineHeight / 2}px;
`;

export type UserTagProps = {
  username: string;
};

export const UserTag: FC<UserTagProps> = ({ username }) => (
  <Row>
    <Identicon username={username} />
    <Username>{username}</Username>
  </Row>
);
