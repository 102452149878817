import { mulberry32, resolveValue } from '.';
import { Graph, SeededGraph, SeededNode } from '../types';

export const seedGraph = (graph: Graph, seed: number): SeededGraph => {
  const rng = mulberry32(seed);
  return {
    ...graph,
    seed,
    nodes: Object.entries(graph.nodes).reduce(
      (memo, [id, node]) => (
        (memo[id] = {
          ...node,
          params: Object.entries(node.params).reduce(
            (memo, [name, value]) => (
              (memo[name] = resolveValue(value, rng)), memo
            ),
            {} as { [name: string]: any },
          ),
        }),
        memo
      ),
      {} as { [id: string]: SeededNode },
    ),
  };
};
