import React, { FC } from 'react';
import { AudioBufferInput } from './AudioBufferInput';
import { ValueInputProps } from './ValueInput';
import { WeightInput } from './WeightInput';
import { ArrayInput } from './ArrayInput';
import { TAB_ORDER } from '../../../../data';
import { Col, InfoText, Label, Row } from '../../../../components';
import { WeightedRandomMemberValue } from 'helicon';
import { weightedRandomMember } from 'helicon/build';
import { lineHeight } from '../../../../style';

export const AudioBufferWeightedRandomMemberValueInput: FC<ValueInputProps> = ({
  id,
  disabled,
  value,
  seededValue,
  onChange,
}) => {
  const seededIndex = (value as WeightedRandomMemberValue).items.findIndex(
    ([v]) => v === seededValue,
  );

  return (
    <Col gap={1} align="stretch">
      <div>
        <Row>
          <Label style={{ flex: 1 }}>value</Label>
          <Label style={{ width: 5.5 * lineHeight }}>weight</Label>
        </Row>
        <ArrayInput
          id={id}
          disabled={disabled}
          value={(value as WeightedRandomMemberValue).items}
          onChange={items =>
            onChange(weightedRandomMember(items as [number, number][]))
          }
          defaultItem={[undefined, 1]}
          renderItemInput={(v, i, onChangeItem) => (
            <>
              <AudioBufferInput
                id={`${id}_${i}`}
                disabled={disabled}
                tabIndex={TAB_ORDER.INPUT}
                value={v[0]}
                onChange={nv => {
                  onChangeItem([nv, v[1]]);
                }}
              />
              <WeightInput
                disabled={disabled}
                tabIndex={TAB_ORDER.INPUT}
                value={v[1]}
                onChange={nv => onChangeItem([v[0], nv])}
              />
            </>
          )}
        />
      </div>
      <InfoText>buffer #{seededIndex + 1}</InfoText>
    </Col>
  );
};
