import React, { forwardRef, Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Vector, MenuItem, MenuItems } from '../types';
import { lineHeight, strokeWidth } from '../style';
import { TAB_ORDER } from '../data';

const MenuBody = styled.nav`
  background-color: var(--menu-color);
  position: absolute;
  z-index: 2;
  padding: ${lineHeight / 4}px 0;
  border: solid ${strokeWidth}px var(--menu-border-color);
  overflow-y: auto;
`;

const MenuSectionDivider = styled.hr`
  margin: ${lineHeight / 4}px 0;
  border: 0;
  border-top: solid ${strokeWidth}px var(--menu-divider-color);
`;

const SubmenuIcon = styled.span`
  padding-left: 2em;
`;

const MenuItemText = styled.span`
  color: inherit;
`;

type MenuItemProps = {
  active?: boolean;
  disabled?: boolean;
};
const MenuItem = styled.a`
  display: flex;
  justify-content: space-between;
  padding: ${lineHeight / 4}px ${lineHeight}px;
  background-color: ${({ active, disabled }: MenuItemProps) =>
    active && !disabled ? `var(--menu-active-color)` : 'transparent'};
  color: var(
    ${({ disabled }) =>
      disabled ? '--menu-disabled-text-color' : '--text-color'}
  );
  cursor: ${({ disabled }: MenuItemProps) =>
    disabled ? 'default' : 'pointer'};
  white-space: nowrap;
  &:focus {
    outline: none;
  }
`;

export type SubmenuProps = {
  position: Vector;
  maxHeight: number;
  items: MenuItems;
  activeItem: [number, number] | undefined;
  activate: (i: number, j: number, isKeyboard: boolean) => void;
  trigger: (i: number, j: number) => void;
};
export const Submenu = forwardRef<HTMLElement, SubmenuProps>(
  (
    { position: [left, top], maxHeight, items, activeItem, activate, trigger },
    ref,
  ) => {
    const itemsRef = useRef<HTMLAnchorElement[][]>([]);
    const itemRef = (i: number, j: number) => (el: HTMLAnchorElement) => {
      itemsRef.current[i] = itemsRef.current[i] ?? [];
      itemsRef.current[i][j] = el;
    };
    useEffect(() => {
      if (activeItem === undefined) return;
      const [i, j] = activeItem;
      itemsRef.current[i][j].focus();
    }, [items, activeItem]);
    return (
      <MenuBody
        ref={ref}
        style={{ left, top, maxHeight }}
        onMouseDown={e => {
          e.stopPropagation();
        }}
      >
        {items.map((group, groupIndex) => (
          <Fragment key={groupIndex}>
            {groupIndex > 0 && <MenuSectionDivider />}
            {group.map(([text, mid, disabled], itemIndex) => {
              const active =
                activeItem !== undefined &&
                groupIndex === activeItem[0] &&
                itemIndex === activeItem[1];
              return (
                <MenuItem
                  key={itemIndex}
                  ref={itemRef(groupIndex, itemIndex)}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    trigger(groupIndex, itemIndex);
                  }}
                  onMouseEnter={() => activate(groupIndex, itemIndex, false)}
                  onFocus={
                    active
                      ? undefined
                      : () => activate(groupIndex, itemIndex, true)
                  }
                  active={active}
                  disabled={disabled}
                  tabIndex={TAB_ORDER.MENU_ITEM}
                >
                  <MenuItemText>{text}</MenuItemText>
                  {Array.isArray(mid) && <SubmenuIcon>▶</SubmenuIcon>}
                </MenuItem>
              );
            })}
          </Fragment>
        ))}
      </MenuBody>
    );
  },
);
