import { NodeExtension, ParamType } from 'helicon';

export const ADEnvelopeExtension: NodeExtension = {
  type: 'ADEnvelope',
  processor: '/ADEnvelopeProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      clock: {
        type: ParamType.AudioParam,
        default: 0,
      },
      attack: {
        type: ParamType.AudioParam,
        min: 0.001,
        default: 0.001,
      },
      decay: {
        type: ParamType.AudioParam,
        min: 0.001,
        default: 0.5,
      },
      hold: {
        type: ParamType.Value,
        default: false,
      },
      loop: {
        type: ParamType.Value,
        default: false,
      },
    },
    signals: {
      trigger: {
        trigger: true,
      },
    },
  },
};
