import React, { FC, useContext } from 'react';
import { ProfileController } from './components';
import { DefaultScaffold, Link } from '../../components';
import { SessionContext } from '../../contexts';
import { listGraphs } from '../../api';
import { useAPIData } from '../../hooks';
import { TAB_ORDER } from '../../data';

export type ProfileProps = {
  path: string[];
};

export const Profile: FC<ProfileProps> = ({ path: [username] }) => {
  const { session } = useContext(SessionContext);
  const [, error, data] = useAPIData(
    () => listGraphs({ session, owner: username }),
    [session],
  );

  return data ? (
    <ProfileController account={data.account} graphs={data.graphs} />
  ) : (
    <DefaultScaffold
      breadcrumbs={
        <>
          <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            sinesaw
          </Link>
          {' / '}
          <Link plain href={`/${username}`} tabIndex={TAB_ORDER.HEADER_BUTTON}>
            {username}
          </Link>
        </>
      }
      error={error}
    />
  );
};
