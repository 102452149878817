import { createContext } from 'react';
import { Vector, Rect } from '../../../types';

export type GridOptions = {
  /** the calculated size of an individual grid cell in pixels */
  cellSize: Vector;
  /** the size and position of the root element in pixels relative to the window */
  domRect: Rect;
  /** the size and position of the viewport in cells relative to the grid origin */
  viewport: Rect;
  /** convert event position in pixels to grid cell position */
  getGridPosition: (pagePosition: Vector) => Vector;
};

export const defaultGridOptions: GridOptions = {
  cellSize: [0, 0],
  domRect: [
    [0, 0],
    [0, 0],
  ],
  viewport: [
    [0, 0],
    [0, 0],
  ],
  getGridPosition: () => [0, 0],
};

export const GridContext = createContext<GridOptions>(defaultGridOptions);
