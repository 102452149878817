import React, { FC } from 'react';
import { fixed } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { BooleanInput } from './BooleanInput';
import { FixedValue } from 'helicon';
import { Row } from '../../../../components';

export const BooleanFixedValueInput: FC<ValueInputProps<boolean>> = ({
  id,
  tabIndex,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Row gap={0.5} align="center" as="label" htmlFor={id}>
      <BooleanInput
        id={id}
        tabIndex={tabIndex}
        disabled={disabled}
        value={(value as FixedValue).value}
        onChange={value => onChange(fixed(value))}
      />
      {(value as FixedValue).value ? 'on' : 'off'}
    </Row>
  );
};
