import { SSIO } from './SSGraph';

export enum TargetType {
  Node = 'Node',
  Edge = 'Edge',
  IO = 'IO',
}

export interface NodeTarget {
  readonly type: TargetType.Node;
  readonly id: string;
}

export interface EdgeTarget {
  readonly type: TargetType.Edge;
  readonly id: string;
}

export interface IOTarget {
  readonly type: TargetType.IO;
  readonly io: SSIO;
}

export type Target = NodeTarget | EdgeTarget | IOTarget;
