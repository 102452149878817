import React, { FC } from 'react';
import { useContext } from 'react';
import { Col } from '../../../components';
import { inputWidth, lineHeight } from '../../../style';
import { SSEdge } from '../../../types';
import { GraphContext } from '../contexts';
import { Analyser } from './Analyser';
import { EdgeDescription } from './EdgeDescription';

export type EdgeSelectionInfoProps = {
  edge: SSEdge;
};

export const EdgeSelectionInfo: FC<EdgeSelectionInfoProps> = ({ edge }) => {
  const { graph } = useContext(GraphContext);

  return (
    <Col gap={1} align="stretch">
      <EdgeDescription graph={graph} id={edge.id} />
      <Analyser
        edge={edge}
        size={[inputWidth + 2 * lineHeight, 2 * lineHeight]}
      />
    </Col>
  );
};
