import React, { FC } from 'react';
import { ValueInputProps } from './ValueInput';
import { IntegerArrayInput } from './IntegerArrayInput';
import { ArrayWeightedRandomMemberValueInput } from './ArrayWeightedRandomMemberValueInput';

export const IntegerArrayWeightedRandomMemberValueInput: FC<
  ValueInputProps<number[]>
> = props => {
  return (
    <ArrayWeightedRandomMemberValueInput
      {...props}
      Component={IntegerArrayInput}
    />
  );
};
