import { createGlobalStyle } from 'styled-components';
import {
  strokeWidth,
  fontSize,
  fontWeight,
  lineHeight,
  lightTheme,
  darkTheme,
} from '../style';

export const GlobalStyle = createGlobalStyle`
  html, body, #app {
    margin: 0;
    padding: 0;
    user-select: none;
    display: block;
    overflow: hidden;
    overscroll-behavior: none;
    width: 100vw;
    height: 100vh;
  }

  body {
    ${Object.entries(lightTheme)
      .map(([k, v]) => `${k}:${v};`)
      .join('\n')}

    @media (prefers-color-scheme: dark) {
      ${Object.entries(darkTheme)
        .map(([k, v]) => `${k}:${v};`)
        .join('\n')}
    }

    background-color: var(--background-color);
  }

  * {
    box-sizing: border-box;
    font-family: "Mono", monospace;
    font-weight: ${fontWeight};
    color: var(--text-color);
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    vertical-align: top;
  }
  *:focus {
    z-index: 1;
    outline: solid var(--outline-color) ${strokeWidth}px;
    outline-offset: ${strokeWidth}px;
  }
  svg *:focus {
    outline: none;
  }
  ::selection {
    color: var(--text-color);
    background: var(--selection-color);
  }
`;
