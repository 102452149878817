import React, { FC } from 'react';
import { SectionsContentLayout, P, A } from '../../components';

export const OpenSource: FC = () => {
  return (
    <SectionsContentLayout
      title="open source"
      path="/open-source"
      sections={[
        {
          id: 'open-source',
          title: 'Open source',
          content: (
            <>
              <P>
                Sinesaw itself is not open source, but its core components that
                are useful for working with audio in web apps and for building
                live interactivity in web apps are.
              </P>
            </>
          ),
        },

        {
          id: 'contributions',
          title: 'Contributions',
          content: (
            <>
              <P>
                <A href="htps://github.com/charlieschwabacher/audiograph">
                  AudioGraph
                </A>{' '}
                is a library for managing web audio graphs in a functional style
                with straighforward and efficent updates. It also handles some
                common setup tasks like loading audio files and worklet
                processors.
              </P>
              <P>
                <A href="https://github.com/charlieschwabacher/ultrawave">
                  Ultrawave
                </A>{' '}
                is a library making it easy to build peer to peer collaborative
                web apps. It was written to facilitate the multi user live
                editing features in Sinesaw.
              </P>
            </>
          ),
        },
        {
          id: 'acknowledgements',
          title: 'Acknowledgements',
          content: (
            <>
              <P>
                Sinesaw's reverb, pitch shifter, and resonator nodes are based
                on open source code from{' '}
                <A href="https://mutable-instruments.net/">
                  Mutable Instruments
                </A>{' '}
                eurorack modules. These modules are a Joy to use and an endless
                source of inspiration, both to create music and to create
                musical instruments like Sinesaw.
              </P>
              <P>
                Sinesaw also uses <A href="https://reactjs.org/">React</A> and
                <A href="https://styled-components.com/">
                  Styled Components
                </A>{' '}
                for UI, and <A href="https://www.hsluv.org/">HSLUV</A> to
                generate its color scheme.
              </P>
            </>
          ),
        },
      ]}
    />
  );
};
