import { createContext, MouseEvent } from 'react';
import { Target } from '../../../types';

export type SelectionOptions = {
  selections: { [username: string]: Target[] | undefined };
  select: (...s: Target[]) => (e: MouseEvent) => void;
};

export const defaultSelectionOptions = {
  selections: {},
  select: () => () => {},
};

export const SelectionContext = createContext<SelectionOptions>(
  defaultSelectionOptions,
);
