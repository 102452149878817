import React, { FC, useState, useContext } from 'react';
import styled from 'styled-components';
import { readAccount } from '../../../api';
import {
  Label,
  Button,
  Input,
  UserTag,
  Stack,
  NetworkActivityIndicator,
} from '../../../components';
import { SessionContext } from '../../../contexts';
import { useThrottledEffect } from '../../../hooks';
import { lineHeight } from '../../../style';
import { GraphPermission, Account } from '../../../types';
import { TAB_ORDER } from '../../../data';

export type GraphPermissionsControlsProps = {
  permissions: GraphPermission[];
  updateGraphPermission: (username: string, write: boolean) => void;
  deleteGraphPermission: (username: string) => void;
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchInput = styled(Input)`
  margin-right: ${lineHeight}px;
  width: 100%;
`;
const InlineButton = styled(Button)`
  width: auto;
  flex: 0 0 auto;
`;

export const GraphPermissionsControls: FC<GraphPermissionsControlsProps> = ({
  permissions,
  updateGraphPermission,
  deleteGraphPermission,
}) => {
  const { session } = useContext(SessionContext);
  const [username, setUsername] = useState<string>('');
  const [account, setAccount] = useState<Account | undefined>();
  const { pending, active } = useThrottledEffect(
    async () => {
      if (
        username.match(/^[\w]{3,64}$/) &&
        !permissions.some(p => p.username === username) &&
        username !== session?.username
      ) {
        const response = await readAccount({ username });
        if (response.status === 200) {
          setAccount(response.body.account);
          return;
        }
      }
      setAccount(undefined);
    },
    1000,
    [username],
  );

  const disabled =
    pending || active || account === undefined || account.username !== username;

  return (
    <div>
      <Label>Access</Label>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (disabled) return;
          updateGraphPermission(username, false);
          setUsername('');
        }}
      >
        <Row style={{ marginBottom: lineHeight }}>
          <SearchInput
            value={username}
            onChange={e => setUsername(e.target.value)}
            tabIndex={TAB_ORDER.INPUT}
          />
          <div
            style={{
              width: 4 * lineHeight,
              display: 'flex',
              justifyContent: 'center',
              flex: '0 0 auto',
            }}
          >
            {pending || active ? (
              <NetworkActivityIndicator
                pending={pending}
                active={active}
                error={false}
              />
            ) : (
              <Button
                type="submit"
                disabled={disabled}
                tabIndex={TAB_ORDER.INPUT}
                style={{ textAlign: 'center' }}
              >
                Add
              </Button>
            )}
          </div>
        </Row>
      </form>
      <Stack>
        {permissions.map(({ username, write }) => (
          <Row>
            <UserTag username={username} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Label
                style={{
                  margin: `0 ${lineHeight}px 0 0`,
                  lineHeight: `${lineHeight * 1.5}px`,
                }}
              >
                write
                <Input
                  type="checkbox"
                  style={{ marginLeft: lineHeight / 2 }}
                  checked={write}
                  onChange={e => {
                    updateGraphPermission(username, e.target.checked);
                  }}
                  tabIndex={TAB_ORDER.INPUT}
                />
              </Label>
              <InlineButton
                tabIndex={TAB_ORDER.INPUT}
                onClick={() => deleteGraphPermission(username)}
                style={{ width: 1.5 * lineHeight }}
              >
                ×
              </InlineButton>
            </div>
          </Row>
        ))}
      </Stack>
    </div>
  );
};
