import {
  FixedValue,
  GaussianDistribution,
  ProbabilityDistributionType,
  RandomNumberSequenceValue,
  RandomNumberValue,
  UniformDistribution,
  Value,
  ValueType,
  WeightedRandomMemberValue,
  WeightedRandomSequenceValue,
  WeightedRandomSubsetValue,
} from 'helicon';
import {
  fixed,
  randomInteger,
  randomNumber,
  randomNumberSequence,
  uniform,
  weightedRandomMember,
  weightedRandomSequence,
  weightedRandomSubset,
} from 'helicon/build';
import { SSParamDescription } from '../types';
import { convertProbabilityDistribution } from './convertProbabililtyDistribution';

type Converter = (value: any, pd: SSParamDescription) => Value;
type ConversionTable = {
  [fromType in ValueType]?: {
    [fromType in ValueType]?: Converter;
  };
};

const minMax = (items: number[]) =>
  items.length > 0
    ? items.reduce(
        (memo: { min: number; max: number }, v: number) => (
          (memo.min = Math.min(v, memo.min)),
          (memo.max = Math.max(v, memo.max)),
          memo
        ),
        { min: Infinity, max: -Infinity },
      )
    : { min: 0, max: 0 };

const maxWeighted = (v: WeightedRandomMemberValue) =>
  v.items.reduce(
    ([memoValue, memoWeight], [currentValue, currentWeight]) =>
      currentWeight > memoWeight
        ? [currentValue, currentWeight]
        : [memoValue, memoWeight],
    [null, -Infinity],
  )[0];

const distribute = (v: RandomNumberSequenceValue): number[] => {
  const length = (
    convertProbabilityDistribution(
      v.length,
      ProbabilityDistributionType.Gaussian,
    ) as GaussianDistribution
  ).mean;
  const { min, max } = convertProbabilityDistribution(
    v.distribution,
    ProbabilityDistributionType.Uniform,
  ) as UniformDistribution;
  const step = (max - min) / (length - 1);
  return new Array(length)
    .fill(undefined)
    .map((_, i) => Math.round(min + step * i * 1000) / 1000);
};

export const VALUE_CONVERSION_TABLE: ConversionTable = {
  [ValueType.Fixed]: {
    [ValueType.RandomNumber]: (v: FixedValue) =>
      randomNumber(uniform(v.value, v.value)),
    [ValueType.RandomInteger]: (v: FixedValue) =>
      randomInteger(uniform(v.value, v.value)),
    [ValueType.WeightedRandomMember]: (
      v: FixedValue,
      pd: SSParamDescription,
    ) => {
      return pd.options
        ? weightedRandomMember(pd.options.map(o => [o, o === v.value ? 1 : 0]))
        : weightedRandomMember([[v.value, 1]]);
    },
    [ValueType.WeightedRandomSubset]: (v: FixedValue) =>
      weightedRandomSubset(v.value.map((n: number) => [n, 1])),
    [ValueType.RandomNumberSequence]: (v: FixedValue) => {
      const { min, max } = minMax(v.value);
      return randomNumberSequence(
        uniform(min, max),
        uniform(v.value.length, v.value.length),
      );
    },
    [ValueType.WeightedRandomSequence]: (v: FixedValue) =>
      weightedRandomSequence(
        v.value.map((vv: any) => [vv, 1]),
        uniform(v.value.length, v.value.length),
      ),
  },
  [ValueType.RandomNumber]: {
    [ValueType.Fixed]: (v: RandomNumberValue) =>
      fixed(
        (
          convertProbabilityDistribution(
            v.distribution,
            ProbabilityDistributionType.Gaussian,
          ) as GaussianDistribution
        ).mean,
      ),
    [ValueType.WeightedRandomMember]: (v: RandomNumberValue) =>
      weightedRandomMember([
        [
          (
            convertProbabilityDistribution(
              v.distribution,
              ProbabilityDistributionType.Gaussian,
            ) as GaussianDistribution
          ).mean,
          1,
        ],
      ]),
  },
  [ValueType.RandomInteger]: {
    [ValueType.Fixed]: (v: RandomNumberValue) =>
      fixed(
        Math.round(
          (
            convertProbabilityDistribution(
              v.distribution,
              ProbabilityDistributionType.Gaussian,
            ) as GaussianDistribution
          ).mean,
        ),
      ),
  },
  [ValueType.WeightedRandomMember]: {
    [ValueType.RandomNumber]: (v: WeightedRandomMemberValue) => {
      const values = v.items.map(([v]) => v);
      return randomNumber(uniform(Math.min(...values), Math.max(...values)));
    },
    [ValueType.Fixed]: (v: WeightedRandomMemberValue) => fixed(maxWeighted(v)),
    [ValueType.WeightedRandomSubset]: (v: WeightedRandomMemberValue) =>
      weightedRandomSubset(maxWeighted(v).map((v: any) => [v, 1])),
    [ValueType.RandomNumberSequence]: (v: WeightedRandomMemberValue) => {
      const items = maxWeighted(v);
      const { min, max } = minMax(items);
      return randomNumberSequence(
        uniform(min, max),
        uniform(items.length, items.length),
      );
    },
    [ValueType.WeightedRandomSequence]: (v: WeightedRandomMemberValue) => {
      const items = maxWeighted(v) ?? [];
      return weightedRandomSequence(
        items.map((v: any) => [v, 1]),
        uniform(items.length, items.length),
      );
    },
  },
  [ValueType.WeightedRandomSubset]: {
    [ValueType.Fixed]: (v: WeightedRandomSubsetValue) =>
      fixed(v.items.map(([iv]) => iv)),
    [ValueType.WeightedRandomMember]: (v: WeightedRandomSubsetValue) =>
      weightedRandomMember([[v.items.map(([v]) => v), 1]]),
    [ValueType.RandomNumberSequence]: (v: WeightedRandomSubsetValue) => {
      const { min, max } = minMax(v.items.map(([v]) => v));
      const weightsSum = Math.ceil(
        v.items.reduce((memo, [_, weight]) => memo + weight, 0),
      );
      return randomNumberSequence(
        uniform(min, max),
        uniform(weightsSum, weightsSum),
      );
    },
    [ValueType.WeightedRandomSequence]: (v: WeightedRandomSubsetValue) => {
      const length = Math.round(
        v.items.reduce((memo, [, weight]) => memo + weight, 0),
      );
      return weightedRandomSequence(v.items, uniform(length, length));
    },
  },
  [ValueType.RandomNumberSequence]: {
    [ValueType.Fixed]: (v: RandomNumberSequenceValue) => fixed(distribute(v)),
    [ValueType.WeightedRandomMember]: (v: RandomNumberSequenceValue) =>
      weightedRandomMember([[distribute(v), 1]]),
    [ValueType.WeightedRandomSubset]: (v: RandomNumberSequenceValue) =>
      weightedRandomSubset(distribute(v).map(vv => [vv, 1])),
    [ValueType.WeightedRandomSequence]: (v: RandomNumberSequenceValue) =>
      weightedRandomSequence(
        distribute(v).map(vv => [vv, 1]),
        v.length,
      ),
  },
  [ValueType.WeightedRandomSequence]: {
    [ValueType.Fixed]: (v: WeightedRandomSequenceValue) =>
      fixed(v.items.map(([vv]) => vv)),
    [ValueType.WeightedRandomMember]: (v: WeightedRandomSequenceValue) =>
      weightedRandomMember([[v.items.map(([vv]) => vv), 1]]),
    [ValueType.WeightedRandomSubset]: (v: WeightedRandomSequenceValue) =>
      weightedRandomSubset(v.items),
    [ValueType.RandomNumberSequence]: (v: WeightedRandomSequenceValue) => {
      const { min, max } = minMax(v.items.map(([v]) => v));
      return randomNumberSequence(uniform(min, max), v.length);
    },
  },
};
