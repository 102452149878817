import React, { FC, useContext, useMemo } from 'react';
import { EditorController } from './components';
import { DefaultScaffold } from '../../components';
import { SSGraph, V1GraphForStorage } from '../../types';
import { SessionContext } from '../../contexts';
import { readGraph } from '../../api';
import { graphFromStorage } from '../../utils';
import { useAPIData } from '../../hooks';

export type EditorProps = {
  path: string[];
};

export const Editor: FC<EditorProps> = ({ path }) => {
  const { session } = useContext(SessionContext);
  const [, error, graphForStorage] = useAPIData<V1GraphForStorage>(
    () => readGraph({ session, graphID: path.join('/') }),
    [session],
  );
  const graph = useMemo<SSGraph | null>(
    () => graphForStorage && graphFromStorage(graphForStorage),
    [graphForStorage],
  );

  return graph ? (
    <EditorController initialGraph={graph} />
  ) : (
    <DefaultScaffold error={error} />
  );
};
