import { useContext, useEffect } from 'react';
import { AudioGraphContext } from '../contexts';
import { AudioGraph } from 'helicon';

export const useAudioGraphEffect = (
  cb: (ag: AudioGraph) => (() => void) | void,
  deps: ((ag: AudioGraph) => any[]) | any[],
): void => {
  const { audioGraph, updating } = useContext(AudioGraphContext);
  let cancelled = false;
  let complete: (() => void) | void;
  useEffect(
    () => {
      updating.then(() => {
        if (!cancelled) {
          complete = cb(audioGraph);
        }
      });
      return () => {
        if (complete) {
          complete();
        }
        cancelled = true;
      };
    },
    Array.isArray(deps) ? deps : deps(audioGraph),
  );
};
