import React, { FC } from 'react';
import { randomInteger } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { RandomNumberValue } from 'helicon';
import { ProbabilityDistributionInput } from './ProbabilityDistributionInput';
import { Col, InfoText } from '../../../../components';

export const IntegerRandomNumberValueInput: FC<ValueInputProps<number>> = ({
  id,
  value,
  seededValue,
  paramDescription,
  onChange,
}) => {
  const distribution = (value as RandomNumberValue).distribution;
  return (
    <Col gap={1} align="stretch">
      <ProbabilityDistributionInput
        id={id}
        value={distribution}
        seededValue={seededValue}
        paramDescription={paramDescription}
        onChange={value => onChange(randomInteger(value))}
      />
      <InfoText>{seededValue}</InfoText>
    </Col>
  );
};
