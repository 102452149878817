import { V0GraphForStorage, V1GraphForStorage, SSGraph } from '../types';
import { migrateV0Graph } from './migrateV0Graph';

export const graphFromStorage = (
  graph: V0GraphForStorage | V1GraphForStorage,
): SSGraph => {
  const migratedGraph: V1GraphForStorage =
    graph.v === undefined ? migrateV0Graph(graph) : graph;
  const { v, id, name, forkedFrom, nodes, edges } = migratedGraph;

  const [author] = id.split('/');
  const incomingEdges: { [id: string]: Array<string> } = {};
  const outgoingEdges: { [id: string]: Array<string> } = {};
  Object.values(nodes).forEach(node => {
    incomingEdges[node.id] = [];
    outgoingEdges[node.id] = [];
  });
  Object.values(edges).forEach(
    ({ id, from: { node: fromNode }, to: { node: toNode } }) => {
      incomingEdges[toNode].push(id);
      outgoingEdges[fromNode].push(id);
    },
  );
  return {
    v,
    id,
    author,
    name,
    forkedFrom,
    nodes,
    edges,
    incomingEdges,
    outgoingEdges,
  };
};
