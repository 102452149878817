import React, { FC } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { GraphContext } from '../contexts';

export const FlashElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: var(--text-color);
  opacity: 0;
  z-index: 666;
`;

export type FlashOverlayProps = {};

export const FlashOverlay: FC<FlashOverlayProps> = () => {
  const { seed } = useContext(GraphContext);
  const ref = useRef<HTMLDivElement | null>(null);
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current === false) {
      mounted.current = true;
      return;
    }
    ref.current?.animate([{ opacity: 0.7 }, { opacity: 0 }], {
      duration: 350,
      easing: 'ease-out',
    });
  }, [seed]);

  return <FlashElement ref={ref} />;
};
