import { NodeExtension, ParamType } from 'helicon';

export const StochasticRhythmExtension: NodeExtension = {
  type: 'StochasticRhythm',
  processor: '/StochasticRhythmProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      clock: {
        type: ParamType.AudioParam,
        default: 0,
      },
      reset: {
        type: ParamType.AudioParam,
        default: 0,
      },
      randomize: {
        type: ParamType.AudioParam,
        default: 0,
      },
      seed: {
        type: ParamType.Value,
        default: 1,
      },
      weights: {
        type: ParamType.Value,
        default: [],
      },
    },
    signals: {
      position: {
        readable: true,
        trigger: true,
        default: 0,
      },
      sequence: {
        readable: true,
        trigger: false,
        default: [],
      },
    },
  },
};
