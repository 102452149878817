import { SSGraph } from '../../../types';
import { UserState } from './State';
import { Action } from './Action';
import { interactionReducer } from './interactionReducer';
import { gridReducer } from './gridReducer';
import { layoutReducer } from './layoutReducer';

export const userReducer = (
  state: UserState,
  action: Action,
  graph: SSGraph,
  nextGraph: SSGraph,
): UserState => ({
  grid: gridReducer(state.grid, nextGraph, action),
  layout: layoutReducer(state.layout, action),
  interaction: interactionReducer(state.interaction, graph, nextGraph, action),
});
