import { NodeExtension, ParamType } from 'helicon';

export const TriggeredSamplerExtension: NodeExtension = {
  type: 'TriggeredSampler',
  processor: '/TriggeredSamplerProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      buffer: {
        type: ParamType.AudioBuffer,
        default: null,
      },
      clock: {
        type: ParamType.AudioParam,
        default: 0,
      },
      detune: {
        type: ParamType.AudioParam,
        default: 0,
      },
    },
    signals: {
      trigger: {
        trigger: true,
      },
    },
  },
};
