import { SSGraph, Rect, Vector } from '../types';
import { min, max, add, subtract } from '../utils';
import { getNodeSize } from './getNodeSize';

export const graphBounds = (graph: SSGraph): Rect => {
  const nodes = Object.values(graph.nodes);
  if (nodes.length === 0)
    throw new Error('cannot calculate bounds of empty graph');
  const minimum = nodes.reduce((memo, { position }) => min(memo, position), [
    Infinity,
    Infinity,
  ] as Vector);
  const maximum = nodes.reduce(
    (memo, node) => max(memo, add(node.position, getNodeSize(node))),
    [-Infinity, -Infinity] as Vector,
  );

  return [minimum, subtract(maximum, minimum)];
};
