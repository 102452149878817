import { NodeExtension, ParamType } from 'helicon';

export const ClockExtension: NodeExtension = {
  type: 'Clock',
  processor: '/ClockProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      rate: {
        type: ParamType.AudioParam,
        min: 0,
        default: 2,
      },
      swing: {
        type: ParamType.AudioParam,
        default: 0,
        min: 0,
        max: 1,
      },
      jitter: {
        type: ParamType.AudioParam,
        default: 0,
        min: 0,
        max: 1,
      },
      reset: {
        type: ParamType.AudioParam,
        default: 0,
      },
    },
    signals: {
      trigger: {
        trigger: true,
      },
    },
  },
};
