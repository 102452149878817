import { createContext } from 'react';
import { Action, deleteProject } from '../reducers';

export type Actions = {
  deleteProject(id: string): void;
};

export const bindActions = (dispatch: (a: Action) => void): Actions => ({
  deleteProject(id: string) {
    dispatch(deleteProject(id));
  },
});

export const ActionContext = createContext<Actions>(bindActions(() => {}));
