import React, { FC } from 'react';
import { strokeWidth } from '../../../../style';

export type UniformIconProps = {
  width: number;
  height: number;
};

export const UniformIcon: FC<UniformIconProps> = ({ width, height }) => {
  const w = width - strokeWidth;
  const h = height - strokeWidth;
  const s = strokeWidth / 2;
  return (
    <>
      <line x1={s} y1={s} x2={s} y2={h + s} />
      <line x1={w + s} y1={s} x2={w + s} y2={h + s} />
      <line x1={s} y1={0.5 * h + s} x2={w + s} y2={0.5 * h + s} />
    </>
  );
};
