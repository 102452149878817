import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { lineHeight } from '../../../style';

const Anchor = styled.a`
  background-color: var(--background-secondary-color);
`;
const SVG = styled.svg`
  width: 100%;
`;
const Path = styled.path`
  stroke: var(--button-color);
  transform: scale(1);
  transition: transform 0.2s;
  a:hover & {
    transform: scale(1.05);
    stroke: var(--button-hover-color);
  }
`;
const Name = styled.h3`
  padding: 0 ${lineHeight}px ${lineHeight / 2}px;
  margin: 0;
  font-size: 1em;
`;

export type NewProjectLinkProps = {
  onClick: (e: MouseEvent<HTMLAnchorElement>) => void;
};

export const NewProjectLink: FC<NewProjectLinkProps> = ({ onClick }) => (
  <Anchor onClick={onClick}>
    <SVG viewBox="-6 -4 12 8">
      <Path d="m -1 0 H 1 M 0 -1 V 1" strokeWidth="0.3" />
    </SVG>
    <Name>New Project</Name>
  </Anchor>
);
