import { NodeExtension, ParamType } from 'helicon';

export const ResonatorExtension: NodeExtension = {
  type: 'Resonator',
  processor: '/ResonatorProcessor.js',
  description: {
    numberOfInputs: 1,
    numberOfOutputs: 1,
    outputChannelCount: [2],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      frequency: {
        type: ParamType.AudioParam,
        default: 220,
      },
      detune: {
        type: ParamType.AudioParam,
        default: 0,
      },
      structure: {
        type: ParamType.AudioParam,
        default: 0.25,
      },
      brightness: {
        type: ParamType.AudioParam,
        default: 0.5,
      },
      damping: {
        type: ParamType.AudioParam,
        default: 0.3,
      },
      position: {
        type: ParamType.AudioParam,
        default: 0.999,
      },
    },
    signals: {},
  },
};
