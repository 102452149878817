import { Rect, Vector, GraphThumbnail } from '../types';
import { min, max, add, subtract } from '../utils';
import { getDefaultNodeSize } from './getNodeSize';

export const graphThumbnailBounds = ({
  data: { nodes },
}: GraphThumbnail): Rect => {
  if (nodes.length === 0) {
    return [
      [0, 0],
      [1, 1],
    ];
  }
  const minimum = nodes.reduce((memo, [, position]) => min(memo, position), [
    Infinity,
    Infinity,
  ] as Vector);
  const maximum = nodes.reduce(
    (memo, [type, position]) =>
      max(memo, add(position, getDefaultNodeSize(type))),
    [-Infinity, -Infinity] as Vector,
  );

  return [minimum, subtract(maximum, minimum)];
};
