import React, { FC } from 'react';
import { SectionsContentLayout, P } from '../../components';

export const Contact: FC = () => {
  return (
    <SectionsContentLayout
      title="contact"
      path="/contact"
      sections={[
        {
          id: 'contact',
          title: 'Contact',
          content: (
            <>
              <P>Lorem ipsum dolor sit amet.</P>
            </>
          ),
        },
      ]}
    />
  );
};
