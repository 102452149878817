import { ProbabilityDistribution } from './ProbabilityDistribution';

export enum ValueType {
  Fixed,
  RandomNumber,
  RandomInteger,
  RandomMember,
  WeightedRandomMember,
  WeightedRandomSubset,
  WeightedRandomSequence,
  RandomNumberSequence,
}

export type FixedValue<T = any> = {
  type: ValueType.Fixed;
  value: T;
};

export type RandomNumberValue = {
  type: ValueType.RandomNumber;
  distribution: ProbabilityDistribution;
};

export type RandomIntegerValue = {
  type: ValueType.RandomInteger;
  distribution: ProbabilityDistribution;
};

export type RandomMemberValue<T = any> = {
  type: ValueType.RandomMember;
  items: T[];
};

export type WeightedRandomMemberValue<T = any> = {
  type: ValueType.WeightedRandomMember;
  items: [T, number][];
};

export type WeightedRandomSubsetValue<T = any> = {
  type: ValueType.WeightedRandomSubset;
  items: [T, number][];
};

export type WeightedRandomSequenceValue<T = any> = {
  type: ValueType.WeightedRandomSequence;
  items: [T, number][];
  length: ProbabilityDistribution;
};

export type RandomNumberSequenceValue = {
  type: ValueType.RandomNumberSequence;
  length: ProbabilityDistribution;
  distribution: ProbabilityDistribution;
};

export type Value =
  | FixedValue
  | RandomNumberValue
  | RandomIntegerValue
  | RandomMemberValue
  | WeightedRandomMemberValue
  | WeightedRandomSubsetValue
  | WeightedRandomSequenceValue
  | RandomNumberSequenceValue;
