import { ScaleType, UIType } from '../types';

export interface ParamUIConfiguration {
  displayName: string;
  uiType: UIType;
  unit?: string;
  options?: any[];
  min?: number;
  max?: number;
  scale?: ScaleType;
}

export interface NodeUIConfiguration {
  displayName: string;
  shortName: string;
  params: {
    [name: string]: ParamUIConfiguration;
  };
  inputNames: string[];
  outputNames: string[];
}

export interface UIConfiguration {
  [name: string]: NodeUIConfiguration;
}

export const UI_CONFIGURATION: UIConfiguration = {
  AnalyserNode: {
    displayName: 'Analyser',
    shortName: 'ANL',
    params: {
      fftSize: {
        displayName: 'FFT size',
        uiType: UIType.Enum,
        options: [32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768],
        unit: 'samples',
      },
      minDecibels: {
        displayName: 'Min decibels',
        uiType: UIType.Hidden,
        unit: 'decibels',
      },
      maxDecibels: {
        displayName: 'Max decibels',
        uiType: UIType.Hidden,
        unit: 'decibels',
      },
      smoothingTimeConstant: {
        displayName: 'Smoothing time constant',
        uiType: UIType.Hidden,
      },
    },
    inputNames: ['input'],
    outputNames: ['through'],
  },
  AudioBufferSourceNode: {
    displayName: 'Audio buffer source',
    shortName: 'BUFF',
    params: {
      buffer: {
        displayName: 'Buffer',
        uiType: UIType.AudioBuffer,
      },
      loop: {
        displayName: 'Loop',
        uiType: UIType.Boolean,
      },
      loopStart: {
        displayName: 'Loop start',
        uiType: UIType.Float,
        unit: 'seconds',
      },
      loopEnd: {
        displayName: 'Loop end',
        uiType: UIType.Float,
        unit: 'seconds',
      },
      detune: {
        displayName: 'Detune',
        uiType: UIType.Float,
        unit: 'cents',
        min: -12000,
        max: 12000,
        scale: ScaleType.Linear,
      },
      playbackRate: {
        displayName: 'Playback rate',
        uiType: UIType.Float,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  AudioDestinationNode: {
    displayName: 'Destination',
    shortName: 'DEST',
    params: {
      gain: {
        displayName: 'Gain',
        uiType: UIType.Float,
      },
    },
    inputNames: ['input'],
    outputNames: [],
  },
  BiquadFilterNode: {
    displayName: 'Biquad filter',
    shortName: 'FLT',
    params: {
      frequency: {
        displayName: 'Frequency',
        uiType: UIType.Float,
        unit: 'hertz',
      },
      detune: {
        displayName: 'Detune',
        uiType: UIType.Float,
        unit: 'cents',
      },
      Q: {
        displayName: 'Quality',
        uiType: UIType.Float,
      },
      gain: {
        displayName: 'Gain',
        uiType: UIType.Float,
      },
      type: {
        displayName: 'Type',
        uiType: UIType.Enum,
        options: [
          'lowpass',
          'highpass',
          'bandpass',
          'lowshelf',
          'highshelf',
          'peaking',
          'notch',
          'allpass',
        ],
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  ChannelMergerNode: {
    displayName: 'Channel merger',
    shortName: 'CHM',
    params: {},
    inputNames: [
      'left',
      'right',
      'surrout left',
      'surrout right',
      'center',
      'LFE',
    ],
    outputNames: ['output'],
  },
  ChannelSplitterNode: {
    displayName: 'Channel splitter', // TODO: variable
    shortName: 'CHS',
    params: {},
    inputNames: ['input'],
    outputNames: [
      'left',
      'right',
      'surrout left',
      'surrout right',
      'center',
      'LFE',
    ],
  },
  ClockNode: {
    displayName: 'Clock',
    shortName: 'CLK',
    params: {
      rate: {
        displayName: 'Rate',
        uiType: UIType.Float,
      },
      swing: {
        displayName: 'Swing',
        uiType: UIType.Float,
      },
      jitter: {
        displayName: 'Jitter',
        uiType: UIType.Float,
      },
      reset: {
        displayName: 'Reset',
        uiType: UIType.Float,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  ConstantSourceNode: {
    displayName: 'Constant source',
    shortName: 'CNS',
    params: {
      offset: {
        displayName: 'Offset',
        uiType: UIType.Float,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  ConvolverNode: {
    displayName: 'Convolver',
    shortName: 'CNV',
    params: {
      buffer: {
        displayName: 'Impulse response',
        uiType: UIType.AudioBuffer,
      },
      normalize: {
        displayName: 'Normalize',
        uiType: UIType.Boolean,
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  ADEnvelopeNode: {
    displayName: 'AD Envelope',
    shortName: 'AD',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      attack: {
        displayName: 'Attack',
        uiType: UIType.Float,
        unit: 'seconds',
      },
      decay: {
        displayName: 'Decay',
        uiType: UIType.Float,
        unit: 'seconds',
      },
      hold: {
        displayName: 'Hold',
        uiType: UIType.Boolean,
      },
      loop: {
        displayName: 'Loop',
        uiType: UIType.Boolean,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  ADSREnvelopeNode: {
    displayName: 'ADSR Envelope',
    shortName: 'ADSR',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      attack: {
        displayName: 'Attack',
        uiType: UIType.Float,
        unit: 'seconds',
      },
      decay: {
        displayName: 'Decay',
        uiType: UIType.Float,
        unit: 'seconds',
      },
      sustain: {
        displayName: 'Sustain',
        uiType: UIType.Float,
      },
      release: {
        displayName: 'Release',
        uiType: UIType.Float,
        unit: 'seconds',
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  ArrangerNode: {
    displayName: 'Arranger',
    shortName: 'ARNG',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      sections: {
        displayName: 'Sections',
        uiType: UIType.IntegerArray,
      },
    },
    inputNames: [],
    outputNames: ['trigger', 'ramp'],
  },
  DelayNode: {
    displayName: 'Delay',
    shortName: 'DLY',
    params: {
      delayTime: {
        displayName: 'Time',
        uiType: UIType.Float,
        unit: 'seconds',
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  DynamicsCompressorNode: {
    displayName: 'Dynamics compressor',
    shortName: 'COMP',
    params: {
      threshold: {
        displayName: 'Threshold',
        uiType: UIType.Float,
        unit: 'decibels',
      },
      knee: {
        displayName: 'Knee',
        uiType: UIType.Float,
        unit: 'decibels',
      },
      ratio: {
        displayName: 'Ratio',
        uiType: UIType.Float,
      },
      attack: {
        displayName: 'Attack',
        uiType: UIType.Float,
        unit: 'seconds',
      },
      release: {
        displayName: 'Release',
        uiType: UIType.Float,
        unit: 'seconds',
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  EuclideanRhythmNode: {
    displayName: 'Euclidean Rhythm',
    shortName: 'ERTM',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      reset: {
        displayName: 'Reset',
        uiType: UIType.Hidden,
      },
      length: {
        displayName: 'Length',
        uiType: UIType.Integer,
      },
      pulses: {
        displayName: 'Pulses',
        uiType: UIType.Integer,
      },
      rotation: {
        displayName: 'Rotation',
        uiType: UIType.Integer,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  GainNode: {
    displayName: 'Gain',
    shortName: 'GAN',
    params: {
      gain: {
        displayName: 'Gain',
        uiType: UIType.Float,
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  IIRFilterNode: {
    displayName: 'IIR filter',
    shortName: 'IIR',
    params: {},
    inputNames: ['input'],
    outputNames: ['output'],
  },
  LogicNode: {
    displayName: 'Logic',
    shortName: 'LOG',
    params: {
      a: {
        displayName: 'A',
        uiType: UIType.Hidden,
      },
      b: {
        displayName: 'B',
        uiType: UIType.Hidden,
      },
    },
    inputNames: [],
    outputNames: ['AND', 'OR', 'XOR'],
  },
  LunarPhaseNode: {
    displayName: 'Lunar Phase',
    shortName: 'MOON',
    params: {},
    inputNames: [],
    outputNames: ['phase'],
  },
  OscillatorNode: {
    displayName: 'Oscillator',
    shortName: 'OSC',
    params: {
      frequency: {
        displayName: 'Frequency',
        uiType: UIType.Float,
        unit: 'hertz',
      },
      detune: {
        displayName: 'Detune',
        uiType: UIType.Float,
        unit: 'cents',
      },
      type: {
        displayName: 'Type',
        uiType: UIType.Enum,
        options: [
          'sine',
          'square',
          'sawtooth',
          'triangle',
          // omitting 'custom' here because it can only be set via the
          // setPeriodicWave() method
          // 'custom,
        ],
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  PannerNode: {
    displayName: 'Panner',
    shortName: 'CPAN',
    params: {
      coneInnerAngle: {
        displayName: 'Cone inner angle',
        uiType: UIType.Float,
        unit: 'degrees',
      },
      coneOuterAngle: {
        displayName: 'Cone outer angle',
        uiType: UIType.Float,
        unit: 'degrees',
      },
      coneOuterGain: {
        displayName: 'Cone outer gain',
        uiType: UIType.Float,
      },
      distanceModel: {
        displayName: 'Distance model',
        uiType: UIType.Enum,
        options: ['linear', 'inverse', 'exponential'],
      },
      panningModel: {
        displayName: 'Panning model',
        uiType: UIType.Enum,
        options: ['equalpower', 'HRTF'],
      },
      maxDistance: {
        displayName: 'Max distance',
        uiType: UIType.Float,
      },
      orientationX: {
        displayName: 'Orientation X',
        uiType: UIType.Float,
      },
      orientationY: {
        displayName: 'Orientation Y',
        uiType: UIType.Float,
      },
      orientationZ: {
        displayName: 'Orientation Z',
        uiType: UIType.Float,
      },
      positionX: {
        displayName: 'Position X',
        uiType: UIType.Float,
      },
      positionY: {
        displayName: 'Position Y',
        uiType: UIType.Float,
      },
      positionZ: {
        displayName: 'Position Z',
        uiType: UIType.Float,
      },
      refDistance: {
        displayName: 'Reference distance',
        uiType: UIType.Float,
      },
      rollOffFactor: {
        displayName: 'Roll-off factor',
        uiType: UIType.Float,
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  PitchShifterNode: {
    displayName: 'Pitch Shifter',
    shortName: 'PCH',
    params: {
      detune: {
        displayName: 'Detune',
        uiType: UIType.Float,
        unit: 'cents',
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  QuantizerNode: {
    displayName: 'Quantizer',
    shortName: 'QNT',
    params: {
      signal: {
        displayName: 'Signal',
        uiType: UIType.Hidden,
      },
      values: {
        displayName: 'Values',
        uiType: UIType.FloatArray,
      },
      range: {
        displayName: 'Range',
        uiType: UIType.Float,
      },
    },
    inputNames: [],
    outputNames: ['output', 'trigger'],
  },
  RandomGateNode: {
    displayName: 'Random gate',
    shortName: 'RNG',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      probability: {
        displayName: 'Probability',
        uiType: UIType.Float,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  ResonatorNode: {
    displayName: 'Resonator',
    shortName: 'RES',
    params: {
      frequency: {
        displayName: 'Frequency',
        uiType: UIType.Float,
        unit: 'hertz',
      },
      detune: {
        displayName: 'Detune',
        uiType: UIType.Float,
        unit: 'cents',
      },
      structure: {
        displayName: 'Structure',
        uiType: UIType.Float,
      },
      brightness: {
        displayName: 'Brightness',
        uiType: UIType.Float,
      },
      damping: {
        displayName: 'Damping',
        uiType: UIType.Float,
      },
      position: {
        displayName: 'Position',
        uiType: UIType.Float,
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  ReverbNode: {
    displayName: 'Reverb',
    shortName: 'RVB',
    params: {
      diffusion: {
        displayName: 'Diffusion',
        uiType: UIType.Float,
      },
      lp: {
        displayName: 'Lowpass',
        uiType: UIType.Float,
      },
      reverbTime: {
        displayName: 'Reverb time',
        uiType: UIType.Float,
      },
      amount: {
        displayName: 'Amount',
        uiType: UIType.Float,
      },
      gain: {
        displayName: 'Gain',
        uiType: UIType.Float,
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  RhythmNode: {
    displayName: 'Rhythm',
    shortName: 'RTM',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      reset: {
        displayName: 'Reset',
        uiType: UIType.Hidden,
      },
      sequence: {
        displayName: 'Sequence',
        uiType: UIType.Rhythm,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  SampleAndHoldNode: {
    displayName: 'Sample and hold',
    shortName: 'SAH',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      signal: {
        displayName: 'Signal',
        uiType: UIType.Hidden,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  SampleSlicerNode: {
    displayName: 'Sample Slicer',
    shortName: 'SLCR',
    params: {
      buffer: {
        displayName: 'Buffer',
        uiType: UIType.AudioBuffer,
      },
      slicePoints: {
        displayName: 'Slice points',
        uiType: UIType.FloatArray,
        min: 0,
        max: 1,
      },
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      reset: {
        displayName: 'Reset',
        uiType: UIType.Hidden,
      },
      start: {
        displayName: 'Start',
        uiType: UIType.Float,
      },
      length: {
        displayName: 'Length',
        uiType: UIType.Float,
      },
      detune: {
        displayName: 'Detune',
        uiType: UIType.Float,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  SequenceNode: {
    displayName: 'Sequence',
    shortName: 'SEQ',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      reset: {
        displayName: 'Reset',
        uiType: UIType.Hidden,
      },
      sequence: {
        displayName: 'Sequence',
        uiType: UIType.FloatArray,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  SlewLimiterNode: {
    displayName: 'Slew limiter',
    shortName: 'SLW',
    params: {
      time: {
        displayName: 'Time',
        unit: 'seconds',
        uiType: UIType.Float,
      },
      signal: {
        displayName: 'Signal',
        unit: 'cents',
        uiType: UIType.Hidden,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  StereoPannerNode: {
    displayName: 'Stereo panner',
    shortName: 'PAN',
    params: {
      pan: {
        displayName: 'Pan',
        uiType: UIType.Float,
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  StochasticRhythmNode: {
    displayName: 'Stochastic Rhythm',
    shortName: 'SRTM',
    params: {
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      reset: {
        displayName: 'Reset',
        uiType: UIType.Hidden,
      },
      randomize: {
        displayName: 'Randomize',
        uiType: UIType.Hidden,
      },
      weights: {
        displayName: 'Weights',
        uiType: UIType.FloatArray,
        min: 0,
        max: 1,
      },
      seed: {
        displayName: 'Seed',
        uiType: UIType.Integer,
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  TriggeredSamplerNode: {
    displayName: 'Triggered sampler',
    shortName: 'SMP',
    params: {
      buffer: {
        displayName: 'Buffer',
        uiType: UIType.AudioBuffer,
      },
      clock: {
        displayName: 'Clock',
        uiType: UIType.Hidden,
      },
      detune: {
        displayName: 'Detune',
        uiType: UIType.Float,
        unit: 'cents',
      },
    },
    inputNames: [],
    outputNames: ['output'],
  },
  WaveShaperNode: {
    displayName: 'Wave shaper',
    shortName: 'WVS',
    params: {
      curve: {
        displayName: 'Curve',
        uiType: UIType.FloatArray,
        min: -1,
        max: 1,
      },
      oversample: {
        displayName: 'Oversample',
        uiType: UIType.Enum,
        options: ['none', '2x', '4x'],
      },
    },
    inputNames: ['input'],
    outputNames: ['output'],
  },
  WhiteNoiseNode: {
    displayName: 'White noise',
    shortName: 'NSE',
    params: {},
    inputNames: [],
    outputNames: ['output'],
  },
};
