import { Vector, Rect } from '../types';
import {
  add,
  subtract,
  divideScalar,
  multiplyScalar,
  min,
  max,
  growVectorToMatchAspectRatio,
} from './vector';

export const center = ([position, size]: Rect): Vector =>
  add(position, divideScalar(size, 2));

export const expand = ([position, size]: Rect, factor: number): Rect => [
  subtract(position, multiplyScalar(size, (factor - 1) / 2)),
  multiplyScalar(size, factor),
];

export const contain = (
  [positionA, sizeA]: Rect,
  [positionB, sizeB]: Rect,
): Rect => {
  const minimum = min(positionA, positionB);
  const maximum = max(add(positionA, sizeA), add(positionB, sizeB));
  return [minimum, subtract(maximum, minimum)];
};

export const growRectToMatchAspectRatio = (
  [position, size]: Rect,
  aspect: Vector,
): Rect => {
  const nextSize = growVectorToMatchAspectRatio(size, aspect);
  const nextPosition = add(position, divideScalar(subtract(size, nextSize), 2));
  return [nextPosition, nextSize];
};
