import { Action, ActionType } from './Action';

const collapsibleActionTypes: Set<ActionType> = new Set([
  ActionType.Copy,
  ActionType.SetCursorPosition,
  ActionType.SetActivity,
  ActionType.SetFocusTarget,
  ActionType.SetHoverTarget,
  ActionType.SetSelection,
  ActionType.SetDOMRect,
  ActionType.SetViewport,
  ActionType.PanViewport,
  ActionType.ZoomViewport,
  ActionType.SetLayoutSize,
  ActionType.SetRightColumnSize,
]);

export const canCollapse = ({ type }: Action): boolean =>
  collapsibleActionTypes.has(type);
