import { request, Response } from '../utils';
import { Account, GraphThumbnail, Session } from '../types';

export const listGraphs = (body: {
  session?: Session;
  owner: string;
}): Response<{
  account: Account;
  graphs: Array<GraphThumbnail>;
}> => request('list-graphs', body);
