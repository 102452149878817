import React, { FC, useContext, memo } from 'react';
import { SelectionContext, ActivityContext, GraphContext } from '../contexts';
import { TargetType } from '../../../types';
import { NodeSelectionInfo } from './NodeSelectionInfo';
import { TargetDescription } from './TargetDescription';
import { ActivityDescription } from './ActivityDescription';
import { Stack } from '../../../components';
import { SessionContext } from '../../../contexts';
import { EdgeSelectionInfo } from './EdgeSelectionInfo';

export type SelectionInfoProps = {};

export const SelectionInfo: FC<SelectionInfoProps> = memo(({}) => {
  const { session } = useContext(SessionContext);
  const { selections } = useContext(SelectionContext);
  const activities = useContext(ActivityContext);
  const { graph, seededGraph } = useContext(GraphContext);

  const selection = session && selections[session.username];
  const activity = session && activities[session.username];
  const selectionKinds = new Set((selection ?? []).map(({ type }) => type));

  return activity ? (
    <Stack>
      <ActivityDescription activity={activity} />
    </Stack>
  ) : selection?.length === 1 ? (
    <>
      {selection[0].type === TargetType.Node ? (
        <NodeSelectionInfo
          node={graph.nodes[selection[0].id]}
          seededNode={seededGraph.nodes[selection[0].id]}
        />
      ) : selection[0].type === TargetType.Edge ? (
        <EdgeSelectionInfo
          key={selection[0].id}
          edge={graph.edges[selection[0].id]}
        />
      ) : (
        <Stack>
          <TargetDescription target={selection[0]} />
        </Stack>
      )}
    </>
  ) : selection ? (
    <Stack>
      <div>
        {selection.length}{' '}
        {selectionKinds.size === 1
          ? TargetType[[...selectionKinds][0]]
          : 'item'}
        s selected
      </div>
      <ul style={{ paddingLeft: '16px' }}>
        {selection.map((target, i) => (
          <li key={i}>
            <TargetDescription target={target} />
          </li>
        ))}
      </ul>
    </Stack>
  ) : (
    <span>no selection</span>
  );
});
