import { useEffect, useRef, useState } from 'react';

export const useThrottledEffect = (
  cb: () => Promise<void>,
  time: number, // amount of time to wait before triggering
  deps: any[],
): { pending: boolean; active: boolean; force: () => void } => {
  const [pending, setPending] = useState(false);
  const [active, setActive] = useState(false);
  const timeoutRef = useRef<number | null>(null);
  const cbRef = useRef<() => void>(cb);
  const hasRunRef = useRef(false);
  const performEffect = async () => {
    setActive(true);
    await cbRef.current();
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
    setActive(false);
    setPending(false);
  };

  useEffect(() => {
    cbRef.current = cb;
    if (!timeoutRef.current && hasRunRef.current) {
      setPending(true);
      timeoutRef.current = setTimeout(performEffect, time) as unknown as number;
    }
    hasRunRef.current = true;
  }, [time, ...deps]);
  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    [],
  );

  return { pending, active, force: performEffect };
};
