import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { ActivityContext } from '../contexts';
import { ActivityType } from '../../../types';
import { strokeWidth } from '../../../style';
import { getUserColor, subtract, min, max } from '../../../utils';
import { SessionContext } from '../../../contexts';

const Rect = styled.rect`
  stroke-width: ${strokeWidth};
  fill-opacity: 0.5;
  pointer-events: none;
  vector-effect: non-scaling-stroke;
`;

export type DragSelectionActivityUIProps = {};

export const DragSelectionActivityUI: FC<DragSelectionActivityUIProps> = () => {
  const { session } = useContext(SessionContext);
  const activities = useContext(ActivityContext);

  return (
    <>
      {Object.entries(activities).map(([username, activity]) => {
        if (
          activity === undefined ||
          activity.type !== ActivityType.DragSelection
        ) {
          return null;
        }

        const color =
          username === session?.username
            ? 'var(--outline-color)'
            : getUserColor(username);
        const { origin, position } = activity;
        const m = min(origin, position);
        const [x, y] = m;
        const [width, height] = subtract(max(origin, position), m);

        return (
          <Rect
            key={username}
            x={x}
            y={y}
            width={width}
            height={height}
            stroke={color}
            fill={color}
          />
        );
      })}
    </>
  );
};
