import React, { FC, useState, useEffect } from 'react';
import { Input } from '../../../../components';
import { TAB_ORDER } from '../../../../data';

export type IntegerInputProps = {
  tabIndex?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  name?: string;
  id?: string;
  value: number;
  onChange: (value: number) => void;
  highlighted?: boolean;
  min?: number;
  max?: number;
};

const pattern = /^-?[0-9]*$/;

export const IntegerInput: FC<IntegerInputProps> = ({
  tabIndex = TAB_ORDER.INPUT,
  autoFocus,
  name,
  id,
  disabled,
  value,
  highlighted,
  onChange,
  min = -Infinity,
  max = Infinity,
}) => {
  const [textValue, setTextValue] = useState(`${value}`);
  const [lastUpdatedValue, setLastUpdatedValue] = useState(value);

  useEffect(() => {
    if (value !== lastUpdatedValue) {
      setLastUpdatedValue(value);
      setTextValue(`${value}`);
    }
  });

  return (
    <Input
      fullWidth
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      disabled={disabled}
      name={name}
      id={id}
      value={textValue}
      highlighted={highlighted}
      onChange={({ target: { value } }) => {
        if (!value.match(pattern)) return;
        setTextValue(value);

        const parsed = Math.min(Math.max(min, parseInt(value)), max);
        if (!Number.isNaN(parsed)) {
          setLastUpdatedValue(parsed);
          onChange(parsed);
        }
      }}
      onBlur={() => setTextValue(`${value}`)}
    />
  );
};
