import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { strokeWidth, outlineExpansion, outlineOffset } from '../../../style';
import { GraphContext, FocusTargetContext } from '../contexts';
import { TargetType } from '../../../types';
import {
  getNodeSize,
  getIOCenterPosition,
  getUserColor,
  add,
} from '../../../utils';
import { SessionContext } from '../../../contexts';

export const FocusOutline = styled.rect`
  fill: none;
  stroke-width: ${strokeWidth};
  vector-effect: non-scaling-stroke;
`;

export type FocusTargetUIProps = {};

export const FocusTargetUI: FC<FocusTargetUIProps> = () => {
  const { session } = useContext(SessionContext);
  const focusTargets = useContext(FocusTargetContext);
  const { graph } = useContext(GraphContext);

  return (
    <>
      {Object.entries(focusTargets).map(([username, focusTarget]) => {
        const color =
          username === session?.username
            ? 'var(--outline-color)'
            : getUserColor(username);

        if (focusTarget?.type === TargetType.Node) {
          const node = graph.nodes[focusTarget.id];
          const {
            position: [x, y],
          } = node;
          const [w, h] = getNodeSize(node);

          return (
            <FocusOutline
              key={username}
              x={x - outlineOffset}
              y={y - outlineOffset}
              width={w + outlineExpansion}
              height={h + outlineExpansion}
              stroke={color}
            />
          );
        } else if (focusTarget?.type === TargetType.IO) {
          const io = focusTarget.io;
          const node = graph.nodes[io.node];
          const r = 1 / 3;
          const d = 2 * r;
          const [cx, cy] = add(getIOCenterPosition(node, io), node.position);

          return (
            <FocusOutline
              key={username}
              x={cx - r - outlineOffset}
              y={cy - r - outlineOffset}
              width={d + outlineExpansion}
              height={d + outlineExpansion}
              stroke={color}
            />
          );
        } else if (focusTarget?.type === TargetType.Edge) {
          const { from, to } = graph.edges[focusTarget.id];
          const fromNode = graph.nodes[from.node];
          const toNode = graph.nodes[to.node];
          const fromPosition = fromNode.position;
          const toPosition = toNode.position;
          const [x1, y1] = add(
            fromPosition,
            getIOCenterPosition(fromNode, from),
          );
          const [x2, y2] = add(toPosition, getIOCenterPosition(toNode, to));

          return (
            <FocusOutline
              key={username}
              x={Math.min(x1, x2) - outlineOffset}
              y={Math.min(y1, y2) - outlineOffset}
              width={Math.abs(x1 - x2) + outlineExpansion}
              height={Math.abs(y1 - y2) + outlineExpansion}
              stroke={color}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
};
