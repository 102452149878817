import React, { FC, useState, useRef, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { A, Input, Label } from '../../../components';
import { lineHeight } from '../../../style';
import { TAB_ORDER } from '../../../data';

export type FilterableSelectListProps = {
  items: { id: string; label: string }[];
  itemSingular?: string;
  itemsPlural?: string;
  value: string | undefined;
  onChange: (value: string) => void;
};

const UL = styled.ul`
  list-style-type: none;
  height: ${8 * lineHeight}px;
  margin: 0;
  padding: 0;
  background: var(--background-secondary-color);
  overflow-y: auto;
`;
const LI = styled.li`
  padding: ${0.25 * lineHeight}px ${0.5 * lineHeight}px;
  ${({ selected }: { selected: boolean | undefined }) =>
    selected ? `background: var(--grid-color);` : ''}
  &:hover {
    background: var(--grid-color);
  }
  &:focus {
    background: var(--outline-color);
    outline: none;
  }
`;
const Info = styled.div`
  margin-top: ${lineHeight / 2}px;
  color: var(--text-secondary-color);
`;

export const FilterableSelectList: FC<FilterableSelectListProps> = ({
  items,
  itemSingular = 'item',
  itemsPlural = 'items',
  onChange,
  value,
}) => {
  const [filter, setFilter] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const itemsRef = useRef<(HTMLElement | null)[]>([]);
  const itemRef = (i: number) => (el: HTMLElement | null) => {
    itemsRef.current[i] = el;
  };

  const upperCaseFilter = filter.toUpperCase();
  const filteredItems =
    items.filter(({ label }) =>
      label.toUpperCase().includes(upperCaseFilter),
    ) ?? [];

  return (
    <div>
      <Label>Filter results</Label>
      <Input
        tabIndex={TAB_ORDER.INPUT}
        value={filter}
        onChange={e => setFilter(e.target.value)}
        ref={inputRef}
        onKeyDown={(e: KeyboardEvent) => {
          switch (e.key) {
            case 'ArrowDown':
              e.preventDefault();
              e.stopPropagation();
              itemsRef.current[0]?.focus();
              break;
          }
        }}
      />
      <UL>
        {filteredItems.map(({ id, label }, i) => (
          <LI
            key={id}
            onClick={() => onChange(id)}
            selected={id === value}
            tabIndex={TAB_ORDER.INPUT}
            ref={itemRef(i)}
            onKeyDown={(e: KeyboardEvent) => {
              switch (e.key) {
                case 'ArrowUp':
                  e.preventDefault();
                  e.stopPropagation();
                  if (i === 0) {
                    inputRef.current?.focus();
                  } else {
                    itemsRef.current[i - 1]?.focus();
                  }
                  break;
                case 'ArrowDown':
                  e.preventDefault();
                  e.stopPropagation();
                  itemsRef.current[i + 1]?.focus();
                  break;
                case 'Enter':
                  e.preventDefault();
                  e.stopPropagation();
                  onChange(id);
              }
            }}
          >
            {label}
          </LI>
        ))}
      </UL>

      <Info>
        {filteredItems.length < items.length ? (
          <>
            Showing {filteredItems.length} of {items.length} {itemsPlural}.{' '}
            <A tabIndex={TAB_ORDER.INPUT} onClick={() => setFilter('')}>
              clear filter
            </A>
          </>
        ) : (
          <>
            Showing {items.length}{' '}
            {items.length === 1 ? itemSingular : itemsPlural}
            <br />
            &nbsp;
          </>
        )}
      </Info>
    </div>
  );
};
