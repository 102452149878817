import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Row } from './Row';
import { Col } from './Col';
import { Button } from './Button';
import { Input } from './Input';
import { Label } from './Label';
import { ErrorMessage } from './ErrorMessage';
import { RadioButtonGroup } from './RadioButtonGroup';
import { TAB_ORDER } from '../data';
import { lineHeight, inputWidth } from '../style';

const Field = styled.div`
  margin: ${lineHeight}px 0;
`;
const SubmitButton = styled(Button)`
  margin: ${2 * lineHeight}px 0 0;
  width: ${inputWidth}px;
`;

export type ValidatedFormProps = {
  onSubmit: (values: { [name: string]: string }) => Promise<string | null>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  buttonText: string;
  fields: {
    [name: string]: {
      label: string;
      type?: string;
      pattern?: RegExp;
      options?: { [value: string]: string };
      validate?: (
        value: string,
        values: { [key: string]: string },
      ) => string | null;
    };
  };
  layout?: string[][];
  initialValues?: { [name: string]: string };
  renderInfo?: (values: { [name: string]: string }) => ReactNode;
};

export const ValidatedForm: FC<ValidatedFormProps> = ({
  onSubmit,
  loading,
  setLoading,
  buttonText,
  fields,
  layout = [Object.keys(fields)],
  initialValues = {},
  renderInfo,
}) => {
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);
  const [values, setValues] =
    useState<{ [name: string]: string }>(initialValues);

  const errors = Object.entries(fields).reduce((memo, [name, { validate }]) => {
    if (validate) memo[name] = validate(values[name], values);
    return memo;
  }, {} as { [name: string]: string | null });
  const valid = Object.values(errors).every(v => v === null);

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        setSubmitAttempted(true);
        if (valid) {
          setLoading(true);
          const serverError = await onSubmit(values);
          setServerError(serverError);
          setLoading(false);
        }
      }}
    >
      <Row gap={2}>
        {layout.map(col => (
          <Col>
            {col.map(name => {
              const { label, type, pattern, options } = fields[name];
              return (
                <Field key={name}>
                  <Label htmlFor={name}>{label}</Label>
                  {type === 'radio' && options ? (
                    <RadioButtonGroup
                      name={name}
                      value={values[name]}
                      options={options}
                      onChange={e => {
                        setValues({ ...values, [name]: e.target.value });
                      }}
                    />
                  ) : (
                    <Input
                      disabled={loading}
                      name={name}
                      id={name}
                      type={type}
                      tabIndex={TAB_ORDER.INPUT}
                      value={values[name] ?? ''}
                      onChange={e => {
                        if (
                          pattern === undefined ||
                          e.target.value.match(pattern)
                        ) {
                          setValues({ ...values, [name]: e.target.value });
                        }
                      }}
                    />
                  )}
                  {submitAttempted && errors[name] && (
                    <ErrorMessage htmlFor={name}>{errors[name]}</ErrorMessage>
                  )}
                </Field>
              );
            })}
          </Col>
        ))}
      </Row>
      {serverError && <ErrorMessage as="span">{serverError}</ErrorMessage>}
      <Row>
        <SubmitButton
          type="submit"
          disabled={loading}
          tabIndex={TAB_ORDER.INPUT}
        >
          {buttonText}
        </SubmitButton>
      </Row>
      {renderInfo && renderInfo(values)}
    </form>
  );
};
