import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { GraphThumbnail, MenuItem } from '../../../types';
import {
  Link,
  Shade,
  NetworkActivityIndicator,
  ErrorText,
  Overlay,
} from '../../../components';
import { lineHeight } from '../../../style';
import { graphThumbnailBounds, getCenteredViewport } from '../../../utils';
import { GraphThumbnailUI } from './GraphThumbnailUI';
import { ActionContext } from '../contexts';
import { RoutingContext, SessionContext } from '../../../contexts';
import { useAPIAction, useContextMenu } from '../../../hooks';
import { deleteGraph } from '../../../api';

const LinkContainer = styled(Link)`
  background-color: var(--project-preview-color);
  position: relative;
  display: block;
  overflow: hidden;
`;
const Name = styled.h3`
  padding: ${lineHeight / 2}px ${lineHeight}px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
  font-size: 1em;
`;
const SVG = styled.svg`
  width: 100%;
  g {
    transform: scale(1);
    transition: transform 0.2s;
  }
  a:hover & g {
    transform: scale(1.05);
  }
`;

export type ProjectPreviewProps = {
  graph: GraphThumbnail;
};

export const ProjectPreview: FC<ProjectPreviewProps> = ({ graph }) => {
  const { deleteProject } = useContext(ActionContext);
  const { route } = useContext(RoutingContext);
  const { session } = useContext(SessionContext);

  const bounds = graphThumbnailBounds(graph);
  const isEmpty = graph.data.nodes.length === 0;
  const [[x, y], [w, h]] = getCenteredViewport(bounds, isEmpty, [3, 2], 1.2);

  const [callDeleteGraph, deleteGraphLoading, deleteGraphError] = useAPIAction(
    deleteGraph,
  );

  const onContextMenu = useContextMenu([
    [
      [
        'Open',
        () => {
          route(`/${graph.id}`);
        },
      ],
      [
        'Open in new tab',
        () => {
          window.open(`/${graph.id}`);
        },
      ],
      [
        'Copy link',
        () => {
          navigator.clipboard.writeText(
            `${window.location.origin}/${graph.id}`,
          );
        },
      ],
    ],
    ...((session
      ? [
          [
            [
              'Delete',
              async () => {
                const response = await callDeleteGraph({
                  session,
                  graphID: graph.id,
                });
                if (response.status === 204) {
                  deleteProject(graph.id);
                }
              },
            ],
          ],
        ]
      : []) as MenuItem[][]),
  ]);

  return (
    <LinkContainer
      key={graph.id}
      href={`/${graph.id}`}
      onContextMenu={onContextMenu}
    >
      <SVG viewBox={`${x} ${y} ${w} ${h}`}>
        <g transform-origin={`${x + w / 2} ${y + h / 2}`}>
          <GraphThumbnailUI graphData={graph.data} />
        </g>
      </SVG>
      <Name>{graph.name}</Name>
      {(deleteGraphLoading || deleteGraphError) && (
        <Overlay>
          <Shade />
          <NetworkActivityIndicator
            pending={false}
            active={deleteGraphLoading}
            error={!!deleteGraphError}
          />
          <ErrorText>{deleteGraphError}</ErrorText>
        </Overlay>
      )}
    </LinkContainer>
  );
};
