import {
  SSIO,
  SSGraph,
  SSNode,
  ActivityType,
  IOType,
  TargetType,
  Target,
  NodeTarget,
  Vector,
  Clipboard,
} from '../../../types';
import { UserState } from './State';
import { Action, ActionType } from './Action';
import {
  seededRandomIDs,
  getNodesCenter,
  add,
  subtract,
  round,
  magnitude,
} from '../../../utils';
import { AudioNodeParams } from 'helicon';

export const graphReducer = (
  graph: SSGraph,
  action: Action,
  userState: UserState,
): SSGraph => {
  switch (action.type) {
    case ActionType.SetGraph:
      return action.graph;
    case ActionType.UpdateGraphMetadata:
      return updateGraphMetadata(graph, action.data);
    case ActionType.AddNode:
      return addNode(graph, action.node);
    case ActionType.RemoveNode:
      return removeNode(graph, action.id);
    case ActionType.UpdateNode:
      return updateNode(graph, action.id, action.params);
    case ActionType.UpdateNodeData:
      return updateNodeData(graph, action.id, action.data);
    case ActionType.Connect:
      return connect(graph, action.from, action.to, action.seed);
    case ActionType.Disconnect:
      return disconnect(graph, action.id);
    case ActionType.DisconnectIO:
      return disconnectIO(graph, action.io);
    case ActionType.Paste:
      return paste(
        graph,
        userState.interaction.clipboard,
        action.position &&
          userState.interaction.clipboard &&
          round(
            subtract(
              action.position,
              getNodesCenter(userState.interaction.clipboard.nodes),
            ),
          ),
        action.seed,
      );
    case ActionType.DeleteSelection:
      return deleteSelection(graph, userState.interaction.selection);
    case ActionType.TranslateSelection:
      return translateSelection(
        graph,
        userState.interaction.selection,
        action.delta,
      );
    case ActionType.PerformActivity:
      const {
        interaction: { hoverTarget },
      } = userState;
      const { activity } = action;
      switch (activity.type) {
        case ActivityType.NodeConnection:
          if (hoverTarget !== undefined && hoverTarget.type === TargetType.IO) {
            if (
              activity.origin.type === IOType.Input &&
              hoverTarget.io.type === IOType.Output
            ) {
              return connect(
                graph,
                hoverTarget.io,
                activity.origin,
                action.seed,
              );
            } else if (
              activity.origin.type === IOType.Output &&
              hoverTarget.io.type === IOType.Input
            ) {
              return connect(
                graph,
                activity.origin,
                hoverTarget.io,
                action.seed,
              );
            }
          }
          return graph;
        case ActivityType.NodeTranslation:
          if (magnitude(activity.delta) === 0) return graph;
          return [...activity.nodes].reduce(
            (g, node) =>
              updateNodeData(g, node.id, {
                position: add(node.position, activity.delta),
              }),
            graph,
          );
        case ActivityType.NodeClone:
          return paste(
            graph,
            activity.clipboard,
            activity.delta,
            activity.seed,
          );
        default:
          return graph;
      }
    default:
      return graph;
  }
};

// TODO: replace with update name action
const updateGraphMetadata = (
  graph: SSGraph,
  data: { name?: string; forkedFrom?: string[] },
): SSGraph => ({
  ...graph,
  ...data,
});

const addNode = (graph: SSGraph, node: SSNode): SSGraph => ({
  ...graph,
  nodes: {
    ...graph.nodes,
    [node.id]: node,
  },
  incomingEdges: {
    ...graph.incomingEdges,
    [node.id]: [],
  },
  outgoingEdges: {
    ...graph.outgoingEdges,
    [node.id]: [],
  },
});

const removeNode = (graph: SSGraph, nodeID: string): SSGraph => {
  if (graph.nodes[nodeID] === undefined) {
    return graph;
  }
  const incomingEdgeIds = graph.incomingEdges[nodeID];
  const outgoingEdgeIds = graph.outgoingEdges[nodeID];
  const edgesToRemove = new Set([...incomingEdgeIds, ...outgoingEdgeIds]);
  const disconnectedGraph = [...edgesToRemove].reduce(
    (memo, edgeID) => disconnect(memo, edgeID),
    graph,
  );

  const {
    nodes: { [nodeID]: _node, ...nodes },
    incomingEdges: { [nodeID]: _incoming, ...incomingEdges },
    outgoingEdges: { [nodeID]: _outgoing, ...outgoingEdges },
  } = disconnectedGraph;

  return {
    ...disconnectedGraph,
    nodes,
    incomingEdges,
    outgoingEdges,
  };
};

const updateNode = (
  graph: SSGraph,
  nodeID: string,
  params: AudioNodeParams,
): SSGraph => ({
  ...graph,
  nodes: {
    ...graph.nodes,
    [nodeID]: {
      ...graph.nodes[nodeID],
      params,
    },
  },
});

// TODO: split into seperate actions for update of position and name
const updateNodeData = (
  graph: SSGraph,
  nodeID: string,
  data: { position?: Vector; name?: string },
): SSGraph => ({
  ...graph,
  nodes: {
    ...graph.nodes,
    [nodeID]: {
      ...graph.nodes[nodeID],
      ...data,
    },
  },
});

const connect = (graph: SSGraph, from: SSIO, to: SSIO, seed: number) => {
  const [id] = seededRandomIDs(seed, 1);
  return {
    ...graph,
    edges: {
      ...graph.edges,
      [id]: {
        id,
        from,
        to,
      },
    },
    outgoingEdges: {
      ...graph.outgoingEdges,
      [from.node]: [...graph.outgoingEdges[from.node], id],
    },
    incomingEdges: {
      ...graph.incomingEdges,
      [to.node]: [...graph.incomingEdges[to.node], id],
    },
  };
};

const disconnect = (graph: SSGraph, edgeID: string): SSGraph => {
  if (graph.edges[edgeID] === undefined) return graph;

  const {
    [edgeID]: {
      from: { node: fromNode },
      to: { node: toNode },
    },
    ...edges
  } = graph.edges;

  return {
    ...graph,
    edges,
    outgoingEdges: {
      ...graph.outgoingEdges,
      [fromNode]: graph.outgoingEdges[fromNode].filter(id => id !== edgeID),
    },
    incomingEdges: {
      ...graph.incomingEdges,
      [toNode]: graph.incomingEdges[toNode].filter(id => id !== edgeID),
    },
  };
};

const disconnectIO = (graph: SSGraph, io: SSIO): SSGraph => {
  const edges =
    io.type === IOType.Input
      ? graph.incomingEdges[io.node].filter(
          id => graph.edges[id].to.index === io.index,
        )
      : graph.outgoingEdges[io.node].filter(
          id => graph.edges[id].from.index === io.index,
        );

  return edges.reduce((memo, id) => disconnect(memo, id), graph);
};

const deleteSelection = (graph: SSGraph, selection: Target[] | undefined) => {
  if (selection === undefined) return graph;
  return selection.reduce((graph, target) => {
    switch (target.type) {
      case TargetType.Node:
        return removeNode(graph, target.id);
      case TargetType.Edge:
        return disconnect(graph, target.id);
      default:
        return graph;
    }
  }, graph);
};

const translateSelection = (
  graph: SSGraph,
  selection: Target[] | undefined,
  delta: Vector,
): SSGraph => {
  if (selection === undefined) return graph;

  const nodes = (
    selection.filter(({ type }) => type === TargetType.Node) as NodeTarget[]
  ).map(({ id }) => graph.nodes[id]);

  return nodes.reduce(
    (graph, node) =>
      updateNodeData(graph, node.id, {
        position: add(node.position, delta),
      }),
    graph,
  );
};

const paste = (
  graph: SSGraph,
  clipboard: Clipboard | undefined,
  delta: Vector = [1, 1],
  seed: number,
): SSGraph => {
  if (clipboard === undefined) return graph;
  const nodeIDs = seededRandomIDs(seed, clipboard.nodes.length);
  const oldToNewNodeIds = clipboard.nodes.reduce(
    (memo: { [id: string]: string }, node, i) => {
      memo[node.id] = nodeIDs[i];
      return memo;
    },
    {},
  );
  const nextGraph = clipboard.nodes.reduce(
    (graph, node, i) =>
      addNode(graph, {
        ...node,
        id: nodeIDs[i],
        position: add(node.position, delta),
      }),
    graph,
  );
  return clipboard.edges.reduce((graph, { from, to }, i) => {
    return connect(
      graph,
      { ...from, node: oldToNewNodeIds[from.node] },
      { ...to, node: oldToNewNodeIds[to.node] },
      seed + i,
    );
  }, nextGraph);
};
