import { NodeExtension } from 'helicon';

export const LunarPhaseExtension: NodeExtension = {
  type: 'LunarPhase',
  processor: '/LunarPhaseProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 1,
    outputChannelCount: [1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {},
    signals: {},
  },
};
