import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Text = styled.text`
  text-anchor: middle;
  dominant-baseline: hanging;
  fill: var(--node-label-color);
  pointer-events: none;
  display: none;
`;

type NodeLabelProps = { x: number; y: number; children: ReactNode };

export const NodeLabel: FC<NodeLabelProps> = ({ x, y, children }) => {
  return (
    // The non-scaling-text and fading-text classes are created by the Grid
    // component, and update based on zoom
    <Text x={x} y={y} className="non-scaling-text fading-text">
      {children}
    </Text>
  );
};
