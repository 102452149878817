import { Vector } from './Vector';
import { Node, Graph, Edge, IO } from 'helicon';

export enum IOType {
  Input = 'Input',
  Output = 'Output',
}

export interface SSIO extends IO {
  readonly type: IOType;
}

export interface SSEdge extends Edge {
  readonly from: SSIO;
  readonly to: SSIO;
}

export interface SSNode extends Node {
  readonly position: Vector;
  readonly name: string;
}

export interface SSGraph extends Graph {
  readonly v: '1.0.0';
  readonly id: string;
  readonly name: string;
  readonly author: string;
  readonly forkedFrom?: string[];
  readonly nodes: { [id: string]: SSNode };
  readonly edges: { [id: string]: SSEdge };
  readonly outgoingEdges: { [nodeId: string]: string[] };
  readonly incomingEdges: { [nodeId: string]: string[] };
}
