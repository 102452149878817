import React, { FC } from 'react';
import { SectionsContentLayout, P } from '../../components';

export const Tutorials: FC = () => {
  return (
    <SectionsContentLayout
      title="tutorials"
      path="/tutorials"
      sections={[
        {
          id: 'tutorials',
          title: 'Tutorials',
          content: (
            <>
              <P>Lorem ipsum dolor sit amet.</P>
            </>
          ),
        },
        {
          id: 'playing-sound',
          title: 'Playing sound',
          content: (
            <>
              <P>Lorem ipsum dolor sit amet.</P>
            </>
          ),
        },
        {
          id: 'subtractive-synth',
          title: 'Creating a basic subtractive synth voice',
          content: (
            <>
              <P>Lorem ipsum dolor sit amet.</P>
            </>
          ),
        },
        {
          id: 'live-collaboration',
          title: 'Live collaboration',
          content: (
            <>
              <P>Lorem ipsum dolor sit amet.</P>
            </>
          ),
        },
        {
          id: 'audio-nodes',
          title: 'Audio nodes reference',
          content: (
            <>
              <P>ADEnvelopeNode</P>
              <P>AnalyserNode</P>
              <P>AudioBufferSourceNode</P>
              <P>AudioDestinationNode</P>
              <P>BiquadFilterNode</P>
              <P>ChannelMergerNode</P>
              <P>ChannelSplitterNode</P>
              <P>ConstantSourceNode</P>
              <P>ConvolverNode</P>
              <P>DelayNode</P>
              <P>DynamicsCompressorNode</P>
              <P>GainNode</P>
              <P>IIRFilterNode</P>
              <P>MIDICCNode</P>
              <P>MIDIGateNode</P>
              <P>MIDIInputNode</P>
              <P>OscillatorNode</P>
              <P>PannerNode</P>
              <P>PitchShifterNode</P>
              <P>QuantizerNode</P>
              <P>RandomGateNode</P>
              <P>ResonatorNode</P>
              <P>ReverbNode</P>
              <P>RhythmNode</P>
              <P>SampleAndHoldNode</P>
              <P>SequenceNode</P>
              <P>StereoPannerNode</P>
              <P>TriggeredSamplerNode</P>
              <P>WaveShaperNode</P>
              <P>WhiteNoiseNode</P>
            </>
          ),
        },
      ]}
    />
  );
};
