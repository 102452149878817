import React, { FC, useState, useContext } from 'react';
import { Dialog } from './Dialog';
import { FormLinks } from './FormLinks';
import { A } from './A';
import { FormDialog, FormDialogProps } from './FormDialog';
import { Stack } from './Stack';
import { validateEmail, validateUsername, validatePassword } from '../utils';
import { createAccount, createSession, resetPassword } from '../api';
import { RoutingContext, SessionContext } from '../contexts';
import { TAB_ORDER } from '../data';

export type AccountDialogProps = {
  close: () => void;
  initialPage: AccountDialogPage;
};

export type SubDialogProps = {
  close: () => void;
  setPage: (page: AccountDialogPage) => void;
};

export enum AccountDialogPage {
  SignUp,
  SignUpConfirmation,
  SignIn,
  ResetPassword,
  ResetPasswordConfirmation,
}

export const AccountDialog: FC<AccountDialogProps> = ({
  close,
  initialPage,
}) => {
  const [page, setPage] = useState<AccountDialogPage>(initialPage);

  switch (page) {
    case AccountDialogPage.SignIn:
      return <SignInDialog close={close} setPage={setPage} />;
    case AccountDialogPage.SignUp:
      return <SignUpDialog close={close} setPage={setPage} />;
    case AccountDialogPage.ResetPassword:
      return <ResetPasswordDialog close={close} setPage={setPage} />;
    case AccountDialogPage.SignUpConfirmation:
      return <SignUpConfirmationDialog close={close} setPage={setPage} />;
    case AccountDialogPage.ResetPasswordConfirmation:
      return (
        <ResetPasswordConfirmationDialog close={close} setPage={setPage} />
      );
    default:
      throw new Error();
  }
};

type MultiPageFormDialogProps = Omit<FormDialogProps, 'children'> & {
  setPage: (page: AccountDialogPage) => void;
  links: Array<[string, AccountDialogPage]>;
};

export const MultiPageFormDialog: FC<MultiPageFormDialogProps> = ({
  setPage,
  links,
  ...formDialogProps
}) => {
  return (
    <FormDialog {...formDialogProps}>
      <FormLinks>
        {links.map(([text, page], i) => (
          <div>
            <A
              key={i}
              href="#"
              tabIndex={TAB_ORDER.INPUT_SECONDARY}
              onClick={() => setPage(page)}
            >
              {text}
            </A>
          </div>
        ))}
      </FormLinks>
    </FormDialog>
  );
};

export const SignInDialog: FC<SubDialogProps> = ({ setPage, close }) => {
  const { signIn } = useContext(SessionContext);
  const { route } = useContext(RoutingContext);
  return (
    <MultiPageFormDialog
      title="Sign in"
      buttonText="Sign in"
      close={close}
      setPage={setPage}
      onSubmit={async ({ username, password }) => {
        const response = await createSession({ username, password });
        if (response.status >= 400) return response.error;
        const { session } = response.body;
        signIn(session);
        route(`/${username}`);
        close();
        return null;
      }}
      fields={{
        username: {
          label: 'Username',
          validate: validateUsername,
        },
        password: {
          label: 'Password',
          type: 'password',
          validate: validatePassword,
        },
      }}
      links={
        [
          // ['Forgot password', AccountDialogPage.ResetPassword],
          // ['Sign up', AccountDialogPage.SignUp],
        ]
      }
    />
  );
};

export const SignUpDialog: FC<SubDialogProps> = ({ setPage, close }) => {
  return (
    <MultiPageFormDialog
      title="Sign up"
      buttonText="Sign up"
      close={close}
      setPage={setPage}
      onSubmit={async ({ username, email, password }) => {
        const response = await createAccount({ username, email, password });
        if (response.status >= 400) return response.error;
        setPage(AccountDialogPage.SignUpConfirmation);
        return null;
      }}
      fields={{
        username: {
          label: 'Username',
          validate: validateUsername,
        },
        email: {
          label: 'Email address',
          validate: validateEmail,
        },
        password: {
          label: 'Password',
          type: 'password',
          validate: validatePassword,
        },
      }}
      links={[['Sign in', AccountDialogPage.SignIn]]}
    />
  );
};

export const ResetPasswordDialog: FC<SubDialogProps> = ({ setPage, close }) => {
  return (
    <MultiPageFormDialog
      title="Reset password"
      buttonText="Reset password"
      close={close}
      setPage={setPage}
      onSubmit={async ({ username }) => {
        const response = await resetPassword({ username });
        if (response.status >= 400) return response.error;
        setPage(AccountDialogPage.ResetPasswordConfirmation);
        return null;
      }}
      fields={{
        username: {
          label: 'Username',
          validate: validateUsername,
        },
      }}
      links={[['Cancel', AccountDialogPage.SignIn]]}
    />
  );
};

export const SignUpConfirmationDialog: FC<SubDialogProps> = ({ close }) => (
  <Dialog close={close} title="Account created">
    <Stack>
      <p>
        We sent an email with a confirmation link. Please click the link to
        continue.
      </p>
      <p>If it doesn’t arrive, be sure to check your spam folder.</p>
    </Stack>
  </Dialog>
);

export const ResetPasswordConfirmationDialog: FC<SubDialogProps> = ({
  close,
}) => (
  <Dialog close={close} title="Password reset">
    <Stack>
      <p>We sent an email with a reset password link.</p>
      <p>If it doesn’t arrive, be sure to check your spam folder.</p>
    </Stack>
  </Dialog>
);
