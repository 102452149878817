import React, { FC } from 'react';
import { SectionsContentLayout, P } from '../../components';

export const About: FC = () => {
  return (
    <SectionsContentLayout
      title="about"
      path="/about"
      sections={[
        {
          id: 'about',
          title: 'About',
          content: (
            <>
              <P>
                Sinesaw is a web based collabrative musical sketchpad with a
                focus on creative exploration and discovery.
              </P>
            </>
          ),
        },
        {
          id: 'inspiration',
          title: 'Inspiration',
          content: (
            <>
              <P>Lorem ipsum dolor sit amet.</P>
            </>
          ),
        },
        {
          id: 'collaboration',
          title: 'Collaboration',
          content: (
            <>
              <P>
                Collaboration is a core feature of Sinesaw - because Sinesaw is
                web based, projects can be shared via link, forked, and remixed
                with minimal friction.
              </P>
              <P>
                In addition to offline collaboration, multiple users can edit
                the same project at the same time. This kind of workflow is new
                in music production software. In many ways, it enables more
                direct collaboration than would be possible even when sharing a
                studio in person where only one person can use a computer at a
                time.
              </P>
            </>
          ),
        },
        {
          id: 'technical-approach',
          title: 'Technical approach',
          content: (
            <>
              <P>
                Sinesaw aims to build on and compliment the native web audio
                graph implemented by all major web browsers. This graph is
                powerful and flexible and provides a great foundation for
                creating music. Sinesaw adds a UI to easily build and edit audio
                graphs using browser native audio nodes, as well as an extended
                set of nodes useful for sound design and sequencing.
              </P>
              <P>
                Control signals in Sinesaw are continuous and interchangable
                with audio signals. This is inspired by analog modular
                synthesis, and allows any output of any node to be routed to any
                input or parameter.
              </P>
              <P>
                This approach leaves some common features of DAWs out of reach.
                In Sinesaw there is no piano roll, no audio or midi tracks, and
                no linear timeline. Instead, Sinesaw invites creative modulation
                of control signals and generative but
              </P>
            </>
          ),
        },
      ]}
    />
  );
};
