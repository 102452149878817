import React, { FC, useContext, memo } from 'react';
import { ActionContext } from '../contexts';
import { SSNode, UIType } from '../../../types';
import { ParamType, SeededNode } from 'helicon';
import { ValueInput } from './inputs';
import { NODE_DESCRIPTIONS, TAB_ORDER } from '../../../data';
import {
  Label,
  FocusLoop,
  EditableText,
  GridLayout,
  Col,
} from '../../../components';
import { Value } from 'helicon';
import { inputWidth, lineHeight, strokeWidth } from '../../../style';
import { EuclideanRhythmVisualization } from './EuclideanRhythmVisualization';
import { ComponentType } from 'react';

export type NodeSelectionInfoProps = {
  node: SSNode;
  seededNode: SeededNode;
};

const DECORATIONS: { [key: string]: ComponentType<NodeSelectionInfoProps> } = {
  EuclideanRhythmNode: EuclideanRhythmVisualization,
};

export const NodeSelectionInfo: FC<NodeSelectionInfoProps> = memo(
  ({ node, node: { id, type, params, name }, seededNode }) => {
    const { updateNode, updateNodeData } = useContext(ActionContext);
    const description = NODE_DESCRIPTIONS[type];
    const update = (name: string) => (next: Value) =>
      updateNode(id, { ...params, [name]: next });

    const DecarationComponent = DECORATIONS[node.type];

    return (
      <FocusLoop spliceID={id}>
        <Col gap={1} align="stretch">
          <div>
            <EditableText
              text={name}
              onChange={name => {
                updateNodeData(id, { name });
              }}
              tabIndex={TAB_ORDER.INPUT}
            />
            <div style={{ color: 'var(--text-secondary-color)' }}>
              {description.displayName}
            </div>
          </div>
          {DecarationComponent && (
            <DecarationComponent node={node} seededNode={seededNode} />
          )}
          <GridLayout minSize={inputWidth + 2 * (lineHeight + strokeWidth)}>
            {Object.entries(description.params).map(([name, param]) => {
              if (param.uiType === UIType.Hidden) return null;

              const value = node.params[name];
              const seededValue = seededNode.params[name];

              return (
                <Col key={name} align="stretch">
                  <Label htmlFor={name}>
                    {param.displayName}
                    {(param as any).unit ? ` (${(param as any).unit})` : ''}
                  </Label>
                  <ValueInput
                    value={value}
                    seededValue={seededValue}
                    onChange={update(name)}
                    node={node}
                    nodeDescription={description}
                    paramDescription={param}
                    id={name}
                    disabled={
                      value !== null &&
                      param.type === ParamType.AudioBuffer &&
                      param.writeOnce
                    }
                  />
                </Col>
              );
            })}
          </GridLayout>
        </Col>
      </FocusLoop>
    );
  },
);
