import { AudioNodeParams } from 'helicon';
import {
  SSIO,
  Activity,
  Target,
  SSGraph,
  SSNode,
  Rect,
  Vector,
} from '../../../types';

export enum ActionType {
  // commands
  Copy = 'Copy',
  Paste = 'Paste',

  // interaction
  SetCursorPosition = 'SetCursorPosition',
  SetActivity = 'SetActivity',
  PerformActivity = 'PerformActivity',
  SetFocusTarget = 'SetFocusTarget',
  SetHoverTarget = 'SetHoverTarget',
  SetSelection = 'SetSelection',

  // graph
  SetGraph = 'SetGraph',
  UpdateGraphMetadata = 'UpdateGraphMetadata',
  AddNode = 'AddNode',
  RemoveNode = 'RemoveNode',
  UpdateNodeData = 'UpdateNodeData',
  UpdateNode = 'UpdateNode',
  Connect = 'Connect',
  Disconnect = 'Disconnect',
  DisconnectIO = 'DisconnectIO',
  DeleteSelection = 'DeleteSelection',
  TranslateSelection = 'TranslateSelection',

  // layout and grid
  SetDOMRect = 'SetDOMRect',
  SetViewport = 'SetViewport',
  PanViewport = 'PanViewport',
  ZoomViewport = 'ZoomViewport',
  SetLayoutSize = 'SetLayoutSize',
  SetRightColumnSize = 'SetRightColumnSize',
  SetRightColumnIsOpen = 'SetRightColumnIsOpen',
}

export type Action =
  | CopyAction
  | PasteAction
  | SetCursorPositionAction
  | SetActivityAction
  | PerformActivityAction
  | SetFocusTargetAction
  | SetHoverTargetAction
  | SetSelectionAction
  | SetGraphAction
  | UpdateGraphMetadataAction
  | AddNodeAction
  | RemoveNodeAction
  | UpdateNodeAction
  | UpdateNodeDataAction
  | ConnectAction
  | DisconnectAction
  | DisconnectIOAction
  | DeleteSelectionAction
  | TranslateSelectionAction
  | SetDOMRectAction
  | SetViewportAction
  | PanViewportAction
  | ZoomViewportAction
  | SetLayoutSizeAction
  | SetRightColumnSizeAction
  | SetRightColumnIsOpenAction;

export type CopyAction = {
  type: ActionType.Copy;
};
export type PasteAction = {
  type: ActionType.Paste;
  position?: Vector;
  seed: number;
};
export type SetCursorPositionAction = {
  type: ActionType.SetCursorPosition;
  position: Vector | undefined;
};
export type SetActivityAction = {
  type: ActionType.SetActivity;
  activity: Activity | undefined;
};
export type PerformActivityAction = {
  type: ActionType.PerformActivity;
  activity: Activity;
  seed: number;
};
export type SetFocusTargetAction = {
  type: ActionType.SetFocusTarget;
  target: Target | undefined;
};
export type SetHoverTargetAction = {
  type: ActionType.SetHoverTarget;
  target: Target | undefined;
};
export type SetSelectionAction = {
  type: ActionType.SetSelection;
  selection: Target[] | undefined;
};
export type SetGraphAction = {
  type: ActionType.SetGraph;
  graph: SSGraph;
};
export type UpdateGraphMetadataAction = {
  type: ActionType.UpdateGraphMetadata;
  data: { name?: string; forkedFrom?: string[] };
};
export type AddNodeAction = {
  type: ActionType.AddNode;
  node: SSNode;
};
export type RemoveNodeAction = {
  type: ActionType.RemoveNode;
  id: string;
};
export type UpdateNodeDataAction = {
  type: ActionType.UpdateNodeData;
  id: string;
  data: { name?: string; position?: Vector };
};
export type UpdateNodeAction = {
  type: ActionType.UpdateNode;
  id: string;
  params: AudioNodeParams;
};
export type ConnectAction = {
  type: ActionType.Connect;
  from: SSIO;
  to: SSIO;
  seed: number;
};
export type DisconnectAction = {
  type: ActionType.Disconnect;
  id: string;
};
export type DisconnectIOAction = {
  type: ActionType.DisconnectIO;
  io: SSIO;
};
export type DeleteSelectionAction = {
  type: ActionType.DeleteSelection;
};
export type TranslateSelectionAction = {
  type: ActionType.TranslateSelection;
  delta: Vector;
};
export type SetDOMRectAction = {
  type: ActionType.SetDOMRect;
  domRect: Rect;
};
export type SetViewportAction = {
  type: ActionType.SetViewport;
  viewport: Rect;
};
export type PanViewportAction = {
  type: ActionType.PanViewport;
  delta: Vector;
};
export type ZoomViewportAction = {
  type: ActionType.ZoomViewport;
  center: Vector;
  amount: number;
};
export type SetLayoutSizeAction = {
  type: ActionType.SetLayoutSize;
  size: Vector;
};
export type SetRightColumnSizeAction = {
  type: ActionType.SetRightColumnSize;
  size: number;
};
export type SetRightColumnIsOpenAction = {
  type: ActionType.SetRightColumnIsOpen;
  isOpen: boolean;
};

export const copy = (): CopyAction => ({ type: ActionType.Copy });
export const paste = (position?: Vector): PasteAction => ({
  type: ActionType.Paste,
  position,
  seed: Math.random(),
});
export const setCursorPosition = (
  position: Vector | undefined,
): SetCursorPositionAction => ({
  type: ActionType.SetCursorPosition,
  position,
});
export const setActivity = (
  activity: Activity | undefined,
): SetActivityAction => ({
  type: ActionType.SetActivity,
  activity,
});
export const performActivity = (activity: Activity): PerformActivityAction => ({
  type: ActionType.PerformActivity,
  activity,
  seed: Math.random(),
});
export const setFocusTarget = (
  target: Target | undefined,
): SetFocusTargetAction => {
  if ((window as any).crash) throw new Error();
  return {
    type: ActionType.SetFocusTarget,
    target,
  };
};
export const setHoverTarget = (
  target: Target | undefined,
): SetHoverTargetAction => {
  if ((window as any).crash) throw new Error();
  return {
    type: ActionType.SetHoverTarget,
    target,
  };
};
export const setSelection = (
  selection: Target[] | undefined,
): SetSelectionAction => ({
  type: ActionType.SetSelection,
  selection,
});
export const setGraph = (graph: SSGraph): SetGraphAction => ({
  type: ActionType.SetGraph,
  graph,
});
export const updateGraphMetadata = (data: {
  name?: string;
  forkedFrom?: string[];
}): UpdateGraphMetadataAction => ({
  type: ActionType.UpdateGraphMetadata,
  data,
});
export const addNode = (node: SSNode): AddNodeAction => ({
  type: ActionType.AddNode,
  node,
});
export const removeNode = (id: string): RemoveNodeAction => ({
  type: ActionType.RemoveNode,
  id,
});
export const updateNodeData = (
  id: string,
  data: { name?: string; position?: Vector },
): UpdateNodeDataAction => ({
  type: ActionType.UpdateNodeData,
  id,
  data,
});
export const updateNode = (
  id: string,
  params: AudioNodeParams,
): UpdateNodeAction => ({
  type: ActionType.UpdateNode,
  id,
  params,
});
export const connect = (from: SSIO, to: SSIO): ConnectAction => ({
  type: ActionType.Connect,
  from,
  to,
  seed: Math.random(),
});
export const disconnect = (id: string): DisconnectAction => ({
  type: ActionType.Disconnect,
  id,
});
export const disconnectIO = (io: SSIO): DisconnectIOAction => ({
  type: ActionType.DisconnectIO,
  io,
});
export const deleteSelection = (): DeleteSelectionAction => ({
  type: ActionType.DeleteSelection,
});
export const translateSelection = (
  delta: Vector,
): TranslateSelectionAction => ({
  type: ActionType.TranslateSelection,
  delta,
});
export const setDOMRect = (domRect: Rect): SetDOMRectAction => ({
  type: ActionType.SetDOMRect,
  domRect,
});
export const setViewport = (viewport: Rect): SetViewportAction => ({
  type: ActionType.SetViewport,
  viewport,
});
export const panViewport = (delta: Vector): PanViewportAction => ({
  type: ActionType.PanViewport,
  delta,
});
export const zoomViewport = (
  center: Vector,
  amount: number,
): ZoomViewportAction => ({
  type: ActionType.ZoomViewport,
  center,
  amount,
});
export const setLayoutSize = (size: Vector): SetLayoutSizeAction => ({
  type: ActionType.SetLayoutSize,
  size,
});
export const setRightColumnSize = (size: number): SetRightColumnSizeAction => ({
  type: ActionType.SetRightColumnSize,
  size,
});
export const setRightColumnIsOpen = (
  isOpen: boolean,
): SetRightColumnIsOpenAction => ({
  type: ActionType.SetRightColumnIsOpen,
  isOpen,
});
