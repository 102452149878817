import React, { Fragment, FC, useContext } from 'react';
import styled from 'styled-components';
import { strokeWidth } from '../../../style';
import {
  GraphContext,
  ActivityContext,
  HoverTargetContext,
  SelectionContext,
} from '../contexts';
import { TargetType } from '../../../types';
import { getIOCenterPosition, getUserColor, add } from '../../../utils';
import { SessionContext } from '../../../contexts';

const Line = styled.line`
  stroke-width: 0.2;
  stroke-linecap: round;
  pointer-events: none;
`;
const NonScalingLine = styled(Line)`
  stroke-width: ${2 * strokeWidth};
  vector-effect: non-scaling-stroke;
`;

export type EdgeHoverTargetUIProps = {};

export const EdgeHoverTargetUI: FC<EdgeHoverTargetUIProps> = () => {
  const { session } = useContext(SessionContext);
  const { graph } = useContext(GraphContext);
  const hoverTargets = useContext(HoverTargetContext);
  const activities = useContext(ActivityContext);
  const { selections } = useContext(SelectionContext);

  // const targets: [
  //   Target | undefined,
  //   Target[] | undefined,
  //   boolean,
  //   string,
  //   string,
  // ][] = [
  //   [
  //     own,
  //     selection.own,
  //     activity.own === undefined,
  //     'var(--outline-color)',
  //     '!',
  //   ],
  //   ...Object.entries(peers).map(([username, target]): [
  //     Target | undefined,
  //     Target[] | undefined,
  //     boolean,
  //     string,
  //     string,
  //   ] => [
  //     target,
  //     selection.peers[username],
  //     activity.peers[username] === undefined,
  //     getUserColor(username),
  //     username,
  //   ]),
  // ];

  return (
    <>
      {Object.entries(hoverTargets).map(([username, hoverTarget]) => {
        const selection = selections[username];
        const activity = activities[username];
        const color =
          username === session?.username
            ? 'var(--outline-color)'
            : getUserColor(username);

        if (
          activity === undefined &&
          hoverTarget?.type === TargetType.Edge &&
          !selection?.some(
            t => t.type === TargetType.Edge && t.id === hoverTarget.id,
          )
        ) {
          const { from, to } = graph.edges[hoverTarget.id];
          const fromNode = graph.nodes[from.node];
          const toNode = graph.nodes[to.node];
          const [x1, y1] = add(
            fromNode.position,
            getIOCenterPosition(fromNode, from),
          );
          const [x2, y2] = add(
            toNode.position,
            getIOCenterPosition(toNode, to),
          );

          return (
            <Fragment key={username}>
              <Line x1={x1} x2={x2} y1={y1} y2={y2} stroke={color} />
              <NonScalingLine x1={x1} x2={x2} y1={y1} y2={y2} stroke={color} />
            </Fragment>
          );
        }
        return null;
      })}
    </>
  );
};
