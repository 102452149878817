import { API_URL } from '../config';

export type Response<Body = void> = Promise<{
  status: number;
  body: Body;
  error: string | null;
}>;

export const request = async <RequestBody, ResponseBody>(
  path: string,
  body: RequestBody,
): Response<ResponseBody> => {
  const response = await fetch(`${API_URL}${path}`, {
    method: 'POST',
    body: body && JSON.stringify(body),
    headers: {
      'Accept-Encoding': 'br, gzip',
    },
  });
  return {
    status: response.status,
    body: response.status === 200 ? await response.json() : null,
    error: response.status >= 400 ? await response.text() : null,
  };
};
