import React, { FC } from 'react';
import { fixed } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { EnumInput } from './EnumInput';

export const EnumFixedValueInput: FC<ValueInputProps<any[]>> = ({
  id,
  tabIndex,
  seededValue,
  onChange,
  disabled,
  paramDescription,
}) => {
  return (
    <EnumInput
      id={id}
      tabIndex={tabIndex}
      disabled={disabled}
      value={seededValue}
      onChange={value => onChange(fixed(value))}
      options={paramDescription.options ?? []}
    />
  );
};
