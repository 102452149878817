import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { SSNode } from '../../../types';
import { AudioGraphContext } from '../contexts';
import { SeededNode } from 'helicon';
import { strokeWidth } from '../../../style';
import { useState } from 'react';
import { useAnimationFrameEffect } from '../../../hooks';
import { generateEuclideanSequence } from '../../../audioUtils/generateEuclideanSequence';

const SVG = styled.svg`
  margin: 0 auto;
`;
const Circle = styled.circle<{ active: boolean; on: boolean }>`
  fill: ${({ on, active }) =>
    on
      ? active
        ? 'var(--visualization-stroke-color)'
        : 'var(--visualization-secondary-stroke-color)'
      : 'var(--background-color)'};
  stroke: ${({ on, active }) =>
    on
      ? active
        ? 'var(--visualization-stroke-color)'
        : 'var(--visualization-secondary-stroke-color)'
      : active
      ? 'var(--text-secondary-color)'
      : 'var(--visualization-color)'};
  stroke-width: ${strokeWidth};
  vector-effect: non-scaling-stroke;
`;

export type EuclideanRhythmVisualizationProps = {
  node: SSNode;
  seededNode: SeededNode;
};

export const EuclideanRhythmVisualization: FC<
  EuclideanRhythmVisualizationProps
> = ({ node, seededNode }) => {
  const { audioGraph } = useContext(AudioGraphContext);
  const [state, setState] = useState<{
    sequence: number[];
    position: number;
    rotation: number;
  }>(() => ({
    sequence: generateEuclideanSequence(
      seededNode.params.length,
      seededNode.params.pulses,
    ),
    position: -1,
    rotation: seededNode.params.rotation,
  }));

  useAnimationFrameEffect(() => {
    const audioNode = audioGraph.audioNodes[node.id];
    let position, sequence, rotation;
    if (audioNode && audioGraph.playing) {
      ({ position, sequence, rotation } = audioNode.signals as {
        [name: string]: any;
      });
    } else {
      position = -1;
      sequence = generateEuclideanSequence(
        seededNode.params.length,
        seededNode.params.pulses,
      );
      rotation = seededNode.params.rotation;
    }

    if (
      state.sequence !== sequence ||
      state.position !== position ||
      state.rotation !== rotation
    ) {
      setState({ sequence, position, rotation });
    }
  }, [audioGraph, state]);

  if (state) {
    const { sequence, position, rotation } = state;
    const len = sequence.length;
    const points = new Array(len);
    const step = (Math.PI * 2) / len;
    for (let i = 0; i < len; i++) {
      const angle = i * step;
      const x = 0.5 + 0.5 * Math.sin(angle - (0 * Math.PI) / 2);
      const y = 0.5 - 0.5 * Math.cos(angle - (0 * Math.PI) / 2);
      points[i] = [x, y];
    }

    const r = Math.min(
      0.08,
      Math.PI / len / 2 - (2 * strokeWidth) / ((Math.PI * 120) / 1.4),
    );

    return (
      <SVG viewBox="-0.2 -0.2 1.4 1.4" width={120} height={120}>
        {points.map(([x, y], i) => (
          <Circle
            key={i}
            cx={x}
            cy={y}
            r={r}
            active={position === i}
            on={sequence[(i + rotation) % len] > 0}
          />
        ))}
      </SVG>
    );
  }

  return <svg viewBox="-0.1 -0.1 1.2 1.2" />;
};
