import { useRef, useEffect, Ref } from 'react';

export const useCombinedRefs = <T>(...refs: Ref<T>[]): [Ref<T>, T | null] => {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        // @ts-ignore
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return [
    current => {
      // @ts-ignore
      targetRef.current = current;

      refs.forEach(ref => {
        if (!ref) return;
        if (typeof ref === 'function') {
          ref(current);
        } else {
          // @ts-ignore
          ref.current = current;
        }
      });
    },
    targetRef.current,
  ];
};
