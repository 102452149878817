import React, { FC, useContext } from 'react';
import {
  GridLayout,
  Stack,
  UserTag,
  FormLinks,
  Link,
  SectionsLayout,
  BasicHeader,
} from '../../../components';
import { ProjectPreview } from './ProjectPreview';
import { NewProjectLink } from './NewProjectLink';
import { Account, GraphThumbnail } from '../../../types';
import { RoutingContext, SessionContext } from '../../../contexts';
import { createGraph } from '../../../api';
import { useBlockingAPIAction } from '../../../hooks';
import { TAB_ORDER } from '../../../data';

export type ProfileLayoutProps = {
  graphs: GraphThumbnail[];
  account: Account;
};

export const ProfileLayout: FC<ProfileLayoutProps> = ({
  graphs,
  account: { username, bio },
}) => {
  const { session } = useContext(SessionContext);
  const { route } = useContext(RoutingContext);
  const blockingCreateGraph = useBlockingAPIAction(createGraph);

  return (
    <SectionsLayout
      breadcrumbs={
        <>
          <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            sinesaw
          </Link>
          {' / '}
          <Link plain href={`/${username}`} tabIndex={TAB_ORDER.HEADER_BUTTON}>
            {username}
          </Link>
        </>
      }
      sections={[
        {
          id: 'profile',
          title: 'Profile',
          content: (
            <>
              <BasicHeader>Profile</BasicHeader>
              <Stack>
                <UserTag username={username} />
                {bio && <p>{bio}</p>}
              </Stack>
              {username === session?.username && (
                <FormLinks>
                  <Link href="/account-settings">edit profile</Link>
                </FormLinks>
              )}
            </>
          ),
        },
        {
          id: 'projects',
          title: 'Projects',
          content: (
            <>
              <BasicHeader>Projects</BasicHeader>
              <GridLayout>
                {graphs.map(graph => (
                  <ProjectPreview graph={graph} key={graph.id} />
                ))}
                {session && username === session.username && (
                  <NewProjectLink
                    onClick={async () => {
                      const response = await blockingCreateGraph({ session });
                      if (response.status === 200) {
                        route(`/${response.body}`);
                      }
                    }}
                  />
                )}
              </GridLayout>
            </>
          ),
        },
      ]}
    />
  );
};
