import { SSGraph } from '../../../types';
import { getCenteredViewport, graphBounds } from '../../../utils';
import {
  meterWidth,
  lineHeight,
  columnPadding,
  columnHandleWidth,
} from '../../../style';
import { UserState } from './State';

export const initialUserState = (graph: SSGraph): UserState => ({
  interaction: {
    cursorPosition: undefined,
    activity: undefined,
    focusTarget: undefined,
    hoverTarget: undefined,
    selection: [],
    clipboard: undefined,
  },
  grid: {
    domRect: [
      [0, 0],
      [0, 0],
    ],
    viewport: getCenteredViewport(
      graphBounds(graph),
      Object.keys(graph.nodes).length === 0,
      [3, 2],
      1.2,
    ),
  },
  layout: {
    rightColumnSize:
      2 * meterWidth + 1.5 * lineHeight + 2 * columnPadding + columnHandleWidth,
    rightColumnIsOpen: true,
  },
});
