import React, { FC, useState, useEffect } from 'react';
import { Input } from '../../../../components';
import { TAB_ORDER } from '../../../../data';

export type FloatInputProps = {
  id?: string;
  disabled?: boolean;
  tabIndex?: number;
  autoFocus?: boolean;
  name?: string;
  value: number;
  onChange: (value: number) => void;
  highlighted?: boolean;
  className?: string;
  truncateDigits?: number;
  min?: number;
  max?: number;
};

const pattern = /^-?([0-9]*[\.\/]?[0-9]*(e[0-9]+)?)?$/;
const parse = (value: string): number => {
  if (value.match(/[0-9]\/[0-9]/)) {
    const [numerator, denominator] = value.split('/');
    return parseInt(numerator) / parseInt(denominator);
  } else {
    return parseFloat(value);
  }
};

export const FloatInput: FC<FloatInputProps> = ({
  tabIndex = TAB_ORDER.INPUT,
  autoFocus,
  name,
  id,
  disabled,
  value,
  highlighted,
  onChange,
  className,
  truncateDigits = 6,
  min = -Infinity,
  max = Infinity,
}) => {
  const [textValue, setTextValue] = useState(`${value}`);
  const [lastUpdatedValue, setLastUpdatedValue] = useState(value);

  useEffect(() => {
    if (value !== lastUpdatedValue) {
      setLastUpdatedValue(value);
      setTextValue(`${value}`);
    }
  });

  return (
    <Input
      fullWidth
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      name={name}
      id={id}
      disabled={disabled}
      value={textValue}
      highlighted={highlighted}
      onChange={({ target: { value } }) => {
        if (!value.match(pattern)) return;
        setTextValue(value);

        const parsed = Math.min(Math.max(min, parse(value)), max);
        const truncFactor = Math.pow(10, truncateDigits);
        const truncated = Math.trunc(parsed * truncFactor) / truncFactor;
        if (!Number.isNaN(truncated) && Number.isFinite(truncated)) {
          setLastUpdatedValue(truncated);
          onChange(truncated);
        }
      }}
      onBlur={() => setTextValue(`${value}`)}
      className={className}
    />
  );
};
