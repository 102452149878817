import React, { FC, ReactNode, useState } from 'react';
import { Dialog } from './Dialog';
import { ValidatedForm } from './ValidatedForm';
import { LoadingIndicator } from './LoadingIndicator';
import { Shade } from './Shade';

export type FormDialogProps = {
  close: () => void;
  onSubmit: (values: { [name: string]: string }) => Promise<string | null>;
  title: string;
  buttonText: string;
  fields: {
    [name: string]: {
      label: string;
      type?: string;
      pattern?: RegExp;
      options?: { [value: string]: string };
      validate?: (
        value: string,
        body: { [key: string]: string },
      ) => string | null;
    };
  };
  layout?: string[][];
  children?: ReactNode;
  initialValues?: { [name: string]: string };
  renderInfo?: (values: { [name: string]: string }) => ReactNode;
};

export const FormDialog: FC<FormDialogProps> = ({
  close,
  onSubmit,
  title,
  buttonText,
  fields,
  children,
  layout,
  initialValues,
  renderInfo,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog title={title} close={loading ? undefined : close}>
      <ValidatedForm
        onSubmit={onSubmit}
        loading={loading}
        setLoading={setLoading}
        buttonText={buttonText}
        fields={fields}
        layout={layout}
        initialValues={initialValues}
        renderInfo={renderInfo}
      />
      {children}
      {loading && (
        <>
          <Shade />
          <LoadingIndicator />
        </>
      )}
    </Dialog>
  );
};
