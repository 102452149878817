import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Oscilloscope } from './Oscilloscope';
import { PeakMeter } from './PeakMeter';
import { FFT } from './FFT';
import { useResizeObserver } from '../../../hooks';
import { lineHeight, meterWidth } from '../../../style';
import { Rect } from '../../../types';
import { useContext } from 'react';
import { AudioGraphContext } from '../contexts';

export type MetersProps = {};

const Container = styled.div`
  flex: 0;
  justify-content: flex-end;
  display: flex;
  > *:nth-child(n + 2) {
    margin-left: ${lineHeight / 2}px;
  }
`;

export const Meters: FC<MetersProps> = () => {
  const {
    audioGraph: { analyser, context },
  } = useContext(AudioGraphContext);
  const [width, setWidth] = useState(0);
  const container = useResizeObserver<HTMLDivElement>(([, [width]]: Rect) =>
    setWidth(width),
  );

  return (
    <Container ref={container}>
      {width >= 2 * meterWidth + 1.5 * lineHeight && (
        <FFT
          size={[meterWidth, 1.5 * lineHeight]}
          truncate={meterWidth}
          stepDivisor={2}
          frameDivisor={2}
          analyser={analyser}
          context={context}
        />
      )}
      {width >= meterWidth + 1 * lineHeight && (
        <Oscilloscope
          size={[meterWidth, 1.5 * lineHeight]}
          truncate={2 * meterWidth}
          stepDivisor={2}
          analyser={analyser}
          context={context}
        />
      )}
      {width >= 0.5 * lineHeight && (
        <PeakMeter
          size={[0.5 * lineHeight, 1.5 * lineHeight]}
          analyser={analyser}
          context={context}
        />
      )}
    </Container>
  );
};
