import {
  ProbabilityDistribution,
  ProbabilityDistributionType,
  UniformDistribution,
  GaussianDistribution,
  ExponentialDistribution,
} from 'helicon';
import { uniform, exponential, gaussian } from 'helicon/build';

const PROBABILITY_DISTRIBUTION_CONVERSION_TABLE: {
  [fromType in ProbabilityDistributionType]: {
    [toType in ProbabilityDistributionType]: (
      d: any,
    ) => ProbabilityDistribution;
  };
} = {
  [ProbabilityDistributionType.Uniform]: {
    [ProbabilityDistributionType.Gaussian]: ({
      min,
      max,
    }: UniformDistribution) => gaussian((min + max) / 2, (max - min) / 4),
    [ProbabilityDistributionType.Exponential]: ({
      min,
      max,
    }: UniformDistribution) => exponential(min, max, 0),
    [ProbabilityDistributionType.Uniform]: v => v,
  },
  [ProbabilityDistributionType.Gaussian]: {
    [ProbabilityDistributionType.Uniform]: ({
      mean,
      variance,
    }: GaussianDistribution) =>
      uniform(mean - 2 * variance, mean + 2 * variance),
    [ProbabilityDistributionType.Exponential]: ({
      mean,
      variance,
    }: GaussianDistribution) =>
      exponential(mean - 2 * variance, mean + 2 * variance, 0),
    [ProbabilityDistributionType.Gaussian]: v => v,
  },
  [ProbabilityDistributionType.Exponential]: {
    [ProbabilityDistributionType.Uniform]: ({
      min,
      max,
    }: ExponentialDistribution) => uniform(min, max),
    [ProbabilityDistributionType.Gaussian]: ({
      min,
      max,
    }: ExponentialDistribution) => gaussian((min + max) / 2, (max - min) / 4),
    [ProbabilityDistributionType.Exponential]: v => v,
  },
};

export const convertProbabilityDistribution = (
  distribution: ProbabilityDistribution,
  toType: ProbabilityDistributionType,
): ProbabilityDistribution => {
  const targets = PROBABILITY_DISTRIBUTION_CONVERSION_TABLE[distribution.type];
  const converter = targets[toType];
  return converter(distribution);
};
