import styled from 'styled-components';
import { maxTextContentWidth } from '../style';

export const P = styled.p`
  max-width: ${maxTextContentWidth}px;
  font-size: 1.25em;
  line-height: 1.25em;
  color: var(--text-color);
  user-select: text;
`;
