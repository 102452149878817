import React, { FC } from 'react';
import styled from 'styled-components';
import {
  H1,
  // H2,
  // P,
  Toolbar,
  ToolbarGroup,
  SessionInfo,
  // GridLayout,
  // Link,
  // Footer,
} from '../../components';
// import { nofanaf, nevevon, vororeme } from '../../demos';
import { lineHeight, maxBlockContentWidth } from '../../style';
// import { graphToThumbnail } from '../../utils';
// import { ProjectPreview } from '../profile/components/ProjectPreview';
// import {
//   ModularEnvironmentAnimation,
//   LiveCollaborationAnimation,
//   WebNativeAnimation,
//   HighPerformanceAnimation,
// } from './components';

export type HomeProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
  min-width: 320px;
  overflow: auto;
`;

const MainContent = styled.div`
  display: flex;
  flex-flow: column;
  padding-bottom: ${10 * lineHeight}px;
  max-width: ${maxBlockContentWidth}px;
  align-self: center;
  align-itmes: stretch;
`;

const ResponsiveH1 = styled(H1)`
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    font-size: 4em;
  }
`;

const Block = styled.div`
  margin-bottom: ${4 * lineHeight}px;
  padding: 0 ${lineHeight}px;
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    max-width: 400px;
  }
`;

// const Row = styled(Block)`
//   display: flex;
//   & > :first-child {
//     margin-right: ${2 * lineHeight}px;
//   }
//   @media only screen and (max-width: ${maxBlockContentWidth}px) {
//     flex-flow: column;
//     & > :first-child {
//       margin-right: 0;
//     }
//   }
// `;

// const RowReverse = styled(Row)`
//   @media only screen and (max-width: ${maxBlockContentWidth}px) {
//     flex-flow: column-reverse;
//   }
// `;

// const Illustration = styled.div`
//   flex: 4;
// `;

// const Description = styled.div`
//   flex: 3;
// `;

export const Home: FC<HomeProps> = () => {
  return (
    <Container>
      <Toolbar>
        <div />
        <ToolbarGroup>
          <SessionInfo />
        </ToolbarGroup>
      </Toolbar>
      <MainContent>
        <Block>
          <ResponsiveH1>SINESAW</ResponsiveH1>
          {/* <P>Sinesaw is a web based collabrative musical sketchpad.</P>
          <P>
            It has a focus on creative exploration and discovery, aiming to
            break free of traditional ideas of pitch, rhythm, and song structure
            and to encourage users to create something unique and new.
          </P> */}
        </Block>
        {/* <Row>
          <Illustration>
            <ModularEnvironmentAnimation />
          </Illustration>
          <Description>
            <H2>Modular environment</H2>
            <P>
              Combine simple building blocks like oscillators, filters,
              samplers, MIDI input, and more to create complex and unique
              instruments, effects, and sequences.
            </P>
          </Description>
        </Row>
        <RowReverse>
          <Description>
            <H2>Live collaboration</H2>
            <P>Work together with friends in real time</P>
            <P>
              Changes to projects are live while collaborators each control
              local playback independently
            </P>
          </Description>
          <Illustration>
            <LiveCollaborationAnimation />
          </Illustration>
        </RowReverse>
        <Row>
          <Illustration>
            <HighPerformanceAnimation />
          </Illustration>
          <Description>
            <H2>High performance</H2>
            <P>
              Sinesaw uses WebAssembly to run audio processing code ported from
              open source plugins and hardware directly in the browser with the
              performance of a fully native app
            </P>
          </Description>
        </Row>
        <RowReverse>
          <Description>
            <H2>Web native</H2>
            <P>
              Sinesaw is built on web audio standards, allowing you to work on
              your projects from any computer with a web browser
            </P>
            <P>
              Share with collaborators via link, allowing them to fork and
              modify your work
            </P>
          </Description>
          <Illustration>
            <WebNativeAnimation />
          </Illustration>
        </RowReverse>
        <Block>
          <H2>Listen</H2>
          <GridLayout>
            {[nofanaf, nevevon, vororeme].map(graph => (
              <ProjectPreview
                graph={{
                  id: graph.id,
                  name: graph.name,
                  createdAt: Date.now(),
                  data: graphToThumbnail(graph),
                }}
                key={graph.id}
              />
            ))}
          </GridLayout>
          <P>
            Explore these demos, along with the hundreds of other public
            projects created by sinesaw users.
          </P>
          <P>
            <Link href="#">More project highlights</Link>
          </P>
        </Block>
        <Block>
          <H2>More</H2>
          <Footer />
        </Block> */}
      </MainContent>
    </Container>
  );
};
