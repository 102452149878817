import { graphToStorage, randomID, request, Response } from '../utils';
import { Session, SSGraph } from '../types';

export const forkGraph = async ({
  session,
  data,
}: {
  session: Session;
  data: SSGraph;
}): Response<string | null> => {
  const id = `${session.username}/${randomID()}`;
  const response = await request('update-graph', {
    session,
    data: graphToStorage({
      ...data,
      id,
      author: session.username,
      forkedFrom: [...(data.forkedFrom ?? []), id],
    }),
  });
  if (response.status !== 204) {
    return {
      error: response.error,
      status: response.status,
      body: null,
    };
  }
  return {
    error: null,
    status: 200,
    body: id,
  };
};
