import React, { FC } from 'react';
import { ValueInputProps } from './ValueInput';
import { ArrayInput } from './ArrayInput';
import { weightedRandomSequence } from 'helicon/build';
import { WeightedRandomSequenceValue } from 'helicon';
import { WeightInput } from './WeightInput';
import { Col, Row, Label, InfoText } from '../../../../components';
import { lineHeight } from '../../../../style';
import { usePosition } from '../../hooks';
import { ReactElement } from 'react';
import { ProbabilityDistributionInput } from './ProbabilityDistributionInput';

type ArrayWeightedRandomSequenceValueInputProps<T> = ValueInputProps<T[]> & {
  Component: FC<{
    id?: string;
    disabled?: boolean;
    value: T;
    min?: number;
    max?: number;
    onChange: (value: T) => void;
    highlighted?: boolean;
  }>;
};

export const ArrayWeightedRandomSequenceValueInput = <T extends any>({
  id,
  value,
  seededValue,
  paramDescription,
  paramDescription: { min, max },
  disabled,
  onChange,
  node,
  Component,
}: ArrayWeightedRandomSequenceValueInputProps<T>): ReactElement => {
  const position = usePosition(node.id);
  const { items, length } = value as WeightedRandomSequenceValue<number>;
  const positionIndex =
    position && items.findIndex(([v]) => v === seededValue[position]);

  return (
    <Col gap={1} align="stretch">
      <div>
        <Row>
          <Label style={{ flex: 1 }}>value</Label>
          <Label style={{ width: 5.5 * lineHeight }}>weight</Label>
        </Row>
        <ArrayInput
          id={id}
          disabled={disabled}
          value={(value as WeightedRandomSequenceValue).items}
          onChange={items =>
            onChange(
              weightedRandomSequence(items as [number, number][], length),
            )
          }
          defaultItem={[0, 1]}
          renderItemInput={(v, i, onChangeItem) => (
            <>
              <Component
                disabled={disabled}
                value={v[0]}
                onChange={nv => onChangeItem([nv, v[1]])}
                highlighted={i === positionIndex}
                min={min}
                max={max}
              />
              <WeightInput
                disabled={disabled}
                value={v[1]}
                onChange={nv => onChangeItem([v[0], nv])}
              />
            </>
          )}
        />
      </div>
      <div />
      <Label style={{ margin: 0 }}>Length</Label>
      <ProbabilityDistributionInput
        id={id}
        value={length}
        seededValue={seededValue.length}
        paramDescription={paramDescription}
        onChange={nv => onChange(weightedRandomSequence(items, nv))}
      />
      <div />
      <InfoText>{seededValue.join(' ')}</InfoText>
    </Col>
  );
};
