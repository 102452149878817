import { Target, TargetType } from '../types';
import { iosEqual } from './iosEqual';

export const targetsEqual = (a: Target, b: Target): boolean => {
  switch (a.type) {
    case TargetType.Node:
      return b.type === TargetType.Node && a.id === b.id;
    case TargetType.Edge:
      return b.type === TargetType.Edge && a.id === b.id;
    case TargetType.IO:
      return b.type === TargetType.IO && iosEqual(a.io, b.io);
  }
};
