import { VectorClock, Clocked } from '../types';
import { later } from './later';

// returns insert index for a clock in history array
export function historyPosition<Item extends Clocked>(
  history: Item[],
  clock: VectorClock,
): number {
  let position = history.length;
  while (position > 0) {
    if (later(history[position - 1].clock, clock) > 0) position -= 1;
    else break;
  }
  return position;
}
