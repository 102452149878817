import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { GraphThumbnailData, IOType } from '../../../types';
import { strokeWidth, cornerRadius } from '../../../style';
import {
  getDefaultNodeSize,
  getRawIOCenterPosition,
  add,
} from '../../../utils';

const Node = styled.rect`
  fill: var(--node-color);
  stroke: var(--node-stroke-color);
  stroke-width: ${strokeWidth};
  vector-effect: non-scaling-stroke;
`;
const Edge = styled.line`
  stroke: var(--edge-color);
  stroke-linecap: round;
  stroke-width: 0.2;
`;
const NonScalingEdge = styled(Edge)`
  stroke-width: ${strokeWidth};
  vector-effect: non-scaling-stroke;
`;

export type GraphThumbnailUIProps = {
  graphData: GraphThumbnailData;
};

export const GraphThumbnailUI: FC<GraphThumbnailUIProps> = ({ graphData }) => (
  <>
    {Object.values(graphData.nodes).map(([type, [x, y]], i) => {
      const [w, h] = getDefaultNodeSize(type);
      return (
        <Node key={i} x={x} y={y} width={w} rx={cornerRadius} height={h} />
      );
    })}
    {Object.values(graphData.edges).map(
      ([[fromNodeIndex, fromIndex], [toNodeIndex, toIndex]], i) => {
        const [fromNodeType, fromNodePosition] = graphData.nodes[fromNodeIndex];
        const [toNodeType, toNodePosition] = graphData.nodes[toNodeIndex];
        const [x1, y1] = add(
          fromNodePosition,
          getRawIOCenterPosition(fromNodeType, IOType.Output, fromIndex),
        );
        const [x2, y2] = add(
          toNodePosition,
          getRawIOCenterPosition(toNodeType, IOType.Input, toIndex),
        );
        return (
          <Fragment key={i}>
            <NonScalingEdge x1={x1} y1={y1} x2={x2} y2={y2} />
            <Edge x1={x1} y1={y1} x2={x2} y2={y2} />
          </Fragment>
        );
      },
    )}
  </>
);
