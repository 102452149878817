import { NodeDescription, ParamDescription } from 'helicon';

export enum UIType {
  Hidden = 'Hidden',
  AudioBuffer = 'AudioBuffer',
  Boolean = 'Boolean',
  Enum = 'Enum',
  Float = 'Float',
  FloatArray = 'FloatArray',
  Integer = 'Integer',
  IntegerArray = 'IntegerArray',
  Rhythm = 'Rhythm',
}

export enum ScaleType {
  Linear,
  Log,
}

export type SSParamDescription = ParamDescription & {
  readonly displayName: string;
  readonly uiType: UIType;
  readonly unit?: string;
  readonly options?: any[];
  readonly min?: number;
  readonly max?: number;
  readonly scale?: ScaleType;
};

export interface SSNodeDescription extends NodeDescription {
  readonly displayName: string;
  readonly shortName: string;
  readonly numberOfInputsAndAudioParams: number;
  readonly params: { [name: string]: SSParamDescription };
  readonly inputNames: string[];
  readonly outputNames: string[];
  readonly audioParamNames: string[];
}
