import React, { FC, useContext, memo, useMemo } from 'react';
import styled from 'styled-components';
import { GridContext } from '../contexts';
import { strokeWidth } from '../../../style';
import { randomID } from '../../../utils';

const GridSquare = styled.rect`
  stroke: var(
    ${({ major }: { major?: boolean }) =>
      major ? '--grid-line-color' : '--grid-line-secondary-color'}
  );
  fill: none;
  stroke-width: ${strokeWidth};
  pointer-events: none;
  vector-effect: non-scaling-stroke;
`;

export type GridLinesProps = {
  majorLineSpacing?: number;
};

const gridLinesFadeOutEnd = 8 / strokeWidth;
const gridLinesFadeOutStart = 16 / strokeWidth;

export const GridLines: FC<GridLinesProps> = memo(
  ({ majorLineSpacing = 5 }) => {
    const {
      cellSize: [size],
      viewport: [[x, y], [w, h]],
    } = useContext(GridContext);
    const uniqueID = useMemo(randomID, []);

    const fx = Math.floor(x / majorLineSpacing) * majorLineSpacing;
    const fy = Math.floor(y / majorLineSpacing) * majorLineSpacing;

    const cw = Math.ceil((x + w) / majorLineSpacing) * majorLineSpacing - fx;
    const ch = Math.ceil((y + h) / majorLineSpacing) * majorLineSpacing - fy;

    const showMinorLines = size > gridLinesFadeOutEnd * strokeWidth;
    const minorLineOpacity =
      size > gridLinesFadeOutStart * strokeWidth
        ? 1
        : (size / strokeWidth - gridLinesFadeOutEnd) /
          (gridLinesFadeOutStart - gridLinesFadeOutEnd);

    return (
      <>
        <defs>
          <pattern
            id={`MinorGridLines${uniqueID}`}
            x={0}
            y={0}
            width={1 / cw}
            height={1 / ch}
          >
            <GridSquare
              x={0}
              y={0}
              width={1}
              height={1}
              strokeWidth={strokeWidth}
            />
          </pattern>
          <pattern
            id={`MajorGridLines${uniqueID}`}
            x={0}
            y={0}
            width={majorLineSpacing / cw}
            height={majorLineSpacing / ch}
          >
            <GridSquare
              major
              x={0}
              y={0}
              width={majorLineSpacing}
              height={majorLineSpacing}
            />
          </pattern>
        </defs>
        {showMinorLines && (
          <rect
            fill={`url(#MinorGridLines${uniqueID})`}
            x={fx}
            y={fy}
            width={cw}
            height={ch}
            style={{ opacity: minorLineOpacity }}
          />
        )}
        <rect
          fill={`url(#MajorGridLines${uniqueID})`}
          x={fx}
          y={fy}
          width={cw}
          height={ch}
        />
      </>
    );
  },
);
