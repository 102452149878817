import React, { FC, useState, useRef, memo } from 'react';
import styled from 'styled-components';
import { Vector } from '../../../types';
import { strokeWidth, halfStrokeWidth } from '../../../style';
import { TAB_ORDER } from '../../../data';
import { useAnimationFrameEffect } from '../../../hooks';

type PathProps = { frozen: boolean };
const Path = styled.path`
  stroke-width: ${strokeWidth};
  stroke: var(
    ${({ frozen }: PathProps) =>
      frozen
        ? '--visualization-secondary-stroke-color'
        : '--visualization-stroke-color'}
  );
  fill: none;
`;
const Line = styled.line`
  stroke-width: ${strokeWidth};
  stroke: var(--visualization-secondary-stroke-color);
`;
const SVG = styled.svg`
  flex-shrink: 0;
  background-color: var(--visualization-color);
  &:focus {
    outline-color: var(--outline-secondary-color);
  }
`;

export type OscilloscopeProps = {
  size: Vector;
  truncate?: number;
  stepDivisor?: number;
  frameDivisor?: number;
  analyser: AnalyserNode;
  context: BaseAudioContext;
};

const calculatePath = (
  data: Float32Array,
  fftSize: number,
  truncate: number | undefined,
  [width, height]: Vector,
  stepDivisor: number,
): string => {
  const len = Math.min(truncate ?? fftSize, fftSize);
  const xScale = width / len;
  const yScale = (height - strokeWidth) / 2;
  const yOffset = halfStrokeWidth + yScale;
  const s = Math.floor((stepDivisor / width) * len);

  let d: string;
  if (data) {
    d = `M 0 ${data[0] * yScale + yOffset}`;
    for (let i = 1; i < len - 1; i += s) {
      d += ` L ${i * xScale} ${data[i] * yScale + yOffset}`;
    }
    d += `L ${(len - 1) * xScale} ${data[len - 1] * yScale + yOffset}`;
  } else {
    d = `M 0 ${height / 2} L ${width} ${height / 2}`;
  }

  return d;
};

export const Oscilloscope: FC<OscilloscopeProps> = memo(
  ({
    size,
    size: [width, height],
    truncate,
    stepDivisor = 1,
    frameDivisor = 1,
    analyser,
    context,
  }) => {
    const [frozen, setFrozen] = useState<boolean>(false);
    const dataRef = useRef<Float32Array>(new Float32Array(analyser.fftSize));
    const pathRef = useRef<SVGPathElement>(null);
    useAnimationFrameEffect(
      () => {
        if (frozen || context.state !== 'running' || pathRef.current === null) {
          return;
        }
        if (
          dataRef.current === null ||
          dataRef.current.length !== analyser.fftSize
        ) {
          dataRef.current = new Float32Array(analyser.fftSize);
        }
        analyser.getFloatTimeDomainData(dataRef.current);
        pathRef.current.setAttribute(
          'd',
          calculatePath(
            dataRef.current,
            analyser.fftSize,
            truncate,
            size,
            stepDivisor,
          ),
        );
      },
      [context, analyser, frozen, truncate, size, stepDivisor],
      frameDivisor,
    );

    return (
      <SVG
        width={width}
        height={height}
        tabIndex={TAB_ORDER.VISUALIZATION}
        onClick={() => setFrozen(!frozen)}
        onKeyDown={e => {
          if (e.key === 'Enter') setFrozen(!frozen);
        }}
      >
        <Line x1={0} y1={height / 2} x2={width} y2={height / 2} />
        <Line x1={halfStrokeWidth} y1={0} x2={halfStrokeWidth} y2={height} />
        <Line
          x1={width - halfStrokeWidth}
          y1={0}
          x2={width - halfStrokeWidth}
          y2={height}
        />
        <Path
          ref={pathRef}
          d={calculatePath(
            dataRef.current,
            analyser.fftSize,
            truncate,
            size,
            stepDivisor,
          )}
          frozen={frozen}
        />
      </SVG>
    );
  },
);
