import { Target, TargetType } from '../types';

export const mergeSelections = (s1: Target[], s2: Target[]): Target[] => {
  const seenNodes: { [id: string]: true } = {};
  const seenEdges: { [id: string]: true } = {};
  const seenIOs: {
    [nodeID: string]: { [type: string]: { [index: number]: true } };
  } = {};
  s1.forEach(target => {
    switch (target.type) {
      case TargetType.Node:
        seenNodes[target.id] = true;
        break;
      case TargetType.Edge:
        seenEdges[target.id] = true;
        break;
      case TargetType.IO:
        seenIOs[target.io.node] = seenIOs[target.io.node] || {};
        seenIOs[target.io.node][target.io.type] =
          seenIOs[target.io.node][target.io.type] || {};
        seenIOs[target.io.node][target.io.type][target.io.index] = true;
        break;
      default:
        throw new Error();
    }
  });

  const result = [...s1];
  s2.forEach(target => {
    switch (target.type) {
      case TargetType.Node:
        if (!seenNodes[target.id]) {
          result.push(target);
        }
        break;
      case TargetType.Edge:
        if (!seenEdges[target.id]) {
          result.push(target);
        }
        break;
      case TargetType.IO:
        const seenIOsNode = seenIOs[target.io.node];
        const seenIOsType = seenIOsNode && seenIOsNode[target.io.type];
        const seenIO = seenIOsType && seenIOsType[target.io.index];
        if (!seenIO) {
          result.push(target);
        }
        break;
      default:
        throw new Error();
    }
  });

  return result;
};
