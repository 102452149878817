import React, { FC } from 'react';
import { fixed } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { RhythmInput } from './RhythmInput';
import { usePosition } from '../../hooks';

export const RhythmFixedValueInput: FC<ValueInputProps<number[]>> = ({
  id,
  seededValue,
  node,
  onChange,
}) => {
  const position = usePosition(node.id);
  return (
    <RhythmInput
      id={id}
      value={seededValue}
      onChange={value => onChange(fixed(value))}
      highlightedIndex={position}
    />
  );
};
