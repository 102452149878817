import React, { FC } from 'react';
import { fixed } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { IntegerInput } from './IntegerInput';

export const IntegerFixedValueInput: FC<ValueInputProps<number>> = ({
  id,
  tabIndex,
  disabled,
  seededValue,
  onChange,
}) => {
  return (
    <IntegerInput
      id={id}
      tabIndex={tabIndex}
      disabled={disabled}
      value={seededValue}
      onChange={value => onChange(fixed(value))}
    />
  );
};
