import React, { FC } from 'react';
import { GridLayout } from './GridLayout';
import { Link } from './Link';
import { P } from './P';
import { maxBlockContentWidth } from '../style';

const links: [href: string, text: string][] = [
  ['/about', 'About sinesaw'],
  ['/tutorials', 'Tutorials'],
  ['/paid-storage', 'Paid storage'],
  ['/developers', 'Developers'],
  ['/open-source', 'Open source'],
  ['/contact', 'Contact'],
];

export const Footer: FC = () => (
  <GridLayout style={{ maxWidth: maxBlockContentWidth }}>
    {links.map(([href, text], i) => (
      <P>
        <Link key={i} href={href}>
          {text}
        </Link>
      </P>
    ))}
  </GridLayout>
);
