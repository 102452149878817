import { SSGraph, Rect, Vector } from '../types';
import { graphBounds } from './graphBounds';
import { growRectToMatchAspectRatio, contain, expand } from './rect';

export const gridBounds = (
  graph: SSGraph,
  viewport: Rect,
  domSize: Vector,
): Rect => {
  return growRectToMatchAspectRatio(
    contain(expand(graphBounds(graph), 3), viewport),
    domSize,
  );
};
