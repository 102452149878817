import { FC, useEffect, useContext, memo } from 'react';
import { ActionContext, AudioGraphContext, UndoContext } from '../contexts';
import { setActivity } from '../reducers';

export type KeyboardListenerProps = {
  forceGraphSave: () => void;
};

export const KeyboardListener: FC<KeyboardListenerProps> = memo(
  ({ forceGraphSave }) => {
    const { copy, paste, setSelection, deleteSelection, translateSelection } =
      useContext(ActionContext);
    const { undo, redo } = useContext(UndoContext);
    const { audioGraph } = useContext(AudioGraphContext);

    useEffect(() => {
      const onKey = (e: KeyboardEvent) => {
        // handle some keys regardless of event target
        switch (e.key) {
          case 'Escape':
            setSelection(undefined);
            setActivity(undefined);
            break;
          case 's':
            if (e.metaKey) {
              e.preventDefault();
              forceGraphSave();
            }
            break;
          default:
            break;
        }

        // handle some keys only if the target is not an input element
        if (
          e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLSelectElement ||
          e.target instanceof HTMLTextAreaElement
        )
          return;

        switch (e.key) {
          case 'Backspace':
            e.preventDefault();
            deleteSelection();
            break;
          case 'ArrowUp':
            translateSelection([0, e.shiftKey ? -5 : -1]);
            break;
          case 'ArrowDown':
            translateSelection([0, e.shiftKey ? 5 : 1]);
            break;
          case 'ArrowLeft':
            translateSelection([e.shiftKey ? -5 : -1, 0]);
            break;
          case 'ArrowRight':
            translateSelection([e.shiftKey ? 5 : 1, 0]);
            break;
          case ' ':
            if (audioGraph.playing) {
              if (e.shiftKey) {
                audioGraph.stop();
              } else {
                audioGraph.pause();
              }
            } else {
              audioGraph.play();
            }
            break;
          case 'c':
            if (e.metaKey) {
              copy();
            }
            break;
          case 'v':
            if (e.metaKey) {
              paste();
            }
            break;
          case 'z':
            if (e.metaKey && e.shiftKey) {
              if (redo !== undefined) redo();
            } else if (e.metaKey) {
              if (undo !== undefined) undo();
            }
            break;
          default:
            break;
        }
      };
      window.addEventListener('keydown', onKey);
      return () => window.removeEventListener('keydown', onKey);
    }, [audioGraph, undo, redo]);

    return null;
  },
);
