import { insertAction, replayHistory, updateClock, later } from './utils';
import {
  InternalState,
  InternalAction,
  InternalActionType,
  Typed,
  ActionAction,
  ExternalReducer,
  SyncAction,
  UndoAction,
  RedoAction,
  VectorClock,
  ExternalState,
  HistoryEntry,
} from './types';

// this handles applying an action at the correct position in history, replaying
// following actions, and updating the local clock
export const internalReducer = <Action extends Typed, DocumentState, UserState>(
  documentReducer: (
    state: DocumentState,
    action: Action,
    userState: UserState,
  ) => DocumentState,
  userReducer: (
    state: UserState,
    action: Action,
    documentState: DocumentState,
    nextDocumentState: DocumentState,
  ) => UserState,
  initUser: (username: string) => UserState,
  canCollapse: (action: Action) => boolean,
  canUndo: (action: Action) => boolean,
  ownUsername: string,
) => (
  state: InternalState<Action, DocumentState, UserState>,
  internalAction: InternalAction<Action, DocumentState, UserState>,
): InternalState<Action, DocumentState, UserState> => {
  const reduce = externalReducer(documentReducer, userReducer, initUser);
  // console.log(
  //   'reducer',
  //   state,
  //   internalAction,
  //   internalAction.type === InternalActionType.ACTION &&
  //     internalAction.action?.type,
  // );

  switch (internalAction.type) {
    case InternalActionType.ACTION:
      return action(
        state,
        internalAction,
        reduce,
        canCollapse,
        canUndo,
        ownUsername,
      );
    case InternalActionType.SYNC:
      return sync(state, internalAction, reduce);
    case InternalActionType.UNDO:
      return undo(state, internalAction, reduce, canUndo, ownUsername);
    case InternalActionType.REDO:
      return redo(state, internalAction, reduce);
    default:
      throw new Error();
  }
};

// this handles applying a single action, calling document and user reducers to
// produce the external state
const externalReducer = <Action extends Typed, DocumentState, UserState>(
  documentReducer: (
    state: DocumentState,
    action: Action,
    userState: UserState,
  ) => DocumentState,
  userReducer: (
    state: UserState,
    action: Action,
    documentState: DocumentState,
    nextDocumentState: DocumentState,
  ) => UserState,
  initUser: (username: string) => UserState,
): ExternalReducer<Action, DocumentState, UserState> => (
  { users, document },
  action,
  username,
) => {
  const user = users[username] ?? initUser(username);
  const nextDocument = documentReducer(document, action, user);
  const nextUser = userReducer(user, action, document, nextDocument);
  return {
    document: nextDocument,
    users: {
      ...users,
      [username]: nextUser,
    },
  };
};

export const action = <Action extends Typed, DocumentState, UserState>(
  state: InternalState<Action, DocumentState, UserState>,
  { action, username, clock }: ActionAction<Action>,
  reduce: ExternalReducer<Action, DocumentState, UserState>,
  canCollapse: (action: Action) => boolean,
  canUndo: (action: Action) => boolean,
  ownUsername: string,
): InternalState<Action, DocumentState, UserState> => {
  // insert action at proper position in history order, collapse if possible
  const history = insertAction(
    state.initialState,
    state.history,
    action,
    username,
    clock,
    reduce,
    canCollapse,
  );

  // clear redostack if current user makes a new undoable action
  const redoStack =
    username === ownUsername && canUndo(action) ? [] : state.redoStack;

  return {
    ...state,
    state: history[history.length - 1].state,
    history,
    redoStack,
    clock: updateClock(state.clock, clock),
  };
};

export const sync = <Action extends Typed, DocumentState, UserState>(
  state: InternalState<Action, DocumentState, UserState>,
  action: SyncAction<Action, DocumentState, UserState>,
  reduce: ExternalReducer<Action, DocumentState, UserState>,
): InternalState<Action, DocumentState, UserState> => {
  // Get an array of entries in order by clock - we combine existing entries in
  // the local history w/ those in the sync action and remove duplicates so that
  // we don't lose actions not seen by the responding peer when the sync was
  // sent.
  const orderedEntries: {
    action: Action;
    username: string;
    clock: VectorClock;
    tombstone: boolean;
  }[] = [];

  // first advance through the two arrays until the end of one is reached,
  // adding actions in order and using clocks to detect and skip duplicate
  // entries
  const localHistory = state.history;
  const remoteHistory = action.history;
  let localPosition = 0;
  let remotePosition = 0;
  while (
    localPosition < localHistory.length &&
    remotePosition < remoteHistory.length
  ) {
    const localEntry = localHistory[localPosition];
    const remoteEntry = remoteHistory[remotePosition];
    switch (later(localEntry.clock, remoteEntry.clock)) {
      case 0:
        orderedEntries.push(localEntry.tombstone ? localEntry : remoteEntry);
        localPosition += 1;
        remotePosition += 1;
        break;
      case 1:
        orderedEntries.push(remoteEntry);
        remotePosition += 1;
        break;
      case -1:
        orderedEntries.push(localEntry);
        localPosition += 1;
        break;
    }
  }
  // add remaining actions
  orderedEntries.push(...localHistory.slice(localPosition));
  orderedEntries.push(...remoteHistory.slice(remotePosition));

  // replay actions in order to get the correct states and an up to date clock
  const { externalState, history, clock } = orderedEntries.reduce<{
    externalState: ExternalState<DocumentState, UserState>;
    clock: VectorClock;
    history: HistoryEntry<Action, DocumentState, UserState>[];
  }>(
    (memo, entry) => {
      const externalState = entry.tombstone
        ? memo.externalState
        : reduce(memo.externalState, entry.action, entry.username);
      return {
        externalState,
        history: [
          ...memo.history,
          {
            ...entry,
            state: externalState,
          },
        ],
        clock: updateClock(memo.clock, entry.clock),
      };
    },
    {
      externalState: action.initialState,
      clock: state.clock,
      history: [],
    },
  );

  return {
    ...state,
    initialState: action.initialState,
    state: externalState,
    clock,
    history,
  };
};

export const undo = <Action extends Typed, DocumentState, UserState>(
  state: InternalState<Action, DocumentState, UserState>,
  action: UndoAction,
  reduce: ExternalReducer<Action, DocumentState, UserState>,
  canUndo: (action: Action) => boolean,
  ownUsername: string,
): InternalState<Action, DocumentState, UserState> => {
  let position;
  for (let i = state.history.length - 1; i >= 0; i--) {
    const entry = state.history[i];
    if (
      entry.username === action.username &&
      canUndo(entry.action) &&
      !entry.tombstone
    ) {
      position = i;
      break;
    }
  }
  if (position === undefined) return state;

  const undoAction = state.history[position].action;

  const history = replayHistory(
    state.initialState,
    state.history.slice(0, position),
    [
      {
        ...state.history[position],
        tombstone: true,
      },
      ...state.history.slice(position + 1),
    ],
    reduce,
  );

  return {
    ...state,
    history,
    state: history[history.length - 1].state,
    redoStack:
      action.username === ownUsername
        ? [undoAction, ...state.redoStack]
        : state.redoStack,
    clock: updateClock(state.clock, action.clock),
  };
};

export const redo = <Action extends Typed, DocumentState, UserState>(
  state: InternalState<Action, DocumentState, UserState>,
  action: RedoAction,
  reduce: ExternalReducer<Action, DocumentState, UserState>,
): InternalState<Action, DocumentState, UserState> => {
  const [redoAction, ...redoStack] = state.redoStack;
  if (redoAction === undefined) return state;

  const history = insertAction(
    state.initialState,
    state.history,
    redoAction,
    action.username,
    action.clock,
    reduce,
  );

  return {
    ...state,
    history,
    state: history[history.length - 1].state,
    redoStack,
    clock: updateClock(state.clock, action.clock),
  };
};
