import { defaultGraph, graphToStorage, request, Response } from '../utils';
import { Session } from '../types';

export const createGraph = async ({
  session,
}: {
  session: Session;
}): Response<string | null> => {
  const data = graphToStorage(defaultGraph(session.username));
  const response = await request('update-graph', {
    session,
    graphID: data.id,
    data,
  });
  if (response.status !== 204) {
    return {
      error: response.error,
      status: response.status,
      body: null,
    };
  }
  return {
    error: null,
    status: 200,
    body: data.id,
  };
};
