import React, { FC } from 'react';
import { SSNode } from '../../../types';
import { NODE_DESCRIPTIONS } from '../../../data';

export type NodeDescriptionProps = {
  node: SSNode;
};

export const NodeDescription: FC<NodeDescriptionProps> = ({
  node,
}: NodeDescriptionProps) => {
  return <>{NODE_DESCRIPTIONS[node.type].displayName}</>;
};
