import React, { FC, ComponentType } from 'react';
import styled from 'styled-components';
import { Value, ValueType } from 'helicon';
import {
  SSNode,
  SSNodeDescription,
  SSParamDescription,
  UIType,
} from '../../../../types';
import { convertValue } from '../../../../utils';
import { AudioBufferFixedValueInput } from './AudioBufferFixedValueInput';
import { BooleanFixedValueInput } from './BooleanFixedValueInput';
import { EnumFixedValueInput } from './EnumFixedValueInput';
import { FloatArrayFixedValueInput } from './FloatArrayFixedValueInput';
import { FloatArrayWeightedRandomSubsetValueInput } from './FloatArrayWeightedRandomSubsetValueInput';
import { FloatFixedValueInput } from './FloatFixedValueInput';
import { FloatRandomNumberValueInput } from './FloatRandomNumberValueInput';
import { FloatWeightedRandomMemberValueInput } from './FloatWeightedRandomMemberValueInput';
import { IntegerFixedValueInput } from './IntegerFixedValueInput';
import { RhythmFixedValueInput } from './RhythmFixedValueInput';
import { RhythmWeightedRandomMemberValueInput } from './RhythmWeightedRandomMemberValueInput';
import { lineHeight, strokeWidth } from '../../../../style';
import { TAB_ORDER, VALUE_TYPE_LABELS } from '../../../../data';
import { EnumWeightedRandomMemberValueInput } from './EnumWeightedRandomMemberValueInput';
import { BooleanWeightedRandomMemberValueInput } from './BooleanWeightedRandomMemberValueInput';
import { AudioBufferWeightedRandomMemberValueInput } from './AudioBufferWeightedRandomMemberValueInput';
import { FloatArrayWeightedRandomMemberValueInput } from './FloatArrayWeightedRandomMemberValueInput';
import { FloatArrayRandomNumberSequenceValueInput } from './FloatArrayRandomNumberSequenceValueInput';
import { IntegerRandomNumberValueInput } from './IntegerRandomNumberValueInput';
import { IntegerArrayFixedValueInput } from './IntegerArrayFixedValueInput';
import { IntegerArrayWeightedRandomMemberValueInput } from './IntegerArrayWeightedRandomMemberValueInput';
import { IntegerArrayWeightedRandomSubsetValueInput } from './IntegerArrayWeightedRandomSubsetValueInput';
import { IntegerArrayWeightedRandomSequenceValueInput } from './IntegerArrayWeightedRandomSequenceValueInput';
import { FloatArrayWeightedRandomSequenceValueInput } from './FloatArrayWeightedRandomSequenceValueInput';

const Card = styled.div`
  flex: 1;
  border: solid ${strokeWidth}px var(--grid-line-color);
  background: var(--grid-color);
`;
const Contents = styled.div`
  padding: ${lineHeight}px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const Buttons = styled.div`
  background: var(--grid-line-secondary-color);
  display: flex;
  flex-wrap: wrap;
`;
const TabButton = styled.button`
  border: none;
  background: var(--grid-line-color);
  padding: ${lineHeight / 4}px ${lineHeight / 2}px;
  flex-grow: 1;
  &:hover {
    background: var(--grid-line-secondary-color);
  }
  &:disabled {
    background: var(--grid-color);
  }
  position: relative;
  z-index: 1;
  &:active,
  &:focus {
    z-index: 2;
  }
`;

export interface ValueInputProps<T = any> {
  value: Value;
  seededValue: T;
  onChange: (v: Value) => void;
  node: SSNode;
  nodeDescription: SSNodeDescription;
  paramDescription: SSParamDescription;
  id?: string;
  disabled?: boolean;
  tabIndex?: number;
}

export const VALUE_INPUT_HANDLERS: {
  [uiType in UIType]: {
    [valueType in ValueType]?: ComponentType<ValueInputProps>;
  };
} = {
  [UIType.Hidden]: {},
  [UIType.AudioBuffer]: {
    [ValueType.Fixed]: AudioBufferFixedValueInput,
    [ValueType.WeightedRandomMember]: AudioBufferWeightedRandomMemberValueInput,
  },
  [UIType.Boolean]: {
    [ValueType.Fixed]: BooleanFixedValueInput,
    [ValueType.WeightedRandomMember]: BooleanWeightedRandomMemberValueInput,
  },
  [UIType.Enum]: {
    [ValueType.Fixed]: EnumFixedValueInput,
    [ValueType.WeightedRandomMember]: EnumWeightedRandomMemberValueInput,
  },
  [UIType.Float]: {
    [ValueType.Fixed]: FloatFixedValueInput,
    [ValueType.RandomNumber]: FloatRandomNumberValueInput,
    [ValueType.WeightedRandomMember]: FloatWeightedRandomMemberValueInput,
  },
  [UIType.FloatArray]: {
    [ValueType.Fixed]: FloatArrayFixedValueInput,
    [ValueType.WeightedRandomMember]: FloatArrayWeightedRandomMemberValueInput,
    [ValueType.WeightedRandomSubset]: FloatArrayWeightedRandomSubsetValueInput,
    [ValueType.RandomNumberSequence]: FloatArrayRandomNumberSequenceValueInput,
    [ValueType.WeightedRandomSequence]:
      FloatArrayWeightedRandomSequenceValueInput,
  },
  [UIType.Integer]: {
    [ValueType.Fixed]: IntegerFixedValueInput,
    [ValueType.RandomInteger]: IntegerRandomNumberValueInput,
  },
  [UIType.IntegerArray]: {
    [ValueType.Fixed]: IntegerArrayFixedValueInput,
    [ValueType.WeightedRandomMember]:
      IntegerArrayWeightedRandomMemberValueInput,
    [ValueType.WeightedRandomSubset]:
      IntegerArrayWeightedRandomSubsetValueInput,
    [ValueType.WeightedRandomSequence]:
      IntegerArrayWeightedRandomSequenceValueInput,
  },
  [UIType.Rhythm]: {
    [ValueType.Fixed]: RhythmFixedValueInput,
    [ValueType.WeightedRandomMember]: RhythmWeightedRandomMemberValueInput,
  },
};

export const ValueInput: FC<ValueInputProps> = ({
  value,
  seededValue,
  node,
  nodeDescription,
  paramDescription,
  onChange,
  id,
  disabled,
  tabIndex,
}) => {
  const uiTypeHandlers = VALUE_INPUT_HANDLERS[paramDescription.uiType];
  const Handler = uiTypeHandlers[value.type];
  if (Handler === undefined) {
    throw new Error(`unexpected value type '${value.type}'`);
  }

  return (
    <Card>
      <Buttons>
        {Object.keys(uiTypeHandlers).map(type => (
          <TabButton
            key={type}
            disabled={value.type === parseInt(type)}
            tabIndex={TAB_ORDER.INPUT}
            onClick={() =>
              onChange(convertValue(value, parseInt(type), paramDescription))
            }
          >
            {VALUE_TYPE_LABELS[type as unknown as ValueType]}
          </TabButton>
        ))}
      </Buttons>
      <Contents>
        <Handler
          value={value}
          seededValue={seededValue}
          node={node}
          nodeDescription={nodeDescription}
          paramDescription={paramDescription}
          onChange={onChange}
          id={id}
          disabled={disabled}
          tabIndex={tabIndex}
        />
      </Contents>
    </Card>
  );
};
