import { Session } from '../types';

export enum ActionType {
  SignIn = 'SignIn',
  SignOut = 'SignOut',
}

export type Action = SignInAction | SignOutAction;

export type SignInAction = {
  type: ActionType.SignIn;
  session: Session;
};
export type SignOutAction = {
  type: ActionType.SignOut;
};

export const signIn = (session: Session): SignInAction => ({
  type: ActionType.SignIn,
  session,
});
export const signOut = (): SignOutAction => ({
  type: ActionType.SignOut,
});
