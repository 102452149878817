import { SSGraph, SSNode, SSEdge, Clipboard } from '../types';

export const getNodesAndConnectingEdgesClipboard = (
  graph: SSGraph,
  nodes: SSNode[],
): Clipboard => {
  const nodeIds = new Set(nodes.map(({ id }) => id));
  const edges = [
    ...nodes.reduce((memo, node): Set<SSEdge> => {
      graph.outgoingEdges[node.id]?.forEach(edgeID => {
        const edge = graph.edges[edgeID];
        if (nodeIds.has(edge.to.node)) {
          memo.add(edge);
        }
      });
      return memo;
    }, new Set<SSEdge>()),
  ];

  return { nodes, edges };
};
