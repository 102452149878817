import React, { FC } from 'react';
// import styled from 'styled-components';
import { Label } from '../../../../../components';
import { FloatInput } from '../FloatInput';
import { GaussianDistribution } from 'helicon';
import { gaussian } from 'helicon/build';
// import { inputWidth, lineHeight } from '../../../../../style';
import { SSParamDescription } from '../../../../../types';

export type GaussianDistributionInputProps = {
  id?: string;
  value: GaussianDistribution;
  seededValue?: number;
  onChange: (value: GaussianDistribution) => void;
  paramDescription: SSParamDescription;
  width?: number;
  height?: number;
};

// const SVG = styled.svg`
//   background: var(--input-color);
// `;

// const Curve = styled.path`
//   fill: var(--input-text-color);
// `;

export const GaussianDistributionInput: FC<GaussianDistributionInputProps> = ({
  id,
  value,
  onChange,
  // width = inputWidth,
  // height = 2 * lineHeight,
}) => {
  // const max = 2400;
  // const min = -2400;
  // const { mean, variance } = value;
  // const centerX = ((mean - min) / (max - min)) * width;
  // const minX = ((mean - 2 * variance - min) / (max - min)) * width;
  // const maxX = ((mean + 2 * variance - min) / (max - min)) * width;
  return (
    <div>
      {/* <SVG width={width} height={height}>
        <Curve d={`M ${minX} ${height} L ${centerX} 0 L ${maxX} ${height}`} />
      </SVG> */}
      <Label htmlFor={id && `${id}_mean`}>mean</Label>
      <FloatInput
        id={id && `${id}_mean`}
        value={value.mean}
        onChange={v => onChange(gaussian(v, value.variance))}
      />
      <Label htmlFor={id && `${id}_variance`}>variance</Label>
      <FloatInput
        id={id && `${id}_variance`}
        value={value.variance}
        onChange={v => onChange(gaussian(value.mean, v))}
      />
    </div>
  );
};
