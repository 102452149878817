import React, { FC, useState, useContext } from 'react';
import { resetPassword, completePasswordReset } from '../../api';
import { SessionContext } from '../../contexts';
import {
  BasicLayout,
  LoadingIndicator,
  ValidatedForm,
  H2,
} from '../../components';
import { validatePassword } from '../../utils';

export type ResetPasswordProps = {
  path: string[];
};

enum Status {
  Input,
  Success,
  Error,
  Resent,
}

export const ResetPassword: FC<ResetPasswordProps> = ({ path: [, code] }) => {
  const { signIn } = useContext(SessionContext);
  const [status, setStatus] = useState<Status>(Status.Input);
  const [loading, setLoading] = useState<boolean>(false);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <BasicLayout>
      {status === Status.Input && (
        <>
          <H2 as="h1">Reset password</H2>
          <p>Please enter a new password below.</p>
          <ValidatedForm
            onSubmit={async ({ newPassword }) => {
              const [username, passwordResetCode] = code.split('.');
              const response = await completePasswordReset({
                username,
                passwordResetCode,
                newPassword,
              });
              if (response.status === 200) {
                const { session } = response.body;
                signIn(session);
                setStatus(Status.Success);
              } else {
                setStatus(Status.Error);
              }
              return response.error;
            }}
            loading={loading}
            setLoading={setLoading}
            buttonText="Change password"
            fields={{
              newPassword: {
                label: 'New password',
                type: 'password',
                validate: validatePassword,
              },
            }}
          />
        </>
      )}
      {status === Status.Success && (
        <>
          <H2 as="h1">Your password was reset successfully</H2>
          <p>You are now signed in.</p>
        </>
      )}
      {status === Status.Error && (
        <>
          <H2 as="h1">We weren’t able to reset your password</H2>
          <p>The reset link you used may be expired or invalid</p>
          <p>Please enter your username below to get a new link</p>
          <ValidatedForm
            onSubmit={async ({ username }) => {
              const response = await resetPassword({
                username,
              });
              if (response.status === 204) {
                setStatus(Status.Resent);
              }
              return response.error;
            }}
            loading={loading}
            setLoading={setLoading}
            buttonText="Resend link"
            fields={{
              username: {
                label: 'Username',
                validate: validatePassword,
              },
            }}
          />
        </>
      )}
      {status === Status.Resent && (
        <>
          <H2 as="h1">We emailed a new reset link</H2>
          <p>If it doesn’t arrive, be sure to check your spam folder.</p>
        </>
      )}
    </BasicLayout>
  );
};
