import React, { FC } from 'react';
import styled from 'styled-components';
import { ValueInputProps } from './ValueInput';
import { TAB_ORDER } from '../../../../data';
import { lineHeight } from '../../../../style';
import { Button, Row, Col, InfoText, Label } from '../../../../components';
import { WeightInput } from './WeightInput';
import { WeightedRandomMemberValue } from 'helicon';
import { weightedRandomMember } from 'helicon/build';
import { usePosition } from '../../hooks';
import { ReactElement } from 'react';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const WeightRow = styled(Row)`
  background-color: var(--input-disabled-color);
`;

const ModifyButton = styled(Button)`
  width: ${1.5 * lineHeight}px;
  text-align: center;
`;

const WeightLabel = styled(Label)`
  flex: 1;
  margin: 0;
  padding: 0 ${0.5 * lineHeight}px;
`;

type ArrayWeightedRandomMemberValueInputProps<T extends any[]> =
  ValueInputProps<T> & {
    Component: FC<{
      id?: string;
      disabled?: boolean;
      value: T;
      min?: number;
      max?: number;
      onChange: (value: T) => void;
      highlightedIndex?: number;
    }>;
  };

export const ArrayWeightedRandomMemberValueInput = <T extends any[]>({
  id,
  disabled,
  value,
  seededValue,
  paramDescription: { min, max },
  onChange,
  node,
  Component,
}: ArrayWeightedRandomMemberValueInputProps<T>): ReactElement => {
  const { items } = value as WeightedRandomMemberValue;

  const onChangeItem = (nv: [number[], number], i: number): void => {
    const copy = [...items];
    copy[i] = nv;
    onChange(weightedRandomMember(copy));
  };

  const position = usePosition(node.id);

  // find index of seeded value
  const seededIndex = items.findIndex(
    ([v]) =>
      v.length === seededValue.length &&
      v.every((vv: number, i: number) => vv === seededValue[i]),
  );

  return (
    <Col gap={1} align="stretch">
      <Col align="stretch" gap={1}>
        {items.map(([v, weight], i) => (
          <Container id={id}>
            <WeightRow align="center">
              <WeightInput
                id={`${id}_weight`}
                disabled={disabled}
                tabIndex={TAB_ORDER.INPUT}
                value={weight}
                onChange={nv => onChangeItem([v, nv], i)}
              />
              <WeightLabel htmlFor={`${id}_weight`}>weight</WeightLabel>
              <ModifyButton
                disabled={disabled || items.length <= 1}
                tabIndex={TAB_ORDER.INPUT}
                onClick={() => {
                  const copy = [...items];
                  copy.splice(i, 1);
                  onChange(weightedRandomMember(copy));
                }}
                onKeyDown={e => e.stopPropagation()}
              >
                ×
              </ModifyButton>
            </WeightRow>
            <Component
              value={v}
              onChange={nv => onChangeItem([nv, weight], i)}
              disabled={disabled}
              highlightedIndex={i === seededIndex ? position : undefined}
              min={min}
              max={max}
            />
          </Container>
        ))}
        <Button
          disabled={disabled}
          tabIndex={TAB_ORDER.INPUT}
          onClick={() => onChange(weightedRandomMember([...items, [[], 1]]))}
          onKeyDown={e => e.stopPropagation()}
          fullWidth
        >
          +
        </Button>
      </Col>
      <InfoText>{seededValue.join(' ')}</InfoText>
    </Col>
  );
};
