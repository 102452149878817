import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { TAB_ORDER } from '../../../../data';
import { Button, Col, Row } from '../../../../components';
import { lineHeight } from '../../../../style';

export type ArrayInputProps<T> = {
  id?: string;
  disabled?: boolean;
  value: T[] | null;
  onChange: (value: T[]) => void;
  defaultItem: T;
  renderItemInput: (
    value: T,
    index: number,
    onChange: (v: T) => void,
  ) => ReactElement;
  minLength?: number;
};

const RemoveButton = styled(Button)`
  width: ${1.5 * lineHeight}px;
  textalign: center;
`;

const AddButton = styled(Button)`
  width: calc(100% - ${1.5 * lineHeight}px);
`;

export const ArrayInput = <T extends any>({
  id,
  disabled,
  value,
  onChange,
  defaultItem,
  renderItemInput,
  minLength,
}: ArrayInputProps<T>): ReactElement => {
  const preventRemoval =
    minLength !== undefined && (value?.length ?? 0) <= minLength;
  return (
    <Col id={id} align="stretch">
      {value?.map((v, i) => (
        <Row key={i}>
          {renderItemInput(v, i, v => {
            const copy = [...value];
            copy[i] = v;
            onChange(copy);
          })}
          <RemoveButton
            disabled={disabled || preventRemoval}
            tabIndex={TAB_ORDER.INPUT}
            onClick={() => {
              const copy = [...value];
              copy.splice(i, 1);
              onChange(copy);
            }}
            onKeyDown={e => e.stopPropagation()}
          >
            ×
          </RemoveButton>
        </Row>
      ))}
      <AddButton
        disabled={disabled}
        tabIndex={TAB_ORDER.INPUT}
        onClick={() => onChange([...(value ?? []), defaultItem])}
        onKeyDown={e => e.stopPropagation()}
      >
        +
      </AddButton>
    </Col>
  );
};
