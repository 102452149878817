import { Value, ValueType } from 'helicon';
import { SSParamDescription } from '../types';
import { VALUE_CONVERSION_TABLE } from './VALUE_CONVERSION_TABLE';

export const convertValue = (
  value: Value,
  toType: ValueType,
  pd: SSParamDescription,
): Value => {
  const targets = VALUE_CONVERSION_TABLE[value.type];
  if (targets === undefined) {
    throw new Error(`unable to convert value from '${ValueType[value.type]}'`);
  }
  const converter = targets[toType];
  if (converter === undefined) {
    throw new Error(
      `unable to convert value from '${ValueType[value.type]}' to '${
        ValueType[toType]
      }'`,
    );
  }
  return converter(value, pd);
};
