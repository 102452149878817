import { VectorClock } from '../types';

export const updateClock = (
  clock1: VectorClock,
  clock2: VectorClock,
): VectorClock => {
  const result = { ...clock1 };
  Object.entries(clock2).forEach(([k, v]) => {
    if (result[k] === undefined || result[k] < v) {
      result[k] = v;
    }
  });
  return result;
};
