import React, { FC } from 'react';
import styled from 'styled-components';
import { Overlay } from './Overlay';
import { ErrorText } from './ErrorText';
import { NetworkActivityIndicator } from './NetworkActivityIndicator';

const Main = styled(Overlay)`
  pointer-events: none;
`;

export type BlockingOverlayProps = {
  error: string | null;
};

export const BlockingOverlay: FC<BlockingOverlayProps> = ({ error }) => {
  return (
    <Main>
      <NetworkActivityIndicator active={true} pending={true} error={!!error} />
      <ErrorText>{error}</ErrorText>
    </Main>
  );
};
