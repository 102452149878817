import { NodeExtension, ParamType } from 'helicon';

export const ReverbExtension: NodeExtension = {
  type: 'Reverb',
  processor: '/ReverbProcessor.js',
  description: {
    numberOfInputs: 1,
    numberOfOutputs: 1,
    outputChannelCount: [2],
    channelCount: 2,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      diffusion: {
        type: ParamType.AudioParam,
        default: 0.625,
      },
      lp: {
        type: ParamType.AudioParam,
        default: 0.7,
      },
      reverbTime: {
        type: ParamType.AudioParam,
        default: 0.5,
      },
      amount: {
        type: ParamType.AudioParam,
        default: 0.5,
      },
      gain: {
        type: ParamType.AudioParam,
        default: 0.5,
      },
    },
    signals: {},
  },
};
