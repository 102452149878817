import React, { FC, MouseEvent, WheelEvent, useContext } from 'react';
import { Grid, GridProps } from './Grid';
import { Vector, ActivityType, DragSelectionActivity } from '../../../types';
import { useDrag } from '../../../hooks';
import { useGridDrag } from '../hooks';
import { ActionContext, GridContext } from '../contexts';
import { subtract, divide } from '../../../utils';

export type NavigableGridProps = GridProps;

const ZOOM_FACTOR = 0.85;

export const NavigableGrid: FC<NavigableGridProps> = ({
  onMouseDown,
  onWheel,
  onClick,
  onDoubleClick,
  onContextMenu,
  children,
}) => {
  const { setViewport, zoomViewport, setActivity, performActivity } =
    useContext(ActionContext);
  const {
    cellSize,
    domRect: [domRectPosition, domRectSize],
    viewport: [viewportPosition, viewportSize],
  } = useContext(GridContext);

  const beginDragViewportPan = useDrag<Vector>(
    (_position, _origin, delta, originalViewportPosition) => {
      setViewport([
        subtract(originalViewportPosition, divide(delta, cellSize)),
        viewportSize,
      ]);
    },
  );

  const getDragSelectionActivity = (
    [positionX, positionY]: Vector,
    [originX, originY]: Vector,
    [deltaX, deltaY]: Vector,
    merge: boolean,
  ): DragSelectionActivity => ({
    type: ActivityType.DragSelection,
    origin: [
      deltaX < 0 ? originX + 1 : originX,
      deltaY < 0 ? originY + 1 : originY,
    ],
    position: [
      deltaX < 0 ? positionX : positionX + 1,
      deltaY < 0 ? positionY : positionY + 1,
    ],
    merge,
  });

  const beginDragSelection = useGridDrag(
    (position, origin, delta, _, e) =>
      setActivity(
        getDragSelectionActivity(
          position,
          origin,
          delta,
          e.shiftKey || e.metaKey,
        ),
      ),
    (position, origin, delta, _, e) => {
      performActivity(
        getDragSelectionActivity(
          position,
          origin,
          delta,
          e.shiftKey || e.metaKey,
        ),
      );
      setActivity(undefined);
    },
    true,
  );

  return (
    <Grid
      onMouseDown={(e: MouseEvent<SVGElement>) => {
        onMouseDown && onMouseDown(e);
        if (e.shiftKey) {
          beginDragSelection(e, undefined);
        } else {
          beginDragViewportPan(e, viewportPosition);
        }
      }}
      onWheel={(e: WheelEvent<SVGElement>) => {
        e.stopPropagation();
        onWheel && onWheel(e);
        const zoomCenter = divide(
          subtract([e.pageX, e.pageY], domRectPosition),
          domRectSize,
        );
        const zoomAmount = e.deltaY * ZOOM_FACTOR;
        zoomViewport(zoomCenter, zoomAmount);
      }}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onContextMenu={onContextMenu}
      onKeyDown={e => {
        // up and down arrows zoom viewport in and out when meta key is held
        if (e.metaKey) {
          switch (e.key) {
            case 'ArrowUp':
              zoomViewport([0.5, 0.5], -50);
              break;
            case 'ArrowDown':
              zoomViewport([0.5, 0.5], 50);
              break;
            default:
              return;
          }
          e.stopPropagation();
          return;
        }
      }}
    >
      {children}
    </Grid>
  );
};
