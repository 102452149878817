import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  MouseEvent,
  useRef,
  useContext,
} from 'react';
import { SSGraph, Vector, Target, Activity } from '../../../types';
import {
  ActionContext,
  AudioGraphContext,
  SelectionContext,
  ActivityContext,
  HoverTargetContext,
  GraphContext,
  GridContext,
  GridRefContext,
  LayoutContext,
  SelectionOptions,
  GridOptions,
  bindActions,
  AudioGraphOptions,
  GraphRefContext,
  FocusTargetContext,
  ClipboardContext,
  UndoContext,
  ActivityOptions,
  HoverTargetOptions,
  FocusTargetOptions,
  GraphOptions,
} from '../contexts';
import { SessionContext } from '../../../contexts';
import { EditorLayout } from './EditorLayout';
import { KeyboardListener } from './KeyboardListener';
import {
  userReducer,
  graphReducer,
  initialUserState,
  UserState,
  Action,
  canUndo,
  canCollapse,
} from '../reducers';
import { AudioGraph, randomSeed, seedGraph } from 'helicon';
import { useP2PReducer, useThrottledEffect } from '../../../hooks';
import { updateGraph } from '../../../api';
import {
  graphToStorage,
  add,
  subtract,
  divide,
  compareTargets,
} from '../../../utils';
import { EXTENSIONS } from '../../../extensions';

export type EditorControllerProps = {
  initialGraph: SSGraph;
};

export const EditorController: FC<EditorControllerProps> = ({
  initialGraph,
}) => {
  const { session } = useContext(SessionContext);
  const username = session?.username ?? '!';
  const [seed, setSeed] = useState(() => {
    if (window.location.hash.startsWith('#0x')) {
      return parseInt(window.location.hash.slice(3), 16);
    } else {
      const seed = randomSeed();
      window.location.hash = `#0x${seed}`;
      return seed;
    }
  });

  const getInitialGraph = useMemo(() => () => initialGraph, [initialGraph]);
  const getInitialUserState = useMemo(
    () => () => initialUserState(initialGraph),
    [initialGraph],
  );
  const {
    state: {
      document: graph,
      users,
      users: {
        [username]: {
          grid: {
            domRect,
            domRect: [domRectPosition, domRectSize],
            viewport,
            viewport: [viewportPosition, viewportSize],
          },
          interaction: { cursorPosition, selection, activity, clipboard },
          layout,
        },
      },
    },
    // connectionState,
    dispatch,
    undo,
    redo,
  } = useP2PReducer<Action, SSGraph, UserState>(
    graphReducer,
    userReducer,
    getInitialGraph,
    getInitialUserState,
    canCollapse,
    canUndo,
    session,
    // initialGraph.id,
  );
  const userEntries = Object.entries(users);

  const seededGraph = useMemo(() => seedGraph(graph, seed), [graph, seed]);

  const [audioGraph] = useState(() => new AudioGraph(undefined, EXTENSIONS));

  // make sure to close the audiograph when this component unmounts
  useEffect(() => () => audioGraph.close(), [audioGraph]);

  // because stopping playback requires a new context to be created we keep it
  // in state/context so that components can access an up to date context
  const [audioContext, setAudioContext] = useState(audioGraph.context);
  useEffect(() => {
    const listener = () => {
      if (audioGraph.context !== audioContext) {
        setAudioContext(audioGraph.context);
      }
    };
    audioGraph.addEventListener('playbackStateChange', listener);
    return () =>
      audioGraph.removeEventListener('playbackStateChange', listener);
  }, [audioGraph, audioContext]);

  // Audio graph update needs to be called from an effect hook to ensure it is
  // not double updated.  Because effects of children run before the parent
  // effect, they will access a stale version of the graph.  We create the
  // audioGraphUpdating promise and pass it through context to allow child
  // effects to wait for the updated graph.
  const [audioGraphUpdating, resolveGraphUpdate] = useMemo((): [
    Promise<void>,
    () => void,
  ] => {
    let r: any;
    const p: Promise<void> = new Promise(resolve => {
      r = resolve;
    });
    return [p, r];
  }, [audioGraph, graph]);
  useEffect(() => {
    audioGraph.ready
      .then(() => audioGraph.update(seededGraph))
      .then(resolveGraphUpdate);
  }, [audioGraph, seededGraph]);

  // save graph updates to server
  const [graphSaveError, setGraphSaveError] = useState<string | null>(null);
  const {
    pending: graphSavePending,
    active: graphSaveActive,
    force: forceGraphSave,
  } = useThrottledEffect(
    async () => {
      if (session) {
        const response = await updateGraph({
          session,
          data: graphToStorage(graph),
        });
        setGraphSaveError(response.error);
      }
    },
    4000,
    [graph],
  );
  useEffect(() => {
    const confirmExit = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    if (graphSavePending) window.addEventListener('beforeunload', confirmExit);
    return () => window.removeEventListener('beforeunload', confirmExit);
  }, [graphSavePending]);

  const boundActions = useMemo(() => bindActions(dispatch), []);
  const { setSelection } = boundActions;

  const graphOptions = useMemo<GraphOptions>(
    () => ({
      graph,
      seededGraph,
      seed,
      reSeed: () => {
        const nextSeed = randomSeed();
        window.location.hash = `0x${nextSeed.toString(16)}`;
        setSeed(nextSeed);
      },
    }),
    [graph, seed, seededGraph],
  );

  const audioGraphOptions = useMemo<AudioGraphOptions>(
    () => ({
      updating: audioGraphUpdating,
      audioGraph,
      audioContext,
    }),
    [audioGraph, audioContext, graph],
  );

  const undoOptions = useMemo(() => ({ undo, redo }), [undo, redo]);

  const selectionOptions = useMemo<SelectionOptions>(
    () => ({
      selections: userEntries.reduce((memo, [username, peer]) => {
        memo[username] = peer.interaction.selection;
        return memo;
      }, {} as { [username: string]: Target[] | undefined }),
      select: (t: Target) => (e: MouseEvent) => {
        e.stopPropagation();
        if ((e.metaKey || e.shiftKey) && selection !== undefined) {
          const index = selection.findIndex(t2 => compareTargets(t, t2));
          if (selection === undefined || index === -1) {
            setSelection([...(selection ?? []), t]);
          } else if (selection.length === 1) {
            setSelection(undefined);
          } else {
            setSelection([
              ...selection?.slice(0, index),
              ...selection?.slice((index as number) + 1),
            ]);
          }
        } else {
          setSelection([t]);
        }
      },
    }),
    [
      selection,
      userEntries.length,
      userEntries.map(([, u]) => u.interaction.selection),
    ],
  );

  const gridOptions = useMemo<GridOptions>(() => {
    const cellSize = divide(domRectSize, viewportSize);
    return {
      domRect,
      viewport,
      cellSize,
      getGridPosition: (pagePosition: Vector) =>
        add(
          divide(subtract(pagePosition, domRectPosition), cellSize),
          viewportPosition,
        ),
    };
  }, [domRect, viewport]);

  const gridRef = useRef<GridOptions>(gridOptions);
  useEffect(() => {
    gridRef.current = gridOptions;
  }, [gridOptions]);

  const graphRef = useRef<SSGraph>(graph);
  useEffect(() => {
    graphRef.current = graph;
  }, [graph]);

  const activityOptions = useMemo<ActivityOptions>(
    () =>
      userEntries.reduce((memo, [username, peer]) => {
        memo[username] = peer.interaction.activity;
        return memo;
      }, {} as { [username: string]: Activity | undefined }),
    [
      activity,
      userEntries.length,
      ...userEntries.map(([, u]) => u.interaction.activity),
    ],
  );

  const hoverTargetOptions = useMemo<HoverTargetOptions>(
    () =>
      userEntries.reduce((memo, [username, peer]) => {
        memo[username] = peer.interaction.hoverTarget;
        return memo;
      }, {} as { [username: string]: Target | undefined }),
    [
      activity,
      userEntries.length,
      ...userEntries.map(([, u]) => u.interaction.hoverTarget),
    ],
  );

  const focusTargetOptions = useMemo<FocusTargetOptions>(
    () =>
      userEntries.reduce((memo, [username, peer]) => {
        memo[username] = peer.interaction.focusTarget;
        return memo;
      }, {} as { [username: string]: Target | undefined }),
    [
      activity,
      userEntries.length,
      ...userEntries.map(([, u]) => u.interaction.focusTarget),
    ],
  );

  return (
    <AudioGraphContext.Provider
      key="AudioGraphContext"
      value={audioGraphOptions}
    >
      <ActionContext.Provider key="ActionContext" value={boundActions}>
        <UndoContext.Provider key="UndoContext" value={undoOptions}>
          <GraphRefContext.Provider key="GraphRefContext" value={graphRef}>
            <GraphContext.Provider key="GraphContext" value={graphOptions}>
              <SelectionContext.Provider
                key="SelectionContext"
                value={selectionOptions}
              >
                <ActivityContext.Provider
                  key="ActivityContext"
                  value={activityOptions}
                >
                  <FocusTargetContext.Provider
                    key="FocusTargetContext"
                    value={focusTargetOptions}
                  >
                    <HoverTargetContext.Provider
                      key="HoverTargetContext"
                      value={hoverTargetOptions}
                    >
                      <ClipboardContext.Provider
                        key="ClipboardContext"
                        value={clipboard}
                      >
                        <GridRefContext.Provider
                          key="GridRefContext"
                          value={gridRef}
                        >
                          <GridContext.Provider
                            key="GridContext"
                            value={gridOptions}
                          >
                            <LayoutContext.Provider
                              key="LayoutContext"
                              value={layout}
                            >
                              <KeyboardListener
                                forceGraphSave={forceGraphSave}
                              />
                              <EditorLayout
                                graphSavePending={graphSavePending}
                                graphSaveActive={graphSaveActive}
                                graphSaveError={graphSaveError}
                                cursorPosition={cursorPosition}
                              />
                            </LayoutContext.Provider>
                          </GridContext.Provider>
                        </GridRefContext.Provider>
                      </ClipboardContext.Provider>
                    </HoverTargetContext.Provider>
                  </FocusTargetContext.Provider>
                </ActivityContext.Provider>
              </SelectionContext.Provider>
            </GraphContext.Provider>
          </GraphRefContext.Provider>
        </UndoContext.Provider>
      </ActionContext.Provider>
    </AudioGraphContext.Provider>
  );
};
