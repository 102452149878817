import React, { FC, useContext, memo } from 'react';
import {
  ActionContext,
  GraphContext,
  ClipboardContext,
  GridRefContext,
} from '../contexts';
import { NavigableGrid } from './NavigableGrid';
import { GridLines } from './GridLines';
import { NodeUI } from './NodeUI';
import { EdgeUI } from './EdgeUI';
import { NodeConnectionActivityUI } from './NodeConnectionActivityUI';
import { NodeTranslationActivityUI } from './NodeTranslationActivityUI';
import { DragSelectionActivityUI } from './DragSelectionActivityUI';
import { NodeHoverTargetUI } from './NodeHoverTargetUI';
import { EdgeHoverTargetUI } from './EdgeHoverTargetUI';
import { FocusTargetUI } from './FocusTargetUI';
import { useContextMenu } from '../../../hooks';
import { TargetType } from '../../../types';
import {
  getDefaultNodeSize,
  node,
  floor,
  subtract,
  divideScalar,
} from '../../../utils';
import { NODE_DESCRIPTIONS, AVAILABLE_NODES } from '../../../data';

const availableNodesInOrderByDisplayName = AVAILABLE_NODES.sort((a, b) =>
  NODE_DESCRIPTIONS[a].displayName > NODE_DESCRIPTIONS[b].displayName ? 1 : -1,
);

export type GraphUIProps = {};

export const GraphUI: FC<GraphUIProps> = memo(() => {
  const { setSelection, paste, addNode } = useContext(ActionContext);
  const { graph } = useContext(GraphContext);
  const clipboard = useContext(ClipboardContext);
  const gridRef = useContext(GridRefContext);

  const onContextMenu = useContextMenu([
    [
      [
        'Paste',
        position => {
          const {
            current: { getGridPosition },
          } = gridRef;
          paste(getGridPosition(position));
        },
        clipboard === undefined,
      ],
    ],
    [
      [
        'Add node',
        [
          availableNodesInOrderByDisplayName.map(type => {
            const description = NODE_DESCRIPTIONS[type];
            return [
              description.displayName,
              position => {
                const n = node(
                  type,
                  subtract(
                    floor(gridRef.current.getGridPosition(position)),
                    floor(divideScalar(getDefaultNodeSize(type), 2)),
                  ),
                );
                addNode(n);
                setSelection([{ type: TargetType.Node, id: n.id }]);
              },
            ];
          }),
        ],
      ],
    ],
  ]);

  return (
    <NavigableGrid
      onClick={() => {
        setSelection(undefined);
      }}
      onContextMenu={onContextMenu}
    >
      <GridLines />
      {Object.values(graph.nodes).map(node => (
        <NodeUI key={node.id} node={node} />
      ))}
      <NodeHoverTargetUI />
      <NodeTranslationActivityUI />
      {Object.values(graph.edges).map(edge => (
        <EdgeUI key={edge.id} edge={edge} />
      ))}
      <DragSelectionActivityUI />
      <NodeConnectionActivityUI />
      <EdgeHoverTargetUI />
      <FocusTargetUI />
    </NavigableGrid>
  );
});
