import { SSNode, SSIO } from './SSGraph';
import { Vector } from './Vector';
import { Clipboard } from './Clipboard';

export enum ActivityType {
  DragSelection = 'DragSelection',
  NodeConnection = 'NodeConnection',
  NodeTranslation = 'NodeTranslation',
  NodeClone = 'NodeClone',
}

export type DragSelectionActivity = {
  readonly type: ActivityType.DragSelection;
  readonly origin: Vector;
  readonly position: Vector;
  readonly merge: boolean;
};

export type NodeConnectionActivity = {
  readonly type: ActivityType.NodeConnection;
  readonly origin: SSIO;
  readonly position: Vector;
};

export type NodeTranslationActivity = {
  readonly type: ActivityType.NodeTranslation;
  readonly delta: Vector;
  readonly nodes: SSNode[];
};

export type NodeCloneActivity = {
  readonly type: ActivityType.NodeClone;
  readonly delta: Vector;
  readonly clipboard: Clipboard;
  readonly seed: number;
};

export type Activity =
  | DragSelectionActivity
  | NodeConnectionActivity
  | NodeTranslationActivity
  | NodeCloneActivity;
