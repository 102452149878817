import React, { FC } from 'react';
import { IntegerInput } from './IntegerInput';
import { ArrayInput } from './ArrayInput';
import { TAB_ORDER } from '../../../../data';

export type IntegerArrayInputProps = {
  id?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  value: number[];
  min?: number;
  max?: number;
  onChange: (value: number[]) => void;
  highlightedIndex?: number;
};

export const IntegerArrayInput: FC<IntegerArrayInputProps> = ({
  id,
  disabled,
  autoFocus,
  value,
  min,
  max,
  onChange,
  highlightedIndex,
}) => {
  return (
    <ArrayInput
      id={id}
      disabled={disabled}
      value={value}
      onChange={onChange}
      defaultItem={0}
      renderItemInput={(v, i, onChangeItem) => (
        <IntegerInput
          key={i}
          disabled={disabled}
          tabIndex={TAB_ORDER.INPUT}
          autoFocus={i === 0 && autoFocus}
          highlighted={i === highlightedIndex}
          value={v}
          min={min}
          max={max}
          onChange={onChangeItem}
        />
      )}
    />
  );
};
