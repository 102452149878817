import styled, { css } from 'styled-components';
import { lineHeight, pulse } from '../style';

export type NetworkActivityIndicatorProps = {
  pending: boolean;
  active: boolean;
  error: boolean;
};

export const NetworkActivityIndicator = styled.div`
  width: ${lineHeight}px;
  height: ${lineHeight}px;
  border-radius: ${lineHeight / 2}px;
  background: ${({ error, active }: NetworkActivityIndicatorProps) =>
    error
      ? 'var(--error-text-color)'
      : active
      ? 'var(--visualization-stroke-color)'
      : 'var(--visualization-color)'};
  color: var(--background-color);
  text-align: center;
  animation: ${({ active, error }) =>
    active && !error
      ? css`
          ${pulse} 1s linear infinite alternate
        `
      : 'none'};
  transform: ${({ pending, active, error }) =>
    pending && !active && !error ? 'scale(0.75)' : 'scale(1)'};
  transition: opacity 0.3s linear, background-color 0.2s linear;
  opacity: ${({ pending, active, error }) =>
    pending || active || error ? 1 : 0};
  &:after {
    content: ${({ error }) => (error ? "'!'" : '')};
  }
`;
