import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components';
import { TAB_ORDER } from '../../../data';

export type GraphPublicAccessControlsProps = {
  isPublic: boolean;
  updateGraphPublicAccess: (isPublic: boolean) => void;
};

const InlineButton = styled(Button)`
  width: auto;
  flex: 0 0 auto;
`;

export const GraphPublicAccessControls: FC<GraphPublicAccessControlsProps> = ({
  isPublic,
  updateGraphPublicAccess,
}) => {
  return isPublic ? (
    <div>
      <h2>Public</h2>
      <p>
        This project will be visible on your profile and to anyone with the
        link.
      </p>
      <InlineButton
        tabIndex={TAB_ORDER.INPUT}
        onClick={() => updateGraphPublicAccess(false)}
      >
        Make private
      </InlineButton>
    </div>
  ) : (
    <div>
      <h2>Private</h2>
      <p>Only people directly invited can view this project</p>
      <InlineButton
        tabIndex={TAB_ORDER.INPUT}
        onClick={() => updateGraphPublicAccess(true)}
      >
        Make public
      </InlineButton>
    </div>
  );
};
