import { InteractionState } from './State';
import { Action, ActionType } from './Action';
import {
  SSGraph,
  ActivityType,
  Target,
  TargetType,
  NodeTarget,
  Clipboard,
} from '../../../types';
import {
  getNodeSize,
  seededRandomIDs,
  getNodesAndConnectingEdgesClipboard,
  mergeSelections,
  min,
  max,
} from '../../../utils';

export const interactionReducer = (
  interaction: InteractionState,
  graph: SSGraph,
  nextGraph: SSGraph,
  action: Action,
): InteractionState => {
  let result: InteractionState = (() => {
    switch (action.type) {
      case ActionType.SetCursorPosition:
        return { ...interaction, cursorPosition: action.position };
      case ActionType.SetActivity:
        return { ...interaction, activity: action.activity };
      case ActionType.PerformActivity:
        switch (action.activity.type) {
          case ActivityType.DragSelection:
            const { origin, position, merge } = action.activity;
            const [minimumX, minimumY] = min(origin, position);
            const [maximumX, maximumY] = max(origin, position);
            const selection: Target[] = Object.values(nextGraph.nodes)
              .filter(n => {
                const [sizeX, sizeY] = getNodeSize(n);
                const [positionX, positionY] = n.position;
                return (
                  positionX + sizeX > minimumX &&
                  positionX < maximumX &&
                  positionY + sizeY > minimumY &&
                  positionY < maximumY
                );
              })
              .map(node => ({ type: TargetType.Node, id: node.id }));
            return {
              ...interaction,
              selection:
                merge && interaction.selection
                  ? mergeSelections(interaction.selection, selection)
                  : selection,
            };
          case ActivityType.NodeClone:
            return paste(
              interaction,
              action.activity.clipboard,
              action.activity.seed,
            );
          default:
            return interaction;
        }
      case ActionType.SetFocusTarget:
        return { ...interaction, focusTarget: action.target };
      case ActionType.SetHoverTarget:
        return { ...interaction, hoverTarget: action.target };
      case ActionType.SetSelection:
        return { ...interaction, selection: action.selection };
      case ActionType.Copy:
        return copy(interaction, graph);
      case ActionType.Paste:
        return paste(interaction, interaction.clipboard, action.seed);
      default:
        return interaction;
    }
  })();

  // clear targets that have been removed from the graph
  if (graph !== nextGraph) {
    const { focusTarget, hoverTarget, selection } = result;
    if (focusTarget && !isValidTarget(nextGraph, focusTarget)) {
      result = { ...result, focusTarget: undefined };
    }
    if (hoverTarget && !isValidTarget(nextGraph, hoverTarget)) {
      result = { ...result, hoverTarget: undefined };
    }
    if (selection && selection.some(t => !isValidTarget(nextGraph, t))) {
      result = {
        ...result,
        selection: selection.filter(t => isValidTarget(nextGraph, t)),
      };
    }
  }

  return result;
};

const isValidTarget = (graph: SSGraph, target: Target): boolean =>
  (target.type == TargetType.Node && graph.nodes[target.id] !== undefined) ||
  (target.type === TargetType.Edge && graph.edges[target.id] !== undefined) ||
  (target.type === TargetType.IO && graph.nodes[target.io.node] !== undefined);

const copy = (
  interaction: InteractionState,
  graph: SSGraph,
): InteractionState => {
  const { selection } = interaction;
  if (selection === undefined) return interaction;

  // get selected nodes
  const nodes = (
    selection.filter(({ type }) => type === TargetType.Node) as NodeTarget[]
  ).map(({ id }) => graph.nodes[id]);

  // create clipboard including connecting edges
  const clipboard = getNodesAndConnectingEdgesClipboard(graph, nodes);

  console.log('COPYING', clipboard);

  return {
    ...interaction,
    clipboard,
  };
};

const paste = (
  interaction: InteractionState,
  clipboard: Clipboard | undefined,
  seed: number,
): InteractionState => ({
  ...interaction,
  selection: clipboard && [
    ...seededRandomIDs(seed, clipboard.nodes.length).map(
      (id): Target => ({
        type: TargetType.Node,
        id,
      }),
    ),
  ],
});
