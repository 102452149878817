import React, { FC, useContext, useState } from 'react';
// import styled from 'styled-components';
import { SessionContext } from '../contexts';
// import { lineHeight } from '../style';
import { A } from './A';
import { Row } from './Row';
import { AccountDialog, AccountDialogPage } from './AccountDialog';
import { MenuButton } from './MenuButton';
import { TAB_ORDER } from '../data';
import { UserTag } from './UserTag';

// const Divider = styled.div`
//   margin: 0 ${lineHeight / 2}px;
// `;

export const SessionInfo: FC = () => {
  const { session, signOut } = useContext(SessionContext);
  const [accountDialogPage, setAccountDialogPage] =
    useState<AccountDialogPage | null>(null);

  return (
    <>
      {accountDialogPage !== null && (
        <AccountDialog
          close={() => setAccountDialogPage(null)}
          initialPage={accountDialogPage}
        />
      )}
      {session ? (
        <MenuButton
          alignRight
          items={[
            [
              ['View profile', `/${session.username}`],
              ['Account settings', `/account-settings`],
            ],
            [['Sign out', signOut]],
          ]}
        >
          <UserTag username={session.username} />
        </MenuButton>
      ) : (
        <Row>
          <A
            onClick={e => {
              e.preventDefault();
              setAccountDialogPage(AccountDialogPage.SignIn);
            }}
            href="#sign-in"
            tabIndex={TAB_ORDER.HEADER_BUTTON}
          >
            sign in
          </A>
          {/* <Divider>|</Divider>
          <A
            onClick={e => {
              e.preventDefault();
              setAccountDialogPage(AccountDialogPage.SignUp);
            }}
            href="#sign-up"
            tabIndex={TAB_ORDER.HEADER_BUTTON}
          >
            sign up
          </A> */}
        </Row>
      )}
    </>
  );
};
