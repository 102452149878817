import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import { Vector, MenuItem, MenuItems } from '../types';
import { lineHeight, strokeWidth } from '../style';
import { useCombinedRefs } from '../hooks';
import { Submenu } from './Submenu';
import { FocusLoop } from './FocusLoop';
import { RoutingContext } from '../contexts';

export type MenuProps = {
  position: Vector;
  items: MenuItems;
  close: () => void;
  spliceElement?: HTMLElement | SVGElement | null;
};

export const Menu = forwardRef<HTMLElement, MenuProps>(
  ({ position, items, close, spliceElement }, ref) => {
    const { route } = useContext(RoutingContext);
    const submenusRef = useRef<HTMLElement[]>([]);
    const submenuRef = (i: number) => (el: HTMLElement) => {
      submenusRef.current[i] = el;
    };
    const [combinedRef, current] = useCombinedRefs<HTMLElement>(
      ref,
      submenuRef(0),
    );

    const [
      { activePath, lastInteractionWasKeyboard },
      setInteraction,
    ] = useState<{
      activePath: [number, number][] | undefined;
      lastInteractionWasKeyboard: boolean;
    }>({ activePath: undefined, lastInteractionWasKeyboard: false });

    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    useEffect(() => {
      const onResize = () => setInnerHeight(window.innerHeight);
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }, []);

    const [submenuRects, setSubmenuRects] = useState<DOMRect[]>([]);
    useLayoutEffect(() => {
      setSubmenuRects(
        submenusRef.current.map(el => el?.getBoundingClientRect()),
      );
    }, [activePath, items, position]);

    const getItem = (path: [number, number][]): MenuItem => {
      const [g, i] = path[path.length - 1];
      return path
        .slice(0, -1)
        .reduce((memo, [g, i]) => memo[g][i][1] as MenuItems, items)[g][i];
    };

    const trigger = (
      path: [number, number][],
      triggerPosition: Vector,
      lastInteractionWasKeyboard: boolean,
    ) => {
      if (path.length === 0) return;
      const [, action, disabled] = getItem(path);
      if (disabled || action === undefined) {
        return;
      } else if (typeof action === 'string') {
        route(action);
        close();
      } else if (typeof action === 'function') {
        action(triggerPosition);
        close();
      } else {
        setInteraction({
          activePath: [...path, [0, 0]],
          lastInteractionWasKeyboard,
        });
      }
    };

    // on splice element focus, deactivate
    useEffect(() => {
      const onFocus = () =>
        setInteraction({ activePath: undefined, lastInteractionWasKeyboard });
      spliceElement?.addEventListener('focus', onFocus);
      return () => spliceElement?.removeEventListener('focus', onFocus);
    }, [spliceElement]);

    // on click outside of menu, close
    useEffect(() => {
      const onClick = (e: MouseEvent) => {
        if (!current?.contains(e.target as Element)) close();
      };

      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }, [close]);

    // on escape key, close
    // arrow keys navigate menu
    // enter key performs action
    useEffect(() => {
      const setActivePath = (activePath: [number, number][] | undefined) =>
        setInteraction({ activePath, lastInteractionWasKeyboard: true });
      const activateNext = () => {
        if (activePath === undefined || activePath.length === 0) {
          setActivePath([[0, 0]]);
        } else {
          const path = activePath.slice(0, -1);
          const last = activePath[activePath.length - 1];
          const currentItems = path.reduce(
            (memo, [g, i]) => memo[g][i][1] as MenuItems,
            items,
          );
          let [group, item] = last;
          do {
            if (item < currentItems[group].length - 1) {
              item++;
            } else if (group < currentItems.length - 1) {
              group++;
              item = 0;
            } else {
              break;
            }
          } while (currentItems[group][item][2] === true);
          setActivePath([...path, [group, item]]);
        }
      };

      const activatePrevious = () => {
        if (activePath === undefined) {
          setActivePath([
            [items.length - 1, items[items.length - 1].length - 1],
          ]);
        } else {
          const path = activePath.slice(0, -1);
          const last = activePath[activePath.length - 1];
          const currentItems = path.reduce(
            (memo, [g, i]) => memo[g][i][1] as MenuItems,
            items,
          );
          let [group, item] = last;
          do {
            if (item > 0) {
              item--;
            } else if (group > 0) {
              do {
                group--;
              } while (currentItems[group].length === 0);
              item = currentItems[group].length - 1;
            } else {
              break;
            }
          } while (currentItems[group][item][2] === true);
          setActivePath([...path, [group, item]]);
        }
      };

      const dive = () => {
        if (activePath === undefined) return;
        const item = getItem(activePath);
        if (item && Array.isArray(item[1])) {
          setActivePath([...activePath, [0, 0]]);
        }
      };

      const surface = () => {
        if (activePath === undefined) return;
        if (activePath.length > 1) setActivePath(activePath.slice(0, -1));
      };

      const onKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
          case 'Escape':
            close();
            break;
          case 'ArrowUp':
            e.preventDefault();
            e.stopPropagation();
            activatePrevious();
            break;
          case 'ArrowDown':
            e.preventDefault();
            e.stopPropagation();
            activateNext();
            break;
          case 'ArrowRight':
            e.preventDefault();
            e.stopPropagation();
            const rectsLength = submenuRects.filter(r => r).length;
            const activePathLength = activePath?.length ?? 0;
            if (
              rectsLength === activePathLength &&
              rectsLength > 1 &&
              submenuRects[rectsLength - 1].left <
                submenuRects[rectsLength - 2].left
            ) {
              surface();
            } else {
              dive();
            }
            break;
          case 'ArrowLeft':
            e.preventDefault();
            e.stopPropagation();
            surface();
            break;
          case 'Enter':
            e.preventDefault();
            if (activePath === undefined) {
              close();
            } else {
              trigger(activePath, position, true);
            }
            break;
        }
      };
      // we capture the event here so that we can stop propagation before other
      // global handlers run - for example the KeyboardListener component of the
      // editor moving selected nodes when the arrow keys are pressed
      window.addEventListener('keydown', onKeyDown, true);
      return () => window.removeEventListener('keydown', onKeyDown, true);
    }, [activePath, items, submenuRects]);

    const submenus = [
      <Submenu
        key={-1}
        ref={combinedRef}
        position={position}
        maxHeight={innerHeight}
        items={items}
        activeItem={activePath && activePath[0]}
        activate={(i, j, isKeyboard) => {
          setInteraction({
            activePath: [[i, j]],
            lastInteractionWasKeyboard: isKeyboard,
          });
        }}
        trigger={(i, j) => {
          const action = items[i][j][1];
          if (typeof action === 'string') {
            route(action);
            close();
          } else if (typeof action === 'function') {
            action(position);
            close();
          }
        }}
      />,
    ];
    let currentItems: MenuItems = items;
    let currentPosition: Vector = position;
    if (activePath) {
      for (
        let submenuIndex = 0;
        submenuIndex < activePath?.length;
        submenuIndex++
      ) {
        const [groupIndex, itemIndex] = activePath[submenuIndex];
        const items = currentItems[groupIndex][itemIndex][1];
        if (
          Array.isArray(items) &&
          !(
            lastInteractionWasKeyboard && submenuIndex === activePath.length - 1
          )
        ) {
          const [x, y] = currentPosition;
          const parentWidth = submenuRects[submenuIndex]?.width ?? 0;
          const selfWidth = submenuRects[submenuIndex + 1]?.width ?? 0;
          const selfHeight = submenuRects[submenuIndex + 1]?.height ?? 0;
          const activeItemOffsetY =
            groupIndex * (lineHeight / 2 + strokeWidth) +
            new Array(groupIndex)
              .fill(0)
              .reduce(
                (count, _, i) => count + currentItems[i].length,
                itemIndex,
              ) *
              lineHeight *
              1.5;
          const offsetX =
            x + parentWidth + selfWidth > window.innerWidth
              ? -selfWidth + strokeWidth
              : parentWidth - strokeWidth;
          const offsetY =
            y + activeItemOffsetY + selfHeight > window.innerHeight
              ? Math.max(
                  -y,
                  activeItemOffsetY -
                    selfHeight +
                    2 * (lineHeight + strokeWidth),
                )
              : activeItemOffsetY;
          const submenuPosition: Vector = [x + offsetX, y + offsetY];

          submenus.push(
            <Submenu
              key={submenuIndex}
              ref={submenuRef(submenuIndex + 1)}
              position={submenuPosition}
              maxHeight={innerHeight}
              activeItem={activePath[submenuIndex + 1]}
              activate={(i, j) => {
                setInteraction({
                  activePath: [
                    ...activePath.slice(0, submenuIndex + 1),
                    [i, j],
                  ],
                  lastInteractionWasKeyboard: false,
                });
              }}
              trigger={(i, j) => {
                trigger(
                  [...activePath.slice(0, submenuIndex + 1), [i, j]],
                  position,
                  false,
                );
              }}
              items={items}
            />,
          );
          currentItems = items;
          currentPosition = submenuPosition;
        }
      }
    }

    // add focus loop to last menu
    return (
      <>
        {submenus.slice(0, -1)}
        <FocusLoop spliceElement={spliceElement}>
          {submenus[submenus.length - 1]}
        </FocusLoop>
      </>
    );
  },
);
