import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { strokeWidth, cornerRadius } from '../../../style';
import {
  GraphContext,
  ActivityContext,
  HoverTargetContext,
  FocusTargetContext,
} from '../contexts';
import { TargetType } from '../../../types';
import {
  getNodeSize,
  getIOCenterPosition,
  targetsEqual,
  getUserColor,
  add,
} from '../../../utils';
import { SessionContext } from '../../../contexts';

export const Outline = styled.rect`
  fill: none;
  stroke-width: ${2 * strokeWidth};
  vector-effect: non-scaling-stroke;
  pointer-events: none;
`;

export type NodeHoverTargetUIProps = {};

export const NodeHoverTargetUI: FC<NodeHoverTargetUIProps> = () => {
  const { session } = useContext(SessionContext);
  const { graph } = useContext(GraphContext);
  const hoverTargets = useContext(HoverTargetContext);
  const focusTargets = useContext(FocusTargetContext);
  const activities = useContext(ActivityContext);

  return (
    <>
      {Object.entries(hoverTargets).map(([username, hoverTarget]) => {
        const focusTarget = focusTargets[username];
        const activity = activities[username];
        const color =
          username === session?.username
            ? 'var(--outline-color)'
            : getUserColor(username);

        if (
          activity !== undefined ||
          hoverTarget === undefined ||
          (focusTarget && targetsEqual(hoverTarget, focusTarget))
        ) {
          return null;
        } else if (hoverTarget.type === TargetType.Node) {
          const node = graph.nodes[hoverTarget.id];
          const {
            position: [x, y],
          } = node;
          const [w, h] = getNodeSize(node);

          return (
            <Outline
              key={username}
              x={x}
              y={y}
              width={w}
              height={h}
              rx={cornerRadius}
              stroke={color}
            />
          );
        } else if (hoverTarget.type === TargetType.IO) {
          const io = hoverTarget.io;
          const node = graph.nodes[io.node];
          const r = 1 / 3;
          const [cx, cy] = add(getIOCenterPosition(node, io), node.position);

          return (
            <Outline
              key={username}
              as="ellipse"
              cx={cx}
              cy={cy}
              rx={r}
              ry={r}
              stroke={color}
            />
          );
        }

        return null;
      })}
    </>
  );
};
