import React, { FC, useContext } from 'react';
import { Stack, Dialog, Shade, LoadingIndicator } from '../../../components';
import { GraphPublicAccessControls } from './GraphPublicAccessControls';
import { GraphPermissionsControls } from './GraphPermissionsControls';
import { lineHeight, inputWidth } from '../../../style';
import { GraphPermissions, GraphPermission } from '../../../types';
import { SessionContext } from '../../../contexts';
import { GraphContext } from '../contexts';
import { useAPIData, useAPIAction } from '../../../hooks';
import {
  readGraphPermissions,
  updateGraphPermission,
  deleteGraphPermission,
  updateGraphPublicAccess,
} from '../../../api';

export type ShareDialogProps = {
  close: () => void;
};

export const ShareDialog: FC<ShareDialogProps> = ({ close }) => {
  const { session } = useContext(SessionContext);
  if (session === undefined) throw new Error();

  const {
    graph: { id: graphID },
  } = useContext(GraphContext);

  const [
    graphPermissionsLoading,
    graphPermissionsError,
    graphPermissions,
    setGraphPermissions,
  ] = useAPIData<GraphPermissions>(() => {
    if (session === undefined) throw new Error();
    return readGraphPermissions({ session, graphID });
  });

  const [
    callUpdateGraphPermission,
    updateGraphPermissionLoading,
    updateGraphPermissionError,
  ] = useAPIAction(async (authorizedUsername: string, write: boolean) => {
    const resp = await updateGraphPermission({
      session,
      graphID,
      authorizedUsername,
      write,
    });
    if (resp.status === 204 && graphPermissions) {
      const permissionsByAuthorizedUsername =
        graphPermissions.permissions.reduce(
          (memo, p) => ((memo[p.username] = p), memo),
          {} as { [username: string]: GraphPermission },
        );
      setGraphPermissions({
        ...graphPermissions,
        permissions: permissionsByAuthorizedUsername[authorizedUsername]
          ? graphPermissions.permissions.map(p =>
              p.username === authorizedUsername ? { ...p, write } : p,
            )
          : [
              ...graphPermissions.permissions,
              { username: authorizedUsername, write },
            ],
      });
    }
    return resp;
  });

  const [
    callDeleteGraphPermission,
    deleteGraphPermissionLoading,
    deleteGraphPermissionError,
  ] = useAPIAction(async (authorizedUsername: string) => {
    const resp = await deleteGraphPermission({
      session,
      graphID,
      authorizedUsername,
    });
    if (resp.status === 204 && graphPermissions) {
      setGraphPermissions({
        ...graphPermissions,
        permissions: graphPermissions.permissions.filter(
          p => p.username !== authorizedUsername,
        ),
      });
    }
    return resp;
  });

  const [
    callUpdateGraphPublicAccess,
    updateGraphPublicAccessLoading,
    updateGraphPublicAccessError,
  ] = useAPIAction(async (isPublic: boolean) => {
    const resp = await updateGraphPublicAccess({
      session,
      graphID,
      isPublic,
    });
    if (resp.status === 204 && graphPermissions) {
      setGraphPermissions({ ...graphPermissions, public: isPublic });
    }
    return resp;
  });

  const actionLoading =
    updateGraphPermissionLoading ||
    updateGraphPublicAccessLoading ||
    deleteGraphPermissionLoading;

  const error =
    graphPermissionsError ||
    updateGraphPermissionError ||
    updateGraphPublicAccessError ||
    deleteGraphPermissionError;

  return (
    <Dialog
      title="Sharing"
      close={close}
      loading={graphPermissionsLoading}
      error={error}
      width={inputWidth + 3.5 * lineHeight}
    >
      {graphPermissions && (
        <Stack leading space={2 * lineHeight}>
          <GraphPublicAccessControls
            isPublic={graphPermissions.public}
            updateGraphPublicAccess={callUpdateGraphPublicAccess}
          />
          <GraphPermissionsControls
            permissions={graphPermissions.permissions}
            updateGraphPermission={callUpdateGraphPermission}
            deleteGraphPermission={callDeleteGraphPermission}
          />
        </Stack>
      )}
      {actionLoading && (
        <>
          <Shade />
          <LoadingIndicator />
        </>
      )}
    </Dialog>
  );
};
