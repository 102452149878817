import { Vector } from './Vector';

export enum V0IOType {
  Input,
  Output,
}

export type V0GraphForStorage = {
  readonly v?: undefined;
  readonly id: string;
  readonly metadata: {
    name: string;
    forkedFrom?: string[];
  };
  readonly nodes: {
    [id: string]: {
      readonly audioNode: {
        type: string;
        params: { [name: string]: any };
      };
      readonly data: {
        readonly position: Vector;
        readonly name: string;
      };
      readonly id: string;
    };
  };
  readonly edges: {
    [id: string]: {
      id: string;
      from: {
        readonly type: V0IOType;
        readonly node: string;
        readonly index: number;
      };
      to: {
        readonly type: V0IOType;
        readonly node: string;
        readonly index: number;
      };
    };
  };
};
