import { State } from './State';
import { Action, ActionType } from './Action';

export const rootReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SignIn:
      return {
        ...state,
        session: action.session,
      };
    case ActionType.SignOut:
      return {
        ...state,
        session: undefined,
      };
    default:
      return state;
  }
};
