import React, { FC } from 'react';
import { ValueInputProps } from './ValueInput';
import { FloatArrayInput } from './FloatArrayInput';
import { ArrayWeightedRandomMemberValueInput } from './ArrayWeightedRandomMemberValueInput';

export const FloatArrayWeightedRandomMemberValueInput: FC<
  ValueInputProps<number[]>
> = props => {
  return (
    <ArrayWeightedRandomMemberValueInput
      {...props}
      Component={FloatArrayInput}
    />
  );
};
