import { Rect, Vector, SSGraph } from '../types';
import { getCenteredViewport } from './getCenteredViewport';
import { graphBounds } from './graphBounds';

export const centerViewport = (domRectSize: Vector, graph: SSGraph): Rect => {
  return getCenteredViewport(
    graphBounds(graph),
    Object.keys(graph.nodes).length === 0,
    domRectSize,
    1.5,
  );
};
