import React, { FC, memo } from 'react';
import styled from 'styled-components';
import {
  columnPadding,
  columnHandleWidth,
  columnHandleHeight,
} from '../../../style';
import { TAB_ORDER } from '../../../data';
import { useDrag } from '../../../hooks';

type ColumnProps = {
  left?: boolean;
};
const Column = styled.div`
  padding-left: ${({ left }: ColumnProps) => (left ? 0 : columnHandleWidth)}px;
  padding-right: ${({ left }) => (left ? columnHandleWidth : 0)}px;
`;

const Content = styled.div.attrs({ tabIndex: -1 })`
  height: 100%;
  padding: 0 ${columnPadding}px;
  &:focus {
    outline: none;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Handle = styled.div`
  position: absolute;
  top: 50vh;
  margin-top: ${-columnHandleHeight / 2}px;
  z-index: 1;
  width: ${columnHandleWidth}px;
  height: ${columnHandleHeight}px;
  border-radius: ${columnHandleWidth / 2}px;
  cursor: col-resize;
  background: var(--ui-control-secondary-color);

  &:hover {
    background: var(--ui-control-color);
  }
  &:focus {
    outline-color: var(--outline-secondary-color);
  }
`;

type ResizableColumnProps = {
  size: number;
  setSize: (size: number) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  minSize: number;
  maxSize: number;
  left?: boolean;
};
export const ResizableColumn: FC<ResizableColumnProps> = memo(
  ({
    size: sizeProp,
    setSize,
    isOpen,
    setIsOpen,
    minSize,
    maxSize,
    left,
    children,
  }) => {
    const constrainedSetSize = (nextSize: number) => {
      if (nextSize <= columnPadding) {
        setIsOpen(false);
      } else if (nextSize > minSize && !isOpen) {
        setIsOpen(true);
      }
      setSize(Math.min(Math.max(minSize, nextSize), maxSize));
    };
    const beginDrag = useDrag<number>(
      (_position, _origin, [deltaX], originalWidth) =>
        constrainedSetSize(originalWidth + (left ? deltaX : -deltaX)),
    );
    const size = isOpen ? sizeProp : columnPadding;

    return (
      <Column left={left} style={{ width: size }}>
        {isOpen && (
          <Content
            onFocus={() => {
              setIsOpen(true);
            }}
          >
            {children}
          </Content>
        )}
        <Handle
          style={{
            left: left ? `${size - columnHandleWidth}px` : 'auto',
            right: left ? 'auto' : `${size - columnHandleWidth}px`,
          }}
          tabIndex={TAB_ORDER.COLUMN_HANDLE}
          onMouseDown={e => beginDrag(e, size)}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={e => {
            switch (e.key) {
              case 'Enter':
                e.stopPropagation();
                setIsOpen(!isOpen);
                break;
              case 'ArrowLeft':
                e.stopPropagation();
                if (left) {
                  if (size === minSize) {
                    setIsOpen(false);
                  } else {
                    constrainedSetSize(size - 32);
                  }
                } else {
                  if (!isOpen) {
                    setIsOpen(true);
                  } else {
                    constrainedSetSize(size + 32);
                  }
                }
                break;
              case 'ArrowRight':
                e.stopPropagation();
                if (left) {
                  if (!isOpen) {
                    setIsOpen(true);
                  } else {
                    constrainedSetSize(size + 32);
                  }
                } else {
                  if (size === minSize) {
                    setIsOpen(false);
                  } else {
                    constrainedSetSize(size - 32);
                  }
                }
                break;
            }
          }}
        />
      </Column>
    );
  },
);
