import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import { Vector } from '../../../types';
import { strokeWidth, halfStrokeWidth } from '../../../style';
import { TAB_ORDER } from '../../../data';
import { useAnimationFrameEffect } from '../../../hooks';

type LineProps = { main?: boolean; clipping?: boolean };
const Line = styled.line`
  stroke-width: ${strokeWidth};
  stroke: var(
    ${({ main, clipping }: LineProps) =>
      clipping
        ? '--outline-color'
        : main
        ? '--visualization-stroke-color'
        : '--visualization-secondary-stroke-color'}
  );
`;
const Rect = styled.rect`
  fill: var(--visualization-secondary-stroke-color);
`;
const BorderLine = styled.line`
  stroke-width: ${strokeWidth};
  stroke: var(--visualization-secondary-stroke-color);
`;
const SVG = styled.svg`
  flex-shrink: 0;
  background-color: var(--visualization-color);
  &:focus {
    outline-color: var(--outline-secondary-color);
  }
`;

export type PeakMeterProps = {
  size: Vector;
  frameDivisor?: number;
  decayFactor?: number;
  currentDecayFactor?: number;
  analyser: AnalyserNode;
  context: BaseAudioContext;
};

export const PeakMeter: FC<PeakMeterProps> = ({
  size,
  frameDivisor = 1,
  decayFactor = 0.99,
  currentDecayFactor = 0.9,
  analyser,
  context,
}) => {
  const [frozen, setFrozen] = useState<boolean>(false);
  const [, setCurrent] = useState<number>(0);
  const currentRef = useRef<number>(0);
  const maxRef = useRef<number>(0);
  useAnimationFrameEffect(
    () => {
      if (frozen || context.state !== 'running') return;
      const data = new Float32Array(analyser.fftSize);
      analyser.getFloatTimeDomainData(data);

      // Compute average power over the interval.
      let peakInstantaneousPower = 0;
      for (let i = 0; i < data.length; i++) {
        peakInstantaneousPower = Math.max(data[i], peakInstantaneousPower);
      }

      currentRef.current = Math.max(
        peakInstantaneousPower,
        currentRef.current * currentDecayFactor,
      );
      maxRef.current = Math.max(
        peakInstantaneousPower,
        maxRef.current * decayFactor,
      );

      // only necessary to trigger re-render
      setCurrent(peakInstantaneousPower);
    },
    [analyser, context, frozen],
    frameDivisor,
  );

  const [width, height] = size;
  const yScale = height - strokeWidth;
  const offset = strokeWidth / 2;
  const maxY = yScale * Math.max(0, 1 - maxRef.current) + offset;
  const currentY = yScale * Math.max(0, 1 - currentRef.current) + offset;
  const currentHeight = height - currentY;

  return (
    <SVG
      width={width}
      height={height}
      tabIndex={TAB_ORDER.VISUALIZATION}
      onClick={() => setFrozen(!frozen)}
      onKeyDown={e => {
        if (e.key === 'Enter') setFrozen(!frozen);
      }}
    >
      <Rect x={0} y={currentY} width={width} height={currentHeight} />
      <BorderLine x1={0} y1={halfStrokeWidth} x2={width} y2={halfStrokeWidth} />
      <Line
        x1={0}
        y1={maxY}
        x2={width}
        y2={maxY}
        main={!frozen}
        clipping={!frozen && maxRef.current >= 1}
      />
    </SVG>
  );
};
