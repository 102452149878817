import { Vector, Rect } from '../types';
import {
  expand,
  growRectToMatchAspectRatio,
  growVectorToMatchAspectRatio,
} from '../utils';

export const getCenteredViewport = (
  bounds: Rect,
  isEmpty: boolean,
  aspectRatio: Vector,
  minFactor: number = 1,
  minSize: number = 9,
): Rect => {
  if (isEmpty) {
    return [
      [minSize / 2, minSize / 2],
      growVectorToMatchAspectRatio([minSize, minSize], aspectRatio),
    ];
  }
  const maxDimension = Math.max(...bounds[1]);
  const expansionFactor = Math.max(9 / maxDimension, minFactor);
  return growRectToMatchAspectRatio(
    expand(bounds, expansionFactor),
    aspectRatio,
  );
};
