import React, { FC, ReactNode } from 'react';
import { Layout } from './Layout';
import { BlockingOverlay } from './BlockingOverlay';

export type DefaultScaffoldProps = {
  breadcrumbs?: ReactNode;
  error: string | null;
};

export const DefaultScaffold: FC<DefaultScaffoldProps> = ({
  error,
  breadcrumbs,
}) => {
  return (
    <>
      <Layout breadcrumbs={breadcrumbs} />
      <BlockingOverlay error={error} />
    </>
  );
};
