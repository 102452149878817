import React, { FC } from 'react';
import { randomNumber } from 'helicon/build';
import { ValueInputProps } from './ValueInput';
import { RandomNumberValue } from 'helicon';
import { ProbabilityDistributionInput } from './ProbabilityDistributionInput';
import { Col, InfoText } from '../../../../components';

export const FloatRandomNumberValueInput: FC<ValueInputProps<number>> = ({
  id,
  value,
  seededValue,
  paramDescription,
  onChange,
}) => {
  const distribution = (value as RandomNumberValue).distribution;
  return (
    <Col gap={1} align="stretch">
      <ProbabilityDistributionInput
        id={id}
        value={distribution}
        seededValue={seededValue}
        paramDescription={paramDescription}
        onChange={value => onChange(randomNumber(value))}
      />
      <InfoText>{seededValue.toFixed(3)}</InfoText>
    </Col>
  );
};
