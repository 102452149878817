import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { A } from './A';
import { Layout } from './Layout';
import { Stack } from './Stack';
import { lineHeight, maxBlockContentWidth } from '../style';

const Columns = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
`;

const Column = styled.div`
  width: 196px;
  padding: ${lineHeight}px;
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    display: none;
  }
`;

const Main = styled.main`
  background-color: var(--background-color);
  overflow-y: auto;
  scroll-behavior: smooth;
  flex: 1 1;
  &:focus {
    outline: none;
  }
`;

const Section = styled.section`
  margin-bottom: ${2 * lineHeight}px;
  padding: ${lineHeight}px;
`;

export type SectionsLayoutProps = {
  breadcrumbs?: ReactNode;
  sections: {
    id: string;
    title: string;
    content: ReactNode;
  }[];
  footer?: ReactNode;
};

export const SectionsLayout: FC<SectionsLayoutProps> = ({
  breadcrumbs,
  sections,
  footer,
}) => {
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Columns>
        <Column>
          <Stack>
            {sections.map(({ id, title }) => (
              <A block href={`#${id}`} key={id}>
                {title}
              </A>
            ))}
          </Stack>
        </Column>
        <Main tabIndex={-1}>
          {sections.map(({ id, content }) => (
            <Section id={id} key={id}>
              {content}
            </Section>
          ))}
          {footer && <Section>{footer}</Section>}
        </Main>
      </Columns>
    </Layout>
  );
};
