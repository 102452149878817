import React, { FC } from 'react';
import { ValueInputProps } from './ValueInput';
import { IntegerInput } from './IntegerInput';
import { ArrayWeightedRandomSubsetValueInput } from './ArrayWeightedRandomSubsetValueInput';

export const IntegerArrayWeightedRandomSubsetValueInput: FC<
  ValueInputProps<number[]>
> = props => {
  return (
    <ArrayWeightedRandomSubsetValueInput {...props} Component={IntegerInput} />
  );
};
