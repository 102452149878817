import { ADEnvelopeExtension } from './ADEnvelopeExtension';
import { ADSREnvelopeExtension } from './ADSREnvelopeExtension';
import { ArrangerExtension } from './ArrangerExtension';
import { ClockExtension } from './ClockExtension';
import { EuclideanRhythmExtension } from './EuclideanRhythmExtension';
import { LogicExtension } from './LogicExtension';
import { LunarPhaseExtension } from './LunarPhaseExtensions';
import { PitchShifterExtension } from './PitchShifterExtension';
import { QuantizerExtension } from './QuantizerExtension';
import { RandomGateExtension } from './RandomGateExtension';
import { ResonatorExtension } from './ResonatorExtension';
import { ReverbExtension } from './ReverbExtension';
import { RhythmExtension } from './RhythmExtension';
import { SampleAndHoldExtension } from './SampleAndHoldExtension';
import { SampleSlicerExtension } from './SampleSlicerExtension';
import { SequenceExtension } from './SequenceExtension';
import { SlewLimiterExtension } from './SlewLimiterExtension';
import { StochasticRhythmExtension } from './StochasticRhythmExtension';
import { TriggeredSamplerExtension } from './TriggeredSamplerExtension';
import { WhiteNoiseExtension } from './WhiteNoiseExtension';

const manifest = (window as any).WEBPACK_MANIFEST ?? {};

export const EXTENSIONS = [
  ADEnvelopeExtension,
  ADSREnvelopeExtension,
  ArrangerExtension,
  ClockExtension,
  EuclideanRhythmExtension,
  LogicExtension,
  LunarPhaseExtension,
  PitchShifterExtension,
  QuantizerExtension,
  RandomGateExtension,
  ResonatorExtension,
  ReverbExtension,
  RhythmExtension,
  SampleAndHoldExtension,
  SampleSlicerExtension,
  SequenceExtension,
  SlewLimiterExtension,
  StochasticRhythmExtension,
  TriggeredSamplerExtension,
  WhiteNoiseExtension,
].map(e => ({
  ...e,
  processor: manifest[e.processor] ?? e.processor,
}));
