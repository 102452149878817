import styled from 'styled-components';
import { Label } from '../../../../components';
import { lineHeight } from '../../../../style';

export const OptionLabel = styled(Label)<{ highlighted?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--input-disabled-color);
  padding: 0 0 0 ${0.5 * lineHeight}px;
  margin: 0;
`;
