import React, { FC, useContext } from 'react';
import { Button, Dialog, Stack } from '../../../components';
import { SessionContext } from '../../../contexts';
import { listFiles } from '../../../api';
import { useAPIData } from '../../../hooks';
import { inputWidth, lineHeight } from '../../../style';
import { createFile } from '../../../api';
import { useAPIAction } from '../../../hooks';
import { FilterableSelectList } from './FilterableSelectList';
import { TAB_ORDER } from '../../../data';

export type SelectAudioFileDialogProps = {
  close: () => void;
  value: string | undefined;
  onChange: (value: string) => void;
};

export const SelectAudioFileDialog: FC<SelectAudioFileDialogProps> = ({
  close,
  value,
  onChange,
}) => {
  const { session } = useContext(SessionContext);
  const [filesLoading, filesError, filesData, setFilesData] = useAPIData(
    session === undefined ? undefined : () => listFiles({ session }),
    [session],
  );
  const [callCreateFile, createFileLoading, createFileError] =
    useAPIAction(createFile);

  const files = filesData?.files ?? [];

  return (
    <Dialog
      title="Select audio file"
      loading={filesLoading || createFileLoading}
      error={filesError || createFileError}
      close={close}
      width={inputWidth}
    >
      <Stack>
        {files.length > 0 && (
          <FilterableSelectList
            value={value}
            onChange={value => {
              onChange(value);
            }}
            items={files.map(({ url, name }) => ({ id: url, label: name }))}
            itemSingular="file"
            itemsPlural="files"
          />
        )}
        <Button
          style={{ marginTop: 2 * lineHeight }}
          onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.addEventListener('change', async (e: Event) => {
              const target = e.target as HTMLInputElement;
              if (session && target.files && target.files.length > 0) {
                const response = await callCreateFile({
                  session,
                  file: target.files[0],
                });
                if (response.status === 200 && response.body) {
                  setFilesData({
                    files: [...(filesData?.files ?? []), response.body].sort(
                      (a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                    ),
                  });
                  onChange(response.body.url);
                }
              }
            });
            input.click();
          }}
          tabIndex={TAB_ORDER.INPUT}
        >
          Upload new file
        </Button>
      </Stack>
    </Dialog>
  );
};
