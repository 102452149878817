import { useContext } from 'react';
import { BlockingOverlayContext } from '../contexts';
import { Response } from '../utils';

export const useBlockingAPIAction = <Data, Args extends any[]>(
  fn: (...args: Args) => Response<Data>,
): ((...args: Args) => Response<Data>) => {
  const { start, stop, fail } = useContext(BlockingOverlayContext);
  return async (...args) => {
    start();
    const response = await fn(...args);
    if (response.status === 200 || response.status === 204) {
      stop();
    } else {
      fail(response.error);
    }
    return response;
  };
};
