import { NodeExtension, ParamType } from 'helicon';

export const LogicExtension: NodeExtension = {
  type: 'Logic',
  processor: '/LogicProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 3,
    outputChannelCount: [1, 1, 1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      a: {
        type: ParamType.AudioParam,
        default: 0,
      },
      b: {
        type: ParamType.AudioParam,
        default: 0,
      },
    },
    signals: {},
  },
};
