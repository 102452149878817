import { createContext } from 'react';

export type BlockingOverlayOptions = {
  active: boolean;
  error: string | null;
  start: () => void;
  stop: () => void;
  fail: (error: string | null) => void;
};

export const BlockingOverlayContext = createContext<BlockingOverlayOptions>({
  active: false,
  error: null,
  start: () => {},
  stop: () => {},
  fail: () => {},
});
