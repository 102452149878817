import { useState } from 'react';
import { Response } from '../utils';

export const useAPIAction = <Data, Args extends any[]>(
  fn: (...args: Args) => Response<Data>,
): [(...args: Args) => Response<Data>, boolean, string | null] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  return [
    async (...args) => {
      setLoading(true);
      const response = await fn(...args);
      setError(response.error);
      setLoading(false);
      return response;
    },
    loading,
    error,
  ];
};
