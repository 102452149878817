import { GridState } from './State';
import { Action, ActionType } from './Action';
import { Rect, Vector, SSGraph } from '../../../types';
import {
  graphBounds,
  add,
  subtract,
  multiply,
  divide,
  divideScalar,
  fit,
  min,
  max,
  multiplyScalar,
  growVectorToMatchAspectRatio,
} from '../../../utils';

export const gridReducer = (
  grid: GridState,
  graph: SSGraph,
  action: Action,
): GridState => {
  switch (action.type) {
    case ActionType.SetDOMRect:
      return setDOMRect(grid, graph, action.domRect);
    case ActionType.SetViewport:
      return { ...grid, viewport: action.viewport };
    case ActionType.PanViewport:
      return panViewport(grid, action.delta);
    case ActionType.ZoomViewport:
      return zoomViewport(grid, graph, action.center, action.amount);
    default:
      return grid;
  }
};

const setDOMRect = (
  grid: GridState,
  graph: SSGraph,
  domRect: Rect,
): GridState => {
  const [, domRectSize] = domRect;
  const {
    viewport: [viewportPosition, viewportSize],
  } = grid;
  const [domRectSizeX, domRectSizeY] = domRectSize;
  const [viewportSizeX] = viewportSize;
  const domAspectRatio = domRectSizeX / domRectSizeY;
  const nextViewportSize = constrainZoom(graph, domRectSize, [
    viewportSizeX,
    viewportSizeX / domAspectRatio,
  ]);
  const nextViewportPosition = add(
    viewportPosition,
    divideScalar(subtract(viewportSize, nextViewportSize), 2),
  );

  return {
    ...grid,
    domRect,
    viewport: [nextViewportPosition, nextViewportSize],
  };
};

const panViewport = (grid: GridState, delta: Vector): GridState => {
  const {
    viewport: [viewportPosition, viewportSize],
  } = grid;
  return {
    ...grid,
    viewport: [add(viewportPosition, delta), viewportSize],
  };
};

const zoomViewport = (
  grid: GridState,
  graph: SSGraph,
  center: Vector,
  amount: number,
): GridState => {
  const {
    viewport: [viewportPosition, viewportSize],
    domRect: [, domRectSize],
  } = grid;
  const cellSize = divide(domRectSize, viewportSize);
  const zoomAmount = fit(divide([amount, amount], cellSize), viewportSize);
  const nextViewportSize = constrainZoom(
    graph,
    domRectSize,
    add(viewportSize, zoomAmount),
  );
  const nextViewportPosition = add(
    viewportPosition,
    multiply(subtract(viewportSize, nextViewportSize), center),
  );

  return {
    ...grid,
    viewport: [nextViewportPosition, nextViewportSize],
  };
};

const constrainZoom = (
  graph: SSGraph,
  domRectSize: Vector,
  viewportSize: Vector,
): Vector => {
  let maxSize;
  if (Object.keys(graph.nodes).length === 0) {
    maxSize = growVectorToMatchAspectRatio([25, 25], domRectSize);
  } else {
    const [, graphSize] = graphBounds(graph);
    maxSize = growVectorToMatchAspectRatio(
      max([25, 25], multiplyScalar(graphSize, 5)),
      domRectSize,
    );
  }
  const minSize = growVectorToMatchAspectRatio([5, 5], domRectSize);

  return max(minSize, min(maxSize, viewportSize));
};
