import { useRef, useEffect } from 'react';

export const useAnimationFrameEffect = (
  cb: () => void,
  deps: any[],
  frameDivisor: number = 1,
): void => {
  const currentFrame = useRef<number>(0);
  useEffect(() => {
    let done = false;
    const update = () => {
      if (done) return;
      if (currentFrame.current === 0) {
        cb();
      }
      currentFrame.current = (currentFrame.current + 1) % frameDivisor;
      requestAnimationFrame(update);
    };
    requestAnimationFrame(update);
    return () => {
      done = true;
    };
  }, deps);
};
