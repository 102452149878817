import React, { FC, useReducer, useMemo } from 'react';
import { rootReducer, initialState } from '../reducers';
import { ActionContext, bindActions } from '../contexts';
import { GraphThumbnail, Account } from '../../../types';
import { ProfileLayout } from './ProfileLayout';

export type ProfileControllerProps = {
  graphs: GraphThumbnail[];
  account: Account;
};

export const ProfileController: FC<ProfileControllerProps> = ({
  graphs: initialGraphs,
  account,
}) => {
  const [{ graphs }, dispatch] = useReducer(
    rootReducer,
    initialState(initialGraphs),
  );
  const boundActions = useMemo(() => bindActions(dispatch), []);

  return (
    <ActionContext.Provider value={boundActions}>
      <ProfileLayout graphs={graphs} account={account} />
    </ActionContext.Provider>
  );
};
